import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, MenuItem, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { formatPrice, globalConstant } from '../../../globalConstants';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../../api';
import useRazorpay from "react-razorpay";
import { useNavigate } from 'react-router-dom';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: '87%',
    borderRadius: 2,
    overflowY: 'auto',
    bgcolor: "white",
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    border: "none",
    boxShadow: 24,
    p: 3,
};


const RefundServicePayment = ({ open, setOpen, refundData, booking, getAllBookings }) => {
    const { user_data } = useSelector(state => state.user);
    const [bankDetails, setBankDetails] = useState({
        bankName: user_data?.bank_details?.bank_name,
        accountNumber: user_data?.bank_details?.account_number,
        ifscCode: user_data?.bank_details?.ifsc,
        accountHolderName: user_data?.bank_details?.account_holder_name,
        branchName: user_data?.bank_details?.branch_name
    });
    const [upi, setUpi] = useState(user_data?.upi)
    const [errors, setErrors] = useState({});
    const { primaryColor } = globalConstant();
    const [refundAmount, setRefundAmount] = useState(0);

    console.log('refund data ', booking);

    const calculateRefundAmount = () => {
        let refundAmt = 0;
        refundData.map((item) => {
            item?.sub_services.map((subservice) => {
                refundAmt = refundAmt + parseInt(subservice?.discounted_price)
            })
        })

        const discount = Math.ceil((refundAmt * booking?.discount) / 100);
        refundAmt = refundAmt - discount;

        setRefundAmount(refundAmt);
    }

    useEffect(() => {
        calculateRefundAmount()
    }, [open])

    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
        boxShadow: 'none',
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBankDetails({
            ...bankDetails,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const handleRefundRequest = async () => {
        let payload = {
            booking_id: booking?._id,
            customer_id: user_data?._id,
            org_amount: booking?.total_amount,
            refund_amount: refundAmount,
            reason: "Service un-serviceable",
            refund_status: 'processed',
            cancel_type: 'unserviced',
            bank_details: {
                bank_name: bankDetails?.bankName,
                account_number: bankDetails?.accountNumber,
                ifsc: bankDetails?.ifscCode,
                account_holder_name: bankDetails?.accountHolderName,
                branch_name: bankDetails?.branchName
            },
            upi: upi
        };
        const data = await post_data(`refund/create-unserviceable-refund`, payload);
        if (data.status) {
            setOpen(false);
            getAllBookings(1)
            Toast.fire({
                icon: 'success',
                title: "Refund request raised successfully",
                color: "green"
            });
        } else {
            setOpen(false);
            Toast.fire({
                icon: 'warning',
                title: "Something Went Wrong",
                color: "red"
            });
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={style}>
                <IconButton onClick={() => setOpen(false)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                    <CloseIcon />
                </IconButton>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                            Total Paid Amount:
                        </Typography>
                        <Typography variant="h6" component="h3">
                            {formatPrice(booking?.total_amount)}
                        </Typography>

                        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                            Total Refund:
                        </Typography>
                        <Typography variant="h6" component="h3">
                            {formatPrice(refundAmount)}
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 2, fontSize: 14 }}>
                            Refund will be credited to your bank account in 24 hours.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" component="h3" sx={{ mt: 2 }}>
                            Bank Details
                        </Typography>
                        <TextField
                            label="Bank Name"
                            name="bankName"
                            value={bankDetails.bankName}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            label="Account Number"
                            name="accountNumber"
                            value={bankDetails.accountNumber}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                        <TextField
                            label="IFSC Code"
                            name="ifscCode"
                            value={bankDetails.ifscCode}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            label="Account Holder Name"
                            name="accountHolderName"
                            value={bankDetails.accountHolderName}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <TextField
                            label="Branch Name"
                            name="branchName"
                            value={bankDetails.branchName}
                            onChange={handleChange}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                        <Typography sx={{ mt: 2 }}>OR</Typography>
                        <TextField
                            label="UPI Handle"
                            name="upi"
                            value={upi}
                            onChange={(e) => setUpi(e?.target?.value)}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" style={button} fullWidth onClick={handleRefundRequest}>
                    Refund Request
                </Button>
            </Box>
        </Modal>
    );
};

export default RefundServicePayment;
