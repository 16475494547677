import { useMediaQuery } from "@mui/material";
import HelpCenter from "../../web-app/user-dashboard/Pages/HelpCenter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function Faq() {

    const isDesktop = useMediaQuery('(min-width: 1024px)');

    return (
        <>
            <Header />
            <div className='user-dashboard' style={{ padding: isDesktop ? '5% 7%' : '3%' }}>
                <HelpCenter />
            </div>
            <Footer />
        </>
    )
}