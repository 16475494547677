import React, { useEffect } from "react";
import AppPromotion from "../Components/AppPromotion";
import FeaturedServices from "../Components/FeaturedServices";
import FeaturedSubServices from "../Components/FeaturedSubServices";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import HeroSection from "../Components/HeroSection";
import Newsletter from "../Components/Newsletter";
import ServicableCities from "../Components/ServicableCities ";
import Services from "../Components/Services";
import ServicesBanners from "../Components/ServicesBanners";
import Testimonials from "../Components/Testimonials";
import WhyChooseUs from "../Components/WhyChooseUs";
import AppPromoModal from "../Components/AppPromoModal";

export default function Home() {
    const [appModal, setAppModal] = React.useState(false)

    useEffect(() => {
        setTimeout(() => {
            setAppModal(true)
        }, 5000);
    }, [])

    return (
        <>
            <AppPromoModal open={appModal} setOpen={setAppModal} />
            <Header />
            <HeroSection />
            <FeaturedServices />
            <Services />
            {/* <FeaturedSubServices /> */}
            <ServicesBanners />
            <ServicableCities />
            {/* <AppPromotion /> */}
            <WhyChooseUs />
            <Testimonials />
            <Newsletter />
            <Footer />
        </>
    )
}