import React, { useEffect, useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Pagination } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { get_data } from '../../../api';
import parse from 'html-react-parser';
import Loader from "../../../website/Components/Loader"

const HelpCenter = ({ user_data }) => {

    const isTablet = useMediaQuery('(max-width: 960px)');
    const isMobile = useMediaQuery('(max-width: 600px)');

    const styles = {
        container: {
            padding: isMobile || isTablet ? '20px' : '3% 15%',
            position: 'relative',
        },
        heading: {
            fontSize: isMobile || isTablet ? '24px' : 40,
            fontWeight: '600',
            marginBottom: '20px',
            width: isMobile || isTablet ? '100%' : '60%'
        },
        subheading: {
            fontSize: '18px',
            color: 'gray',
            marginBottom: '20px',
        },
        faqItem: {
            marginBottom: '10px',
            boxShadow: 'none',
            background: 'transparent'
        },
    };


    const itemsPerPage = 15;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalFaqs, setTotalFaqs] = useState(null);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);


    const getAllFaqs = async () => {
        try {
            const data = await get_data(`faq/get-all-faq?pageNumber=${currentPage}`);
            if (data.status) {
                const sortedData = data?.data?.faq.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
                setLoader(false);
                setData(sortedData);
                setTotalPages(data?.data?.totalPages);
                setTotalFaqs(data?.data?.totalFaqs);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Newsletters", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllFaqs(currentPage);
        }
    }, [currentPage])

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Box sx={styles.container}>
            <Typography variant="overline" display="block" gutterBottom>HELP CENTER</Typography>
            <Typography sx={styles.heading}>Everything you need to know about Nivishka Services</Typography>
            {
                loader ?
                    <>
                        <div style={{ marginTop: '7%' }}><Loader /></div>
                    </>
                    :
                    <>
                        {data?.map((faq, index) => (
                            <Accordion key={index} sx={styles.faqItem}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                >
                                    <Typography style={{ fontSize: isTablet || isMobile ? 17 : 20 }}>{faq.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{parse(faq.answer)}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </>
            }

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                    "&& .Mui-selected": {
                        backgroundColor: "#5DB761",
                        color: "#fff"
                    }
                }}
            />
        </Box>
    );
};

export default HelpCenter;