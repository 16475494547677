import { Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import RefundTable from "../Components/RefundTable";
import SearchComponent from "../Components/SearchComponent";

export default function Refund({ type }) {

    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [refundData, setRefundData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalRefunds, setTotalRefunds] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);


    const permissions_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };


    const getAllRefunds = async () => {
        try {
            const data = await get_data(`refund/get-all-refunds?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setRefundData(data?.data?.refund);
                setTotalPages(data?.data?.totalPages);
                setTotalRefunds(data?.data?.totalRefunds);
            }
        } catch (error) {
            setLoader(false);
            console.log("Error while fetching Profession", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllRefunds(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Customer", grid: 2 },
        { title: "Number", grid: 2 },
        { title: "BookingID", grid: 2 },
        { title: "Org Amount", grid: 1 },
        { title: "RefundAmt", grid: 1 },
        { title: "Status", grid: 1 },
        { title: "Payment", grid: 1 },
        { title: "Action", grid: 1 },
    ];

    const count = refundData.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...permissions_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Refunds' />

                <div style={page_container}>
                    <RefundTable
                        tableHeader={tableHeader}
                        type="permissions"
                        data={refundData}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalRefunds={totalRefunds}
                        totalPages={totalPages}
                        loader={loader}
                        setLoader={setLoader}
                        getAllRefunds={getAllRefunds}
                    />
                </div>
            </Grid>
        </>
    )
}