import React, { useEffect, useState } from "react";
import { Grid, Typography, Pagination, CircularProgress, Modal, Box, TextField, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, FormHelperText } from "@mui/material";
import { endPointURL, post_data } from "../../api";
import Swal from 'sweetalert2';
import { IoClose } from "react-icons/io5";
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import Loader from "../../website/Components/Loader";
import 'react-quill/dist/quill.snow.css';
import { useMemo } from "react";
import { globalConstant } from "../../globalConstants";

const ServiceBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  background: #fff;
  box-sizing:border-box;
  overflow: hidden;
  `;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};


export default function ClientsTable({
    tableHeader,
    data,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    setLoader,
    count,
    totalPages,
    loader,
    getAllClients,
    setAddClients,
    totalClients,
    addClients
}) {

    const { primaryColor } = globalConstant()

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [errors, setErrors] = useState({});

    const [isUpdate, setIsUpdate] = useState(false);
    const [updateID, setUpdateID] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [editClientId, setEditClientId] = useState(null);
    const [formValues, setFormValues] = useState({
        name: '',
        logo: '',
        file: null
    });

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleOpen = () => setAddClients(true);

    const handleClose = () => {
        setIsUpdate(false);
        setIsEdit(false)
        setDisabled(false);
        setAddClients(false);
        setErrors({});
        setFormValues({
            name: '',
            logo: '',
            file: null
        })
    };

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };


    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalClients);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }


    function validate() {
        let error = false;

        if (formValues?.name.length === 0) {
            error = true;
            handleError('name', 'name is required')
        }
        return error;
    }

    const handleSetUpdateClient = (item) => {
        setIsUpdate(true);
        setFormValues({
            ...formValues,
            name: item?.name,
            logo: `${endPointURL}/uploads/clients-pictures/${item?.logo}`,
            file: null
        })
        setUpdateID(item?._id);
        handleOpen();
        setIsEdit(true);
    }

    const handleDeleteClient = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5DB761",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`clients/delete-client/${id}`, {})
                    if (data.status) {
                        Toast.fire({
                            title: "Client Deleted!",
                            color: "green",
                            icon: "success"
                        });
                        getAllClients();
                    }
                    else {
                        Toast.fire({
                            title: "Something Went Wrong!",
                            color: "red",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    // console.log("error while deleting Faq ", error);
                }
            }
        });
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormValues({ ...formValues, logo: URL.createObjectURL(file), file: file });
            setErrors({ ...errors, logo: '' });
        }
    };


    const validateForm = () => {
        let isValid = true;
        const newErrors = { logo: '', name: '' };


        if (formValues.name?.length === 0) {
            newErrors.name = 'Please enter name.';
            isValid = false;
        }

        if (!formValues.logo && !formValues.file && !isEdit) {
            newErrors.logo = 'Please upload an logo.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };


    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                const formdata = new FormData();
                formdata.append("name", formValues?.name);
                if (formValues.file) {
                    formdata.append("logo", formValues?.file);
                }

                let data;
                if (isEdit) {
                    if (formValues?.file) {
                        data = await post_data(`clients/update-client-with-picture/${updateID}`, formdata);
                    }
                    else {
                        await post_data(`clients/update-client-with-picture/${updateID}`, formdata);
                    }
                } else {
                    data = await post_data("clients/create-client", formdata);
                }

                if (data.status) {
                    handleClose();
                    Toast.fire({
                        icon: 'success',
                        title: isEdit ? "Cleint Updated Successfully" : "Cleint Added Successfully",
                        color: "green"
                    });
                    getAllClients()
                } else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "green"
                    });
                }
            } catch (error) {
                handleClose();
            }
        }
    };


    const displayAllHeader = () => {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        cursor: 'pointer',
                    }}
                >
                    <span style={{ display: 'flex', alignItems: 'center', gap: '3px', color: '#34eb52' }}>
                        <Modal
                            open={addClients}
                            onClose={handleClose}
                        >
                            <Box sx={style}>

                                {formValues.logo && (
                                    <center>
                                        <img
                                            src={formValues?.logo}
                                            alt="Uploaded Preview"
                                            style={{ marginTop: 20, width: '30%', borderRadius: '50%' }}
                                        />
                                    </center>
                                )}

                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    value={formValues?.name}
                                    onChange={handleInputChange}
                                    margin="normal"
                                    error={!!errors.name}
                                    helperText={errors.name}
                                />
                                <Button
                                    variant="contained"
                                    component="label"
                                    fullWidth
                                    style={{ boxShadow: 'none', padding: '2% 0', border: '1px dashed gray', color: 'black', marginTop: '2%', backgroundColor: 'white' }}
                                >
                                    Upload Image
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleImageUpload}
                                    />
                                </Button>
                                {errors.logo && <Typography color="error">{errors.logo}</Typography>}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleSubmit}
                                    style={{ marginTop: '2%', boxShadow: 'none', backgroundColor: primaryColor }}
                                >
                                    {isEdit ? "Update" : "Save"}
                                </Button>
                            </Box>
                        </Modal>
                    </span>
                </div>
                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        :
                        data.length > 0 ?
                            data?.map((item, i) => (
                                <div style={{ width: "100%" }} key={i}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{
                                            width: "100%",
                                            padding: "0.6% 0",
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px dashed gainsboro",
                                        }}
                                    >
                                        <Grid item xs={1}>
                                            <h3 style={{ ...table_row_font, color: "#3F4254" }}>{startEntry + i}</h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={9}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254",
                                                    margin: 0
                                                }}
                                            >
                                                {item?.name}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: '10px'
                                            }}
                                        >
                                            <img onClick={() => handleSetUpdateClient(item)} src="/images/edit-img.svg" style={{ cursor: 'pointer' }} />
                                            <img onClick={() => handleDeleteClient(item?._id)} src="/images/delete-img.svg" style={{ cursor: 'pointer' }} />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                            :
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
                            >
                                No Client Found...
                            </Grid>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${count} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}