import * as React from 'react';
import { Button, Grid, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";
import { IoLogoFacebook } from "react-icons/io";

export default function Footer(props) {

    var navigate = useNavigate()
    const [dense, setDense] = React.useState(false);
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));

    const currentYear = new Date().getFullYear();

    const legal = [
        {
            title: 'Privacy Policy',
            link: '/privacy-policy'
        },
        {
            title: 'Terms and Conditions',
            link: '/terms-and-conditions'
        },
        {
            title: 'Refund Policy',
            link: '/refund-policy'
        },
        {
            title: 'Customer Support',
            link: '/contact'
        }
    ];


    const help = [
        {
            title: 'Contact us',
            link: '/contact'
        },
        {
            title: 'FAQ',
            link: '/faq'
        }
    ];

    const services = [
        {
            title: 'Plumbing',
            link: '/plumbing'
        },
        {
            title: 'Painting',
            link: '/painting'
        },
        {
            title: 'Security',
            link: '/security'
        },
        {
            title: 'Cleaning',
            link: '/services'
        }
    ];

    const explore = [
        {
            title: 'Home',
            link: '/'
        },
        {
            title: 'About us',
            link: '/about'
        },
        {
            title: 'All Services',
            link: '/services'
        }
    ];

    const handleItemClick = (item) => {
        navigate(item?.link)
        window.scrollTo(0, 0)
    }

    // const handleLogoclick = () => {
    //     navigate('/')
    //     window.scrollTo(0, 0);
    // }

    return (
        <div>
            <div className='footer-section'>

                <Grid container spacing={1}>
                    <Grid item md={4} style={{ width: '50%' }}>
                        {/* <img src={`/images/white-logo.svg`} style={{ width: matches_md ? '70%' : '40%', cursor: 'pointer' }} onClick={handleLogoclick} /> */}
                        <p className='footer-txt' style={{ width: matches_md ? '100%' : '70%', marginTop: '5%' }}>
                            Services with a Lifestyle!
                        </p>
                        <p className='footer-txt'>
                            info@nivishka.com <br />
                            9900840337 / 9900761138 <br /><br />
                            Corporate Office - No. 2497/577, 8th Main Rd, 5th Block, 1st Stage, HBR Layout, Bengaluru, Karnataka 560043 <br /> <br />
                            Registered Office - #92, 7th Cross, Near Indus Valley College, Gayatri Layout, Basavanapura Main Road, Krishnarajapuram, Bangalore-560036
                        </p>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'left', gap: 10, marginTop: '3%'}}>
                            <a target='_blank' href='https://www.instagram.com/nivishkaservices/'><FaInstagram fontSize={20} style={{cursor: 'pointer', color: 'white'}} /></a>
                            <a target='_blank' href='https://www.facebook.com/nivishka.in/'><IoLogoFacebook fontSize={20} style={{cursor: 'pointer', color: 'white'}} /></a>
                        </div>
                    </Grid>

                    <Grid item md={2} style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                                Explore
                            </Typography>
                            <List dense={dense} style={{ marginTop: '2%' }}>
                                {
                                    explore.map((item) => {
                                        return (
                                            <ListItem style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                                                <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt'>{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid>

                    <Grid item md={2} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
                        <div>
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                                Featured Services
                            </Typography>
                            <List dense={dense} style={{ marginTop: '2%' }}>
                                {
                                    services.map((item) => {
                                        return (
                                            <ListItem style={{ paddingLeft: 0, padding: '0' }}>
                                                <ListItemText><span className='footer-txt'>{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid>

                    <Grid item md={2} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
                        <div>
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                                Help
                            </Typography>
                            <List dense={dense} style={{ marginTop: '2%' }}>
                                {
                                    help.map((item) => {
                                        return (
                                            <ListItem style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                                                <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt'>{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List></div>
                    </Grid>


                    <Grid item md={2} style={{ width: '30%', marginTop: matches_md ? '5%' : 0 }}>
                        <div>
                            <Typography style={{ fontWeight: 600, fontSize: 18, marginBottom: 5 }}>
                                Legal
                            </Typography>
                            <List dense={dense} style={{ marginTop: '2%' }}>
                                {
                                    legal.map((item) => {
                                        return (
                                            <ListItem style={{ paddingLeft: 0, padding: '0', cursor: 'pointer' }}>
                                                <ListItemText onClick={() => handleItemClick(item)}><span className='footer-txt'>{item?.title}</span></ListItemText>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid>
                </Grid>

                <hr style={{ opacity: '30%', margin: '2% 0' }} />

                <center>
                    <p className='footer-txt' style={{ margin: 0, padding: 0 }}>
                        © {currentYear} Nivishka Services. All rights reserved.
                    </p>
                </center>

            </div>
        </div>
    )
}