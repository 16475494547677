import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import PermissionTable from "../Components/PermissionTable";
import { get_data, post_data } from "../../../api";
import SearchComponent from "../Components/SearchComponent";
import AddPermissionModal from "../Components/AddPermissionModal";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../globalConstants";

export default function Permissions({ type }) {

    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [permissionData, setPermissionData] = useState([]);
    const [addPermissions, setAddPermissions] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalPermissions, setTotalPermissions] = useState(null);
    const [value, setValue] = useState('');

    const [searchState, setSearchState] = useState(false)

    const handleSearch = () => {
        setLoader(true)
        setTimeout(() => {
            if (value !== '') {
                searchPermissions()
                setSearchState(true)
            }
            else {
                getAllPermissions()
                setSearchState(false)
            }
        }, 500);
    }


    const searchPermissions = async () => {
        try {
            const data = await post_data(`sub-admin-permission/search-permissions/${value}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setPermissionData(data?.data?.subAdminPermission);
                setTotalPages(data?.data?.totalPages);
                setTotalPermissions(data?.data?.totalPermissions);
            }
            else {
                setLoader(false);
                setPermissionData([])
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error While Fetching All Sub Admins", error);
        }
    }

    const permissions_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };


    const getAllPermissions = async () => {
        try {
            const data = await get_data(`sub-admin-permission/get-all-sub-admin-permission?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setPermissionData(data?.data?.subAdminPermission);
                setTotalPages(data?.data?.totalPages);
                setTotalPermissions(data?.data?.totalPermissions);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Sub Admin", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllPermissions();
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Permission", grid: 6 },
        { title: "Assigned", grid: 3 },
        { title: "Active", grid: 1 },
        { title: "Actions", grid: 1 },
    ];

    const count = permissionData.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...permissions_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Permissions' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setAddPermissions(true)}>
                        Create
                    </Button>
                    {addPermissions && <AddPermissionModal
                        open={addPermissions}
                        setOpen={setAddPermissions}
                        getAllPermissions={getAllPermissions}
                    />}

                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>

                <div style={page_container}>
                    <PermissionTable
                        tableHeader={tableHeader}
                        type="permissions"
                        data={permissionData}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPermissions={totalPermissions}
                        setLoader={setLoader}
                        totalPages={totalPages}
                        searchState={searchState}
                        loader={loader}
                        getAllPermissions={getAllPermissions}
                    />
                </div>
            </Grid>
        </>
    )
}