import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export default function ServicesSkeleton() {

    return (
        <Grid container spacing={3}>
            <Grid item xs>
                <Skeleton variant="rounded" width={'100%'} height={400} />
            </Grid>
            <Grid item xs>
                <Skeleton variant="rounded" width={'100%'} height={400} />
            </Grid>
            <Grid item xs>
                <Skeleton variant="rounded" width={'100%'} height={400} />
            </Grid>
        </Grid>
    );
}
