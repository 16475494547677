import { Button, useMediaQuery } from "@mui/material"
import Header from "../Components/Header"
import Footer from "../Components/Footer"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { globalConstant } from "../../globalConstants"

export default function PaymentStatus() {

    const location = useLocation()
    let status = location?.state?.status
    const { user_data } = useSelector(state => state.user);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const { primaryColor } = globalConstant()
    const navigate = useNavigate()

    const success_div = {
        background: 'linear-gradient(to right, #E1F2E2, white)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
        padding: '6% 2%',
    }

    const success_box = {
        width: isDesktop ? '35%' : '90%',
        margin: 'auto',
        marginTop: isDesktop ? '' : '15%',
        padding: '2% 2% 3%',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 15,
        alignItems: 'center',
        flexDirection: 'column',
    }

    const handleClick = () => {
        navigate('/contact')
        window.scrollTo(0, 0)
    }

    const handleDashboard = () => {
        navigate('/dashboard', { state: { user_profile_details: user_data } })
        window.scrollTo(0, 0)
    }

    const btnStyles = {
        padding: '13px 40px',
        fontSize: '14px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
    }

    let image = status === 'success' ? 'success' : 'failure'
    let title = status === 'success' ? 'Thanks a lot for putting up this Booking!' : 'Payment failed, something went wrong!'

    return (
        <>
            <Header />
            <div style={success_div}>
                <div style={success_box}>
                    <img src={`/images/${image}.svg`} style={{ width: 130, marginTop: '-17%', marginBottom: '3%' }} />
                    <h3 className="global-h3" style={{ margin: '2% 0', fontSize: 20 }}>{title}</h3>
                    {/* <p style={{ textAlign: 'center', fontSize: 13, opacity: '70%' }}>
                        Your Mailto order ASK123456 has successfully been placed. You'll fin all the details about your order below, and we'll send you a shipping confrimation email as soon as your order ships. In the meantime, you can share Mailto and earn store credit.
                    </p> */}
                    <p style={{ textAlign: 'center', fontSize: 13, marginTop: '1%', opacity: '70%' }}>
                        Questions? Suggestions? insightful showe thoughts?
                    </p>
                    <p style={{ color: primaryColor, fontWeight: 500, textDecoration: 'underline', cursor: "pointer" }} onClick={handleClick}>
                        Contact us
                    </p>
                    <Link to={'/dashboard'} target="_blank">
                        <Button style={btnStyles}>Back to Dashboard</Button>
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    )
}