import styled from 'styled-components';
import { useState } from 'react';
import { post_data } from '../../../api';
import Swal from "sweetalert2";
import { TextField, Button, IconButton, InputAdornment, Collapse, Alert } from '@mui/material';
import { login } from '../../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Container = styled.div`
  width:100vw; 
  height: 100vh;
  background: linear-gradient(to right, #E1F2E2, white);
`;

const LoginBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const FormSection = styled.div`
  width: 25%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  boxSizing:border-box;
`;

const ImageSection = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  margin-bottom: 1px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Subtitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Link = styled.a`
  font-size: 14px;
  color: #6c63ff;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

function ForgetPassword() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  const handleError = (label, value) => {
    setErrors((prev) => ({ ...prev, [label]: value }))
  }

  const validate = () => {
    let error = false;

    if (email.length === 0) {
      error = true;
      handleError('email', 'Email is Required')
    }

    return error;
  }

  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    const error = validate();
    if (!error) {
      try {
        setLoader(true);
        const data = await post_data("super-admin/send-reset-password-email", { email })
        if (data.status) {
          setLoader(false);
          setSuccess(true);
        }
        else {
          setLoader(false);
          Toast.fire({
            title: "Incorrect email",
            icon: "error",
            color: "red",
          })
        }
      } catch (error) {
        setLoader(false);
        console.log("Error while sending mail", error);
      }
    }
  }

  return (
    <Container>
      <LoginBox>
        <FormSection>
          <Logo>
            <img src="/images/nivishka-logo.png" alt="Logo" style={{ width: "30%" }} />
          </Logo>
          <Title>Enter Your Email</Title>
          <Subtitle>Please enter your email to get reset link</Subtitle>
          <TextField
            type="email"
            value={email}
            label="Email Address"
            className={errors.email ? "errorborder" : ""}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            onFocus={() => {
              setErrors({ ...errors, email: "" })
            }}
          />
          <small style={{ marginBottom: '20px', color: 'red' }}>{errors.email}</small>

          <Button variant='contained'
            style={{
              boxShadow: 'none', padding: '3% 0', background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
            }}
            disabled={loader} onClick={handleSubmit}>
            Get Reset Link
          </Button>
          <div style={{ marginTop: 10 }}>
            <Collapse in={success}>
              <Alert severity="success" onClose={() => setSuccess(false)}>
                Reset password link sent successfully.
              </Alert>
            </Collapse>
          </div>
        </FormSection>
      </LoginBox>
    </Container>
  );
};

export default ForgetPassword;
