import React, { useState, useEffect } from 'react';
import { get_data } from '../../../api';
import Empty from '../../user-dashboard/Components/Empty';
import { CircularProgress, Pagination, Typography, Card, Rating, CardContent, Avatar, Box } from '@mui/material';
import Loader from '../../../website/Components/Loader';
import { MdDelete } from "react-icons/md";
import Swal from 'sweetalert2';
import { styled } from '@mui/system';
import { endPointURL, post_data } from '../../../api';

const ReviewsList = () => {

    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalReviews, setTotalReviews] = useState(null);

    const getAllReviews = async () => {
        try {
            const data = await get_data(`reviews/get-all-reviews?pageNumber=${currentPage}`);
            if (data.status) {
                setLoading(false);
                setReviews(data?.data?.reviews);
                setTotalPages(data?.data?.totalPages);
                setTotalReviews(data?.data?.totalReviews);
            }
        } catch (error) {
            setLoading(false);
            // console.log("Error while fetching Newsletters", error);
        }
    }

    useEffect(() => {
        getAllReviews()
    }, [])


    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const GridContainer = styled('div')({
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '16px',
        padding: '16px',
    });

    const ReviewCard = styled(Card)({
        borderRadius: '6px',
        boxShadow: 'none',
        border: '1px solid gainsboro',
        margin: 0,
    });

    const itemsPerPage = 15;
    const count = reviews?.length;

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalReviews);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoading(true)
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: "Do you want to Delete?",
            showDenyButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#5DB761",
            denyButtonText: "Cancel"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = await post_data(`reviews/delete-reviews/${id}`)
                if (data?.status) {
                    getAllReviews();
                    Toast.fire({
                        icon: "success",
                        text: "Feedback Deleted!",
                        color: "Green"
                    })
                } else {
                    Toast.fire({
                        icon: "error",
                        text: "Something Went Wrong",
                        color: "Red"
                    })
                }
            }
        });
    }

    return (
        <div>
            {
                loading ?
                    <>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '17%' }}>
                            <Loader />
                        </div>
                    </>
                    :
                    <>
                        {
                            reviews?.length === 0 ?
                                <>
                                    <Empty />
                                </>
                                :
                                <>
                                    <GridContainer>
                                        {reviews?.map((review) => (
                                            <ReviewCard key={review?._id} style={{ position: 'relative' }}>
                                                <MdDelete
                                                    onClick={() => handleDelete(review?._id)}
                                                    style={{ position: 'absolute', right: '3%', top: '3%', fontSize: 25, color: 'red', cursor: 'pointer' }} />
                                                <CardContent>
                                                    <Box display="flex" alignItems="center" mb={2}>
                                                        <Avatar src={`${endPointURL}/uploads/profile-picture/${review?.customer_id?.profile_picture}`} />
                                                        <Box ml={2}>
                                                            <Typography variant="h6">{review?.customer_id?.full_name || 'User'}</Typography>
                                                            <Rating value={review?.rating} readOnly />
                                                        </Box>
                                                    </Box>
                                                    <Typography variant="body1">{review?.review_text}</Typography>
                                                </CardContent>
                                            </ReviewCard>
                                        ))}
                                    </GridContainer>
                                    {reviews?.length > 0 && (
                                        <div
                                            style={{
                                                borderTop: "1px dashed gainsboro",
                                                paddingTop: "2%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: 15,
                                                    color: "black",
                                                }}
                                            >{`Showing ${startEntry} to ${endEntry} of ${count} Records`}</Typography>
                                            <Pagination
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                color="primary"
                                            />
                                        </div>
                                    )}
                                </>
                        }
                    </>
            }
        </div>
    );
};

export default ReviewsList;