import { Button, Grid } from "@mui/material";
import CategoryTable from "../components/CategoryTable";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data } from "../../api";
import { createButtonBg } from "../../globalConstants";
import DashboardHeading from "../../web-app/admin-dashboard/Components/DashboardHeading";
import PostTable from "../components/PostTable";
import { useNavigate } from "react-router-dom";

export default function Posts() {

    const navigate = useNavigate()
    const [addPost, setAddPost] = useState(false)
    const [loader, setLoader] = useState(true);
    const [postData, setPostData] = useState([]);

    const [totalPages, setTotalPages] = useState(null);
    const [totalPost, setTotalPost] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const category_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };


    const getAllPosts = async () => {
        try {
            const data = await get_data(`post/get-all-posts-admin?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setPostData(data?.data?.posts);
                setTotalPages(data?.data?.totalPages);
                setTotalPost(data?.data?.totalPost);
            }
        } catch (error) {
            setLoader(false);
        }
    }
    useEffect(() => {
        getAllPosts()
    }, [currentPage])


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Title", grid: 5 },
        { title: "Category", grid: 2 },
        { title: "Status", grid: 2 },
        { title: "Action", grid: 2 },
    ];

    const count = postData?.length;


    return (
        <>
            <Grid container spacing={0} style={{ ...category_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Posts' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => navigate('/blog/dashboard/create')}>
                        Create
                    </Button>
                </div>

                <div style={page_container}>
                    <PostTable
                        addPost={addPost}
                        setAddPost={setAddPost}
                        tableHeader={tableHeader}
                        data={postData}
                        totalPost={totalPost}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        loader={loader}
                        setLoader={setLoader}
                        getAllPosts={getAllPosts}
                    />
                </div>
            </Grid>
        </>
    )
}