import React, { useState, useEffect, useRef } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { HiCalendar } from "react-icons/hi";
import { MdPeople, MdStore, } from "react-icons/md";
import { BiSolidUserPin } from "react-icons/bi";
import { AiOutlineLogout } from "react-icons/ai";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import LogoutModal from "../../web-app/admin-dashboard/Components/LogoutModal";
import { IoMdList } from "react-icons/io";

export default function BlogAdminAvatar() {

    const navigate = useNavigate()
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const [open, setOpen] = useState(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        if (showMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMenu]);

    const handleLogout = () => {
        setShowMenu(!showMenu)
        setOpen(true);
    }

    return (
        <div style={{ position: 'relative', display: 'inline-block' }} ref={menuRef}>
            <div onClick={toggleMenu} style={{ cursor: 'pointer' }}>
                <FaUserCircle
                    style={{
                        color: 'gainsboro',
                        height: 40,
                        width: 40
                    }}
                />
            </div>
            {showMenu && (
                <div style={styles.menu}>
                    <div className="popup-menu-item" onClick={() => { navigate('/blog/dashboard/settings', { state: { tab: 0 } }); window.scrollTo(0, 0); setShowMenu(!showMenu) }} style={styles.menuItem}>
                        <FaUserCircle style={{ color: 'gray', fontSize: 20 }} />
                        My Profile
                    </div>
                    <div className="popup-menu-item" onClick={() => { navigate('/blog/dashboard/posts', { state: { tab: 1 } }); window.scrollTo(0, 0); setShowMenu(!showMenu) }} style={styles.menuItem}>
                        <IoMdList style={{ color: 'gray', fontSize: 20 }} />
                        Posts
                    </div>
                    <div className="popup-menu-item" onClick={handleLogout} style={{ ...styles.menuItem, borderBottom: 'none', color: 'red' }}>
                        <AiOutlineLogout style={{ color: 'red', fontSize: 20 }} />
                        Logout
                    </div>
                </div>
            )}
            {open && <LogoutModal open={open} setOpen={setOpen} />}
        </div>
    );
}

const styles = {
    menu: {
        position: 'absolute',
        top: '45px',
        right: 0,
        width: 230,
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        zIndex: 99,
    },
    menuItem: {
        padding: '8%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: 15,
        borderBottom: '1px solid #eee',
    },
    lastMenuItem: {
        borderBottom: 'none',
    }
};