import { Grid, Modal, TextField, Box, Button, Rating } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import Swal from "sweetalert2";

const AddTestimonials = ({ open, setOpen, getAllTestimonials, selectedTestimonial, isUpdate }) => {

    const [testimonial, setTestimonial] = useState('');
    const [name, setName] = useState('');
    const [rating, setRating] = useState(0);
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (selectedTestimonial) {
            setTestimonial(selectedTestimonial?.review || '');
            setName(selectedTestimonial?.name || '');
            setRating(selectedTestimonial?.rating || 0);
        }
    }, [open]);

    const handleClose = () => {
        setTestimonial('');
        setName('');
        setRating(0);
        setOpen(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'white',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
    };

    const validate = () => {
        let hasError = false;
        let errors = {};

        if (!name) {
            hasError = true;
            errors.name = 'Name is required';
        }

        if (!testimonial) {
            hasError = true;
            errors.testimonial = 'Review is required';
        }

        if (rating === 0) {
            hasError = true;
            errors.rating = 'Rating is required';
        }

        setErrors(errors);
        return hasError;
    };

    const handleAdd = async () => {
        const hasError = validate();
        if (!hasError) {
            setDisabled(true);
            const data = await post_data('testimonials/create-testimonial', { name, review: testimonial, rating });

            if (data?.status) {
                handleClose();
                getAllTestimonials();
                Toast.fire({
                    title: "Testimonial created successfully",
                    icon: 'success',
                    color: 'green'
                });
            } else {
                Toast.fire({
                    title: "Something went wrong",
                    icon: 'warning',
                    color: 'red'
                });
            }
            setDisabled(false);
        }
    };

    const handleUpdate = async () => {
        const hasError = validate();
        if (!hasError) {
            const data = await post_data(`testimonials/update-testimonial/${selectedTestimonial?._id}`, { name, review: testimonial, rating });

            if (data?.status) {
                handleClose();
                getAllTestimonials();
                Toast.fire({
                    title: "Testimonial updated successfully",
                    icon: 'success',
                    color: 'green'
                });
            } else {
                Toast.fire({
                    title: "Something went wrong",
                    icon: 'warning',
                    color: 'red'
                });
            }
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            name="name"
                            variant="outlined"
                            value={name}
                            error={!!errors.name}
                            fullWidth
                            helperText={errors.name}
                            onChange={(e) => {
                                setName(e?.target?.value);
                                setErrors(prev => ({ ...prev, name: '' }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Review"
                            multiline
                            rows={3}
                            name="testimonial"
                            variant="outlined"
                            value={testimonial}
                            error={!!errors.testimonial}
                            fullWidth
                            helperText={errors.testimonial}
                            onChange={(e) => {
                                setTestimonial(e?.target?.value);
                                setErrors(prev => ({ ...prev, testimonial: '' }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Rating
                            name="rating"
                            value={rating}
                            precision={0.5}
                            onChange={(event, newValue) => {
                                setRating(newValue);
                                setErrors(prev => ({ ...prev, rating: '' }));
                            }}
                        />
                        {errors.rating && <Box sx={{ color: 'red', mt: 1 }}>{errors.rating}</Box>}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={disabled}
                            sx={{
                                boxShadow: 'none', padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761', boxShadow: 'none' }
                            }}
                            onClick={isUpdate ? handleUpdate : handleAdd}
                        >
                            {isUpdate ? "Update Testimonial" : "Add Testimonial"}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default AddTestimonials;
