import React, { useEffect, useState } from "react";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { globalConstant } from "../../globalConstants";
import OurAllServices from "../Components/OurAllServices";
import FeaturedSubServices from "../Components/FeaturedSubServices";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import Testimonials from "../Components/Testimonials";
import { endPointURL, get_data, post_data } from "../../api"
import ServicesSkeleton from "../Components/Skeleton/ServicesSkeleton";

export default function AllServices() {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { primaryColor } = globalConstant();
    const [serviceData, setServicesData] = useState([])
    const [loader, setLoader] = useState(true)

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: isDesktop ? '3%' : '20px 5px',
            backgroundColor: '#efffef',
        },
        leftSide: {
            flex: 1,
            padding: '20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        heading: {
            fontSize: isDesktop ? 45 : 32,
            fontWeight: 600,
            marginBottom: '20px',
            marginTop: 0,
        },
        highlight: {
            color: primaryColor,
        },
        description: {
            width: '90%',
            fontSize: '16px',
            marginBottom: '20px',
        },
    }


    const getAllServices = async () => {
        try {
            const data = await get_data("services/get-all-services");
            if (data.status) {
                setLoader(false);
                setServicesData(data?.data?.services);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Services", error);
        }
    }

    useEffect(() => {
        getAllServices();
    }, [])

    return (
        <>
            <Header />

            <div style={styles.container} >
                <div style={styles.leftSide}>
                    <h2 style={styles.heading}>All Services</h2>
                    <p style={styles.description}>
                        At Nivishka Services, we are committed to delivering top-notch home maintenance and furnishing solutions that make your home shine. Our team of skilled professionals ensures every detail is perfect, providing you with the best service available in the industry.
                    </p>
                </div>
                <div style={styles.rightSide}>
                </div>
            </div>
            {
                loader ?
                    <div style={{ padding: '4% 10% 2%' }}>
                        <ServicesSkeleton />
                    </div>
                    :
                    <>
                        <OurAllServices serviceData={serviceData} />
                    </>
            }
            <FeaturedSubServices />
            <Testimonials />
            <Newsletter />
            <Footer />
        </>
    )
}