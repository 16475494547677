import { Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import StatisticsComp from "./StatisticsComp";

export default function DashboardComponent({
    socket,
    permissions
}) {

    const [loading, setLoading] = useState(true)

    const dashboard_page = {
        width: '100%',
    }

    const page_container = {
        width: "100%",
        paddingBottom: '3%'
    };

    const container = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '20px',
        flexWrap: 'wrap',
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])

    return (
        <>
            <Grid container spacing={0} style={{ ...dashboard_page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={page_container}>
                    <StatisticsComp socket={socket} permissions={permissions} />
                </div>

            </Grid>
        </>
    )
}