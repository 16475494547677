import React, { useRef, useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Button, Grid, Pagination, Rating } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReviewDrawer from './ReviewDrawer';
import { formatDate, globalConstant } from '../../globalConstants';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Empty from '../../web-app/user-dashboard/Components/Empty';

export default function ServiceReviews({ service, reviews, totalPages, setCurrentPage, currentPage }) {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const matches_md = useMediaQuery('(min-width:960px)');
    const { primaryColor } = globalConstant()

    const rating = (value) => {
        return (
            <Box>
                <Rating name="read-only" value={value} readOnly
                    emptyIcon={<StarBorderIcon style={{ color: '#BDBDBD' }} />}
                    icon={<StarIcon style={{ color: primaryColor }} />}
                />
            </Box>
        )
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    console.log('reviews, ', reviews)
    const showReviews = () => {
        return (
            <div>
                <Grid container spacing={1} style={{ width: '100%' }}>
                    <Grid item md={12} style={{ width: '100%', marginTop: matches_md ? '3%' : 0 }}>
                        <h3 style={{ margin: 0, fontWeight: 500, fontSize: 20 }}>Reviews of this Service</h3>
                        <p style={{ fontSize: 16, opacity: '70%', margin: 0 }}>What our customers say after using our services.</p><br />
                    </Grid>
                </Grid><br />
                <hr style={{ opacity: '20%' }} />
                {
                    reviews?.length === 0 ?
                        <Empty />
                        :
                        reviews?.map((item, i) => (
                            <div style={{ width: '100%' }} key={i}>
                                <Grid container spacing={1} style={{ width: isDesktop ? '30%' : '100%' }}>
                                    <Grid item md={6} style={{ width: '100%', marginTop: matches_md ? '3%' : 0 }}>
                                        <h3 style={{ margin: 0, fontWeight: 600, fontSize: 19 }}>{item?.customer_id?.full_name}</h3>
                                    </Grid>
                                    <Grid item md={6} style={{ width: '100%', marginTop: matches_md ? '3%' : 0, display: 'flex', alignItems: 'center', gap: '3%' }}>
                                        <CheckCircleIcon style={{ color: primaryColor, width: 18 }} />
                                        <p style={{ margin: 0, fontWeight: 400, fontSize: 14 }}>Verified Purchased</p>
                                    </Grid>
                                    <Grid item md={6} style={{ width: '100%', marginTop: matches_md ? '3%' : 0 }}>
                                        <h3 style={{ margin: 0, fontWeight: 400, fontSize: 14 }}>{rating(item?.rating)}</h3>
                                    </Grid>
                                    <Grid item md={6} style={{ width: '100%', marginTop: matches_md ? '3%' : 0 }}>
                                        <p style={{ margin: 0, fontWeight: 400, fontSize: 14 }}>{formatDate(item?.created_at)}</p>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} style={{ margin: 0 }}>
                                    <p style={{ margin: 0, fontWeight: 400, fontSize: 16, width: isDesktop ? '70%' : '100%', marginTop: '1%' }}>
                                        {item?.review_text}
                                    </p>
                                </Grid><br />
                                <hr style={{ opacity: '20%' }} />
                            </div>
                        ))
                }
                <Pagination
                    sx={{
                        "&& .Mui-selected": {
                            backgroundColor: "#5DB761",
                            color: "#fff"
                        }
                    }}
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                />
            </div>
        );
    };

    return (
        <div style={{ padding: '2% 7%' }}>
            {showReviews()}
        </div>
    );
}
