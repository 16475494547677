import { useEffect, useState } from "react";
import { Card, Grid, Modal, Box, TextField, Button, Typography } from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import BannerCard from "../components/BannerCard";
import { globalConstant } from "../../globalConstants";
import DashboardHeading from "../../web-app/admin-dashboard/Components/DashboardHeading";
import Swal from 'sweetalert2';
import { endPointURL, get_data, post_data } from "../../api";
import SubServicesSkeleton from "../../../src/website/Components/Skeleton/SubServicesSkeleton";

export default function Banners() {

    const { primaryColor } = globalConstant();
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editBannerId, setEditBannerId] = useState(null);
    const [formValues, setFormValues] = useState({
        name: '',
        order: '',
        image: '',
        file: null
    });

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const [errors, setErrors] = useState({
        order: '',
        image: ''
    });

    const banners_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const handleClick = () => {
        setOpen(true);
        setIsEdit(false);
        setFormValues({ name: '', order: '', image: '', file: null });
    };

    const handleClose = () => {
        setOpen(false);
        setFormValues({ name: '', order: '', image: '', file: null });
        setErrors({ order: '', image: '' });
        setIsEdit(false);
        setEditBannerId(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormValues({ ...formValues, image: URL.createObjectURL(file), file: file });
            setErrors({ ...errors, image: '' });
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = { order: '', image: '' };

        if (!/^\d+$/.test(formValues.order) || parseInt(formValues.order) <= 0) {
            newErrors.order = 'Order must be a positive integer.';
            isValid = false;
        }

        if (!formValues.image && !formValues.file && !isEdit) {
            newErrors.image = 'Please upload an image.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                const formdata = new FormData();
                formdata.append("name", formValues?.name);
                formdata.append("order", formValues?.order);
                if (formValues.file) {
                    formdata.append("banner", formValues?.file);
                }

                let data;
                if (isEdit) {
                    if (formValues.file) {
                        data = await post_data(`banners/update-banner-v2/${editBannerId}`, formdata);
                    } else {
                        data = await post_data(`banners/update-banner/${editBannerId}`, { name: formValues?.name, order: formValues?.order });
                    }
                } else {
                    data = await post_data("banners/create-banner", formdata);
                }

                if (data.status) {
                    handleClose();
                    getAllBanners();
                    Toast.fire({
                        icon: 'success',
                        title: isEdit ? "Banner Updated Successfully" : "Banner Added Successfully",
                        color: "green"
                    });
                } else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "green"
                    });
                }
            } catch (error) {
                handleClose();
            }
        }
    };

    const handleEditClick = (banner) => {
        setFormValues({
            name: banner.name,
            order: banner.order,
            image: `${endPointURL}/uploads/banners-picture/${banner.banner}`, // Assuming `banner.banner` is the image path
            file: null
        });
        setEditBannerId(banner._id);
        setIsEdit(true);
        setOpen(true);
    };

    const handleDeleteClick = (bannerId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: primaryColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`banners/delete-banner/${bannerId}`);
                    if (data.status) {
                        getAllBanners();
                        Toast.fire({
                            icon: 'success',
                            title: 'Banner Deleted Successfully',
                            color: "green"
                        });
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: "Something Went Wrong",
                            color: "green"
                        });
                    }
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Failed to delete the banner',
                        color: "red"
                    });
                }
            }
        });
    };

    const getAllBanners = async () => {
        try {
            const data = await get_data(`banners/get-all-banners`);
            if (data.status) {
                setLoader(false);
                setData(data?.data);
            }
        } catch (error) {
            setLoader(false);
        }
    };

    useEffect(() => {
        getAllBanners();
    }, []);

    return (
        <>
            <Grid container spacing={0} style={{ ...banners_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Banners' />
                {
                    loader ?
                        <>
                            <SubServicesSkeleton />
                        </>
                        :
                        <>
                            <div style={page_container}>
                                <div style={{ display: 'flex', gap: 30 }}>
                                    <Grid container spacing={3}>
                                        <Grid item md={3}>
                                            <div onClick={handleClick} style={{
                                                width: 280, height: '100%', borderRadius: 2, cursor: 'pointer',
                                                border: '1px dashed gray', padding: 0, boxShadow: 'none',
                                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                                            }}>
                                                <IoMdAdd style={{ fontSize: 60 }} />
                                            </div>
                                        </Grid>
                                        {
                                            data?.map((item, i) => (
                                                <Grid item md={3} key={i}>
                                                    <div style={{ borderRadius: 5, border: '1px solid #e0e0e0', padding: 0, boxShadow: 'none', background: 'white' }}>
                                                        <BannerCard
                                                            banner={item}
                                                            onEdit={() => handleEditClick(item)}
                                                            onDelete={() => handleDeleteClick(item?._id)}
                                                        />
                                                    </div>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </div>
                            </div>
                        </>
                }
            </Grid>

            <Modal open={open} onClose={handleClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}>
                    <h2 style={{ margin: 0, padding: 0 }}>{isEdit ? "Edit Banner" : "Add Banner"}</h2>

                    {formValues.image && (
                        <img
                            src={formValues.image}
                            alt="Uploaded Preview"
                            style={{ marginTop: 20, width: '100%', borderRadius: 10 }}
                        />
                    )}

                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Order"
                        name="order"
                        value={formValues.order}
                        onChange={handleInputChange}
                        margin="normal"
                        error={!!errors.order}
                        helperText={errors.order}
                    />
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        style={{ boxShadow: 'none', padding: '2% 0', border: '1px dashed gray', color: 'black', marginTop: '2%', backgroundColor: 'white' }}
                    >
                        Upload Image
                        <input
                            type="file"
                            hidden
                            onChange={handleImageUpload}
                        />
                    </Button>
                    {errors.image && <Typography color="error">{errors.image}</Typography>}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                        style={{ marginTop: '2%', boxShadow: 'none', backgroundColor: primaryColor }}
                    >
                        {isEdit ? "Update" : "Save"}
                    </Button>
                </Box>
            </Modal>
        </>
    );
}
