import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import AllFeedbacks from "../Components/AllFeedbacks";
import Loader from "../../../website/Components/Loader";
import AllTestimonials from "../Components/AllTestimonials";
import { createButtonBg } from "../../../globalConstants";
import { IoMdAdd } from "react-icons/io";
import AddTestimonials from "../Components/AddTestimonials";

export default function Testimonials({ type }) {

    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalTestimonials, setTotalTestimonials] = useState(null);

    const permissions_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };


    const getAllTestimonials = async () => {
        try {
            const data = await get_data(`testimonials/get-all-testimonials?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setData(data?.data?.testimonials);
                setTotalPages(data?.data?.totalPages);
                setTotalTestimonials(data?.data?.totalTestimonials);
            }
        } catch (error) {
            setLoader(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllTestimonials(currentPage);
        }
    }, [currentPage])


    const itemsPerPage = 15;
    const count = data?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...permissions_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Testimonials' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button variant="contained" startIcon={<IoMdAdd style={{ color: 'white' }} />} style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setOpen(true)}>
                        Create
                    </Button>
                    <AddTestimonials 
                    open={open}
                    setOpen={setOpen}
                    isUpdate={false}
                    getAllTestimonials={getAllTestimonials}
                    />
                </div>

                <div style={page_container}>
                    {
                        loader ?
                            <>
                                <Loader />
                            </>
                            :
                            <>
                                <AllTestimonials
                                    data={data}
                                    setLoader={setLoader}
                                    getAllTestimonials={getAllTestimonials}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    count={count}
                                    totalPages={totalPages}
                                    itemsPerPage={itemsPerPage}
                                    totalTestimonials={totalTestimonials}
                                />
                            </>
                    }
                </div>
            </Grid>
        </>
    )
}