import React from "react";
import ContactForm from "../Components/ContactForm";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

export default function Contact() {
    return (
        <>
            <Header />
            <ContactForm />
            <Footer />
        </>
    )
}