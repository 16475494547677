import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, useMediaQuery, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginModal from './LoginModal';
import UserAvatar from './UserAvatar';
import { useNavigate } from 'react-router-dom';
import { BsCart2 } from "react-icons/bs";
import { globalConstant } from '../../globalConstants';
import CartDrawer from './CartDrawer';
import { useSelector } from 'react-redux';
import LogoutModal from '../../web-app/admin-dashboard/Components/LogoutModal';

const Header = () => {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [openLogout, setOpenLogout] = useState(false);
    const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const navigate = useNavigate();
    const { primaryColor } = globalConstant();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { isLoggedIn } = useSelector(state => state.user);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const toggleCartDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsCartDrawerOpen(open);
    };

    const menuItems = [
        { text: "Home", link: "/" },
        { text: "About", link: "/about" },
        { text: "Services", link: "/services" },
        { text: "Contact", link: "/contact" },
        { text: <BsCart2 />, icon: true, link: "/checkout" },
        { text: isLoggedIn ? "My Account" : "Login / Sign Up", link: isLoggedIn ? "/dashboard" : "/", button: true }
    ];

    const handleClick = () => {
        setOpen(true);
    };

    const button = {
        padding: '8px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
        boxShadow: 'none',
    }

    const handleClick2 = (item) => {
        if (isLoggedIn) {
            navigate('/dashboard')
            window.scrollTo(0, 0)
            console.log('hello');
        }
        else {
            handleClick()
            toggleDrawer(false)('')
        }
    }


    const drawerContent = (
        <>
            <List>
                {menuItems?.map((item, index) => (
                    <ListItem button key={index} onClick={() => { navigate(item.link); window.scrollTo(0, 0) }}>
                        {item.button ? (
                            <Button onClick={handleClick2} className="cta-Button" style={button} fullWidth variant='contained'>{item.text}</Button>
                        ) :
                            item?.icon ? (
                                <BsCart2 style={{ width: 30, height: 30, color: primaryColor }} onClick={() => { navigate(item.link); window.scrollTo(0, 0) }} />
                            ) :
                                (
                                    <ListItemText primary={item.text} />
                                )}
                    </ListItem>
                ))}
                {
                    !isDesktop && isLoggedIn && (
                        <>
                            <center>
                                <Button
                                    onClick={() => {
                                        setOpenLogout(true)
                                        toggleDrawer(false)('')
                                    }}
                                    style={{ ...button, border: `2px solid red`, color: 'red', background: 'white', width: '90%', margin: 'auto' }} variant='contained'>Logout</Button>
                            </center>
                        </>
                    )
                }
            </List>
        </>
    );

    const styles = {
        navItem: {
            margin: '0 15px',
            cursor: 'pointer'
        },
        nav: {
            display: 'flex',
            alignItems: 'center'
        }
    };


    return (
        <>
            <header className="header">
                <div className="logo">
                    <img src='/images/nivishka-logo.png' style={{ width: 180, cursor: 'pointer' }} onClick={() => navigate('/')} />
                </div>
                {!isMobile && (
                    <nav className="nav" style={styles.nav}>
                        {menuItems.map((item, index) => (
                            item.button ? (
                                <>
                                    {isLoggedIn ? <UserAvatar /> : <><button className="cta-button" key={index} onClick={handleClick}>{item.text}</button></>}
                                </>
                            ) :
                                item.icon ? (
                                    <IconButton color="inherit" aria-label="cart" onClick={() => { navigate('/checkout'); window.scrollTo(0, 0) }}>
                                        <BsCart2 style={{ width: 30, height: 30, color: primaryColor }} />
                                    </IconButton>
                                ) : (
                                    <a onClick={() => { navigate(item.link); window.scrollTo(0, 0) }} className="nav-item" key={index} style={styles.navItem}>{item.text}</a>
                                )
                        ))}
                    </nav>
                )}
                {isMobile && (
                    <>
                        <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon style={{ width: 35, height: 35 }} />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={isDrawerOpen}
                            onClose={toggleDrawer(false)}
                            sx={{ '& .MuiDrawer-paper': { width: 300, zIndex: 9999 } }} // Adjust the width here
                        >
                            {drawerContent}
                        </Drawer>
                    </>
                )}
            </header>
            <CartDrawer isCartDrawerOpen={isCartDrawerOpen} toggleCartDrawer={toggleCartDrawer} />
            <LoginModal open={open} setOpen={setOpen} />
            <LogoutModal open={openLogout} setOpen={setOpenLogout} />
        </>
    );
};

export default Header;
