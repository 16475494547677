import React from 'react';
import { FaThumbsUp, FaShippingFast, FaUndo } from 'react-icons/fa';

const TrustBadges = () => {
    return (
        <div className="trust-badges-container">
            <div className="trust-badge">
                <FaThumbsUp className="badge-icon" />
                <div className="badge-text">
                    <h3>Satisfaction Guaranteed</h3>
                    <p>Every product we sell comes with our satisfaction guarantee. We stand behind our craftsmanship.</p>
                </div>
            </div>
            <div className="trust-badge">
                <FaShippingFast className="badge-icon" />
                <div className="badge-text">
                    <h3>Fast Shipping</h3>
                    <p>Most products ship within 1-2 business days. Choose from our express options for faster delivery.</p>
                </div>
            </div>
            <div className="trust-badge">
                <FaUndo className="badge-icon" />
                <div className="badge-text">
                    <h3>24 Hours Refund Policy</h3>
                    <p>Don't love your new products? Request a refund within 14 days and we'll send your money back - no questions asked!</p>
                </div>
            </div>
        </div>
    );
};

export default TrustBadges;
