import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import FaqTable from "../Components/FaqTable";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../globalConstants";

export default function Faq({ type }) {

    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalFaqs, setTotalFaqs] = useState(null);

    const permissions_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };


    const getAllFaqs = async () => {
        try {
            const data = await get_data(`faq/get-all-faq?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setData(data?.data?.faq);
                setTotalPages(data?.data?.totalPages);
                setTotalFaqs(data?.data?.totalFaqs);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Newsletters", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllFaqs(currentPage);
        }
    }, [currentPage])


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Question", grid: 9 },
        { title: "Actions", grid: 2 },
    ];

    const count = data.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...permissions_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='FAQs' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none'}}
                        onClick={() => setOpen(true)}
                    >
                        Create
                    </Button>
                </div>

                <div style={page_container}>
                    <FaqTable
                        tableHeader={tableHeader}
                        type="permissions"
                        data={data}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        loader={loader}
                        totalFaqs={totalFaqs}
                        addFaq={open}
                        setLoader={setLoader}
                        setAddFaq={setOpen}
                        getAllFaqs={getAllFaqs}
                    />
                </div>
            </Grid>
        </>
    )
}