import React, { useEffect, useState } from "react";
import { Grid, Typography, Pagination, CircularProgress, Modal, Box, TextField, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, FormHelperText } from "@mui/material";
import { endPointURL, get_data, post_data } from "../../../api";
import Swal from 'sweetalert2';
import { IoClose } from "react-icons/io5";
import styled from 'styled-components';
import Loader from "../../../website/Components/Loader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMemo } from "react";

const ServiceBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  background: #fff;
  box-sizing:border-box;
  overflow: hidden;
  `;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px'
};


export default function VendorFaqTable({
    tableHeader,
    data,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    count,
    totalPages,
    loader,
    getAllFaqs,
    setAddFaq,
    addFaq,
    setLoader,
    totalFaqs
}) {

    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [errors, setErrors] = useState({});

    const [isUpdate, setIsUpdate] = useState(false);
    const [updateID, setUpdateID] = useState('');
    const [disabled, setDisabled] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleOpen = () => setAddFaq(true);

    const handleClose = () => {
        setIsUpdate(false);
        setDisabled(false);
        setAddFaq(false);
        setErrors({});
        setAnswer('')
        setQuestion('')
    };

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };


    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalFaqs);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }


    function validate() {
        let error = false;

        if (question.length === 0) {
            error = true;
            handleError('question', 'Question is required')
        }
        if (answer.length === 0) {
            error = true;
            handleError('answer', 'Asnwer is required')
        }
        return error;
    }

    const handleAddFaq = async () => {
        const error = validate();
        if (!error) {
            try {
                setDisabled(true);
                const payload = {
                    question: question,
                    answer: answer
                }

                const data = await post_data("vendor-faq/create-faq", payload);

                if (data.status) {
                    handleClose();
                    getAllFaqs();
                    Toast.fire({
                        icon: 'success',
                        title: "Faq added successfully",
                        color: "green"
                    })
                }
                else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "green"
                    })
                }
            } catch (error) {
                handleClose();
                // console.log("Error While Add Sub Service ", error);
            }
        }
    }

    const handleSetUpdateProfession = (item) => {
        setIsUpdate(true);
        setUpdateID(item?._id);
        setQuestion(item?.question);
        setAnswer(item?.answer);
        handleOpen();
    }


    const handleDeleteProfession = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5DB761",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`vendor-faq/delete-faq/${id}`, {})
                    if (data.status) {
                        Toast.fire({
                            title: "Faq Deleted!",
                            color: "green",
                            icon: "success"
                        });
                        getAllFaqs();
                    }
                    else {
                        Toast.fire({
                            title: "Something Went Wrong!",
                            color: "red",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    // console.log("error while deleting Faq ", error);
                }
            }
        });
    }

    const handleUpdateFaq = async () => {
        const error = validate();
        if (!error) {
            setDisabled(true);
            try {
                const body = {
                    question: question, answer: answer
                }
                const data = await post_data(`vendor-faq/update-faq/${updateID}`, body);

                if (data.status) {
                    handleClose();
                    getAllFaqs();
                    setAnswer('')
                    setQuestion('')
                    Toast.fire({
                        icon: 'success',
                        title: "Faq Updated Successfully",
                        color: "green"
                    })
                }
                else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "red"
                    })
                }
            } catch (error) {
                handleClose();
                // console.log("Error while deleting Faq", error);
            }
        }
    }

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', "strike"],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['image', "link", "video"],
                ['clean'],
                ['code-block'],
                [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
            ],
            imageResize: {
                displaySize: true
            }
        },
    }), [])

    const handleQuill = (newValue) => {
        setAnswer(newValue)
        if (newValue.trim() !== '') {
            // handleError('', 'body');
        }
    }


    const displayAllHeader = () => {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        cursor: 'pointer',
                    }}
                >
                    <span style={{ display: 'flex', alignItems: 'center', gap: '3px', color: '#34eb52' }}>
                        <Modal
                            open={addFaq}
                            onClose={handleClose}
                        >
                            <Box sx={style}>
                                <Typography>
                                    <ServiceBox>
                                        <Grid container spacing={2}>

                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Enter Question"
                                                    value={question}
                                                    fullWidth
                                                    error={errors.question}
                                                    helperText={errors.question}
                                                    sx={{ mt: 2 }}
                                                    onChange={(e) => {
                                                        setQuestion(e.target.value);
                                                    }}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, question: "" })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} style={{ marginBottom: '10%' }} >
                                                <ReactQuill
                                                    style={{ height: 200 }}
                                                    error={errors.answer}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, answer: "" })
                                                    }}
                                                    theme="snow" value={answer} onChange={handleQuill} modules={modules}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    disabled={disabled}
                                                    sx={{
                                                        padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                                                    }}
                                                    onClick={isUpdate ? handleUpdateFaq : handleAddFaq}
                                                >
                                                    {isUpdate ? "Update Faq" : "Add Faq"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ServiceBox>
                                </Typography>
                            </Box>
                        </Modal>
                    </span>
                </div>
                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        :
                        data.length > 0 ?
                            data?.map((item, i) => (
                                <div style={{ width: "100%" }} key={i}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{
                                            width: "100%",
                                            padding: "0.6% 0",
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px dashed gainsboro",
                                        }}
                                    >
                                        <Grid item xs={1}>
                                            <h3 style={{ ...table_row_font, color: "#3F4254" }}>{startEntry + i}</h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={9}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254",
                                                    margin: 0
                                                }}
                                            >
                                                {item?.question}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: '10px'
                                            }}
                                        >
                                            <img onClick={() => handleSetUpdateProfession(item)} src="/images/edit-img.svg" style={{ cursor: 'pointer' }} />
                                            <img onClick={() => handleDeleteProfession(item._id)} src="/images/delete-img.svg" style={{ cursor: 'pointer' }} />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                            :
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
                            >
                                No Faq Found...
                            </Grid>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalFaqs} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}