import React, { useState } from 'react';
import { Modal, Box, Typography, Button, useMediaQuery } from '@mui/material';
import { globalConstant } from '../../../globalConstants';
import { useNavigate } from 'react-router-dom';

const backdropStyle = {
    backgroundColor: '#000000c5', // Customize this color as needed
};

const CartModal = ({ open, setOpen, setOnsitePaymentOpen, screen }) => {

    const navigate = useNavigate()
    const handleClose = () => setOpen(false)
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [loading, setLoading] = useState(false)

    const { primaryColor } = globalConstant()

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isDesktop ? 350 : '80%',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            padding: isDesktop ? '4% 3%' : '5%',
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: isDesktop ? 27 : 22,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        },
        button: {
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            borderRadius: '8px',
            cursor: 'pointer',
            border: '2px solid ' + primaryColor,
            marginTop: '3%',
            width: '50%',
            boxShadow: 'none'
        }
    };

    const handleClick = () => {
        setLoading(true)
        setTimeout(() => {
            setOpen(false)
            if (screen === 'onsite')
                setOnsitePaymentOpen(true);
            else
                navigate('/checkout');
            window.scrollTo(0, 0)
            setLoading(false)
        }, 1000);
    }

    return (
        <Modal
            BackdropProps={{
                style: backdropStyle,
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
        >
            <Box sx={styles.modalBox}>
                <img src='/images/success.svg' style={{ width: isDesktop ? 150 : 120, marginBottom: '3%' }} />
                <Typography id="logout-modal-title" variant="h6" sx={styles.heading}>
                    Added to Cart Successfully!
                </Typography>
                <Box sx={styles.buttons}>
                    <Button variant="outlined" onClick={handleClose} style={{ ...styles.button, color: primaryColor, background: 'white' }}>
                        Close
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleClick} style={styles.button}>
                        {loading ? 'Checkout...' : 'Checkout'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CartModal;