import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, IconButton, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery } from '@mui/material';
import { convertMinutes, formatPrice, globalConstant } from '../../globalConstants';
import { useNavigate } from 'react-router-dom';
import { get_data, post_data } from '../../api';
import { useSelector } from 'react-redux';
import Empty from "../../web-app/user-dashboard/Components/Empty";

const CartDrawer = ({ isCartDrawerOpen, setIsCartDrawerOpen, setRefresh, refresh }) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const { primaryColor } = globalConstant();
    const navigate = useNavigate();
    const { user_data } = useSelector(state => state.user);
    const [allSubServices, setAllSubServices] = useState([]);
    const [loadingIds, setLoadingIds] = useState([]); // State to track loading state for each item

    const styles = {
        drawer: {
            width: isMobile ? '100%' : '350px',
            padding: '20px',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
        },
        cartItem: {
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            padding: '10px 0',
            borderBottom: '1px solid #E0E0E0',
        },
        itemDetails: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: '10px',
        },
        itemImage: {
            width: '80px',
            height: '80px',
            borderRadius: '8px',
            objectFit: 'cover',
        },
        itemName: {
            fontWeight: '600',
            fontSize: '16px',
        },
        itemPrice: {
            color: primaryColor,
            fontWeight: 'bold',
        },
        removeButton: {
            color: 'red',
            marginLeft: 'auto'
        },
        checkoutButton: {
            padding: '13px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: primaryColor,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '100%',
            margin: '5% 0'
        },
    };

    const handleClick = () => {
        navigate('/checkout');
        window.scrollTo(0, 0);
    };

    const fetchSelectedServices = async () => {
        const response = await get_data(`selected-service/get-selected-services-by-customer/${user_data?._id}`);
        if (response?.status) {
            setAllSubServices(response?.data);
        }
    };

    useEffect(() => {
        fetchSelectedServices();
    }, [user_data, refresh]);

    const handleDelete = async (id) => {
        setLoadingIds((prev) => [...prev, id]); // Add id to loading state
        setTimeout(async () => {
            try {
                const response = await post_data(`selected-service/delete-selected-service-by-serviceId/${id}`);
                if (response?.status) {
                    fetchSelectedServices();
                    setRefresh(!refresh)
                }
            } catch (e) {
                console.log("error ", e);
            } finally {
                setLoadingIds((prev) => prev.filter((loadingId) => loadingId !== id)); // Remove id from loading state
            }
        }, 1000);
    };

    return (
        <Drawer
            anchor="right"
            open={isCartDrawerOpen}
            onClose={() => setIsCartDrawerOpen(false)}
            sx={{ '& .MuiDrawer-paper': styles.drawer }}
        >
            <Box style={{ paddingBottom: '8%' }}>
                <Box sx={styles.header}>
                    <Typography variant="h6">Cart Items</Typography>
                    <IconButton onClick={() => setIsCartDrawerOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {
                    allSubServices?.length === 0 ?
                        <Empty />
                        :
                        <>
                            {allSubServices?.map((item, index) => (
                                <Box key={index} sx={styles.cartItem}>
                                    <img src="/images/house-keeping.svg" alt="item" style={styles.itemImage} />
                                    <Box sx={styles.itemDetails}>
                                        <Typography sx={styles.itemName}>{item?.selected_sub_service_id?.sub_service_name}</Typography>
                                        <Typography>Duration: {convertMinutes(item?.selected_sub_service_id?.duration)}</Typography>
                                        <div style={{ display: 'flex', gap: '10%' }}>
                                            <Typography sx={styles.itemPrice}>{formatPrice(item?.selected_sub_service_id?.discounted_price)}</Typography>
                                            <Typography sx={{ textDecoration: 'line-through', color: '#9E9E9E' }}>{formatPrice(item?.selected_sub_service_id?.price)}</Typography>
                                        </div>
                                    </Box>
                                    <IconButton
                                        onClick={() => handleDelete(item?._id)}
                                        style={styles.removeButton}
                                        disabled={loadingIds.includes(item?._id)}
                                    >
                                        {loadingIds.includes(item?._id) ? <CircularProgress size={24} style={{ color: 'black' }} /> : <DeleteIcon />}
                                    </IconButton>
                                </Box>
                            ))}
                            <Button style={styles.checkoutButton} onClick={handleClick}>Proceed to Checkout</Button>
                        </>
                }
            </Box>
        </Drawer>
    );
};

export default CartDrawer;