import React, { useEffect, useState } from "react";
import { Grid, Typography, Pagination, Switch } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { post_data } from "../../../api";
import Loader from "../../../website/Components/Loader";
import { IoIosInformationCircleOutline } from "react-icons/io";
import CustomerDetail from "./CustomerDetail";
import CustomerCartModal from "./CustomerCartModal";

export default function CustomerTable({
    tableHeader,
    data,
    itemsPerPage = 6,
    currentPage,
    setCurrentPage,
    count,
    totalPages,
    setLoader,
    loader,
    getAllCustomerCart,
    totalCustomerCart
}) {

    const [open, setOpen] = useState(false);
    const [selectedCart, setSelectedCart] = useState([]);

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };

    const table_heading = {
        fontWeight: 600,
        fontSize: 17,
        color: "#3F4254",
    };

    const table_tagline = {
        fontWeight: 500,
        color: "#A1A5B7",
        fontSize: 13,
    };
    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalCustomerCart);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const handleInfoOpen = (item) => {
        setOpen(true);
        setSelectedCart(item?.services?.selected_sub_services);
    };

    const displayAllHeader = () => {
        return (
            <div>
                <CustomerCartModal open={open} setOpen={setOpen} currentService={selectedCart} />

                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        : data?.length > 0 ? data?.map((item, i) => (
                            <div style={{ width: "100%" }} key={i}>
                                <Grid
                                    container
                                    spacing={0}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        padding: 15,
                                        alignItems: "center",
                                        borderTop: "1px dashed gainsboro",
                                    }}
                                >
                                    <Grid item xs={1}>
                                        <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>{startEntry + i}</h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                margin: 0,
                                                fontWeight: 500,
                                                fontSize: 14,
                                                color: "#3F4254", margin: 0, padding: 0
                                            }}
                                        >
                                            {item?.customer?.phone || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                                    >
                                        <h3
                                            style={{
                                                ...table_row_font,
                                                color: "#3F4254", margin: 0, padding: 0
                                            }}
                                        >
                                            {item?.customer?.full_name || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                            {item?.customer?.city || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                margin: 0,
                                                fontSize: 14,
                                                borderRadius: 4,
                                                textAlign: "center",
                                                fontWeight: 500, margin: 0, padding: 0
                                            }}
                                        >
                                            {item?.services?.selected_sub_services?.length + ' Services' || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}
                                    >
                                        <IoIosInformationCircleOutline style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => handleInfoOpen(item)} />
                                    </Grid>
                                </Grid>
                            </div>
                        ))
                            :
                            <div>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#3F4254",
                                        marginTop: '5%'
                                    }}
                                >
                                    No Customer Found...
                                </Grid>
                            </div>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalCustomerCart} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}
