import './App.css';
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { get_data } from './api';
import { useEffect } from 'react';
import { login } from './redux/slices/user-slice';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './website/Pages/Home';
import Contact from './website/Pages/Contact';
import Dashboard from './web-app/admin-dashboard/Pages/Dashboard';
import SuperAdminLogin from './web-app/admin-dashboard/Pages/SuperAdminLogin';
import SubAdminLogin from './web-app/admin-dashboard/Pages/SubAdminLogin';
import UserDashboard from './web-app/user-dashboard/Pages/UserDashboard';
import Service from './website/Pages/Service';
import Checkout from './website/Pages/Checkout';
import AllServices from './website/Pages/Services';
import About from './website/Pages/About';
import PermissionDenied from './website/Components/PermissionDenied';
import ForgetPassword from './web-app/admin-dashboard/Pages/ForgetPassword';
import ResetPassword from './web-app/admin-dashboard/Pages/ResetPassword';
import ChangeAdminEmail from './web-app/admin-dashboard/Pages/ChangeAdminEmail';
import ErrorPage from './website/Pages/ErrorPage';
import PaymentStatus from './website/Pages/PaymentStatus';
import socketIOClient from 'socket.io-client';
import ScreenLoading from './web-app/user-dashboard/Components/ScreenLoading';
import PrivacyPolicy from './website/Pages/PrivacyPolicy';
import Terms from './website/Pages/Terms';
import RefundsPolicy from './website/Pages/RefundPoilcy';
import Faq from './website/Pages/Faq';
import BlogDashboard from './blog/pages/BlogDashboard';
import PmsPrivacyPolicy from './website/Pages/PmsPrivacyPolicy';

function App() {

  const dispatch = useDispatch();
  const { isLoggedIn, user_data } = useSelector(state => state.user);
  const token = localStorage.getItem("authToken");
  const [socket, setSocket] = useState(null);

  const get_user = async () => {
    const current_data = await get_data("customer/get-user", { headers: { "Authorization": token } });
    if (current_data?.status) {
      dispatch(login(current_data?.data));
    } else {
    }
  };

  const socketURL = "https://api.nivishka.com/api"

  useEffect(() => {
    const socketInstance = socketIOClient(socketURL);

    setSocket(socketInstance);


    return () => {
      socketInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    get_user();
  }, []);


  const [screenLoading, setScreenLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setScreenLoading(false)
    }, 2000);
  }, [])


  return (
    <div className="App">
      {
        screenLoading ?
          <>
            <ScreenLoading />
          </>
          :
          <>
            <Router>
              <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<Service />} path="/service/:name" />
                <Route element={<AllServices />} path="/services" />
                <Route element={<About />} path="/about" />
                <Route element={<Checkout socket={socket} />} path="/Checkout" />


                {
                  isLoggedIn ?
                    <>
                      <Route element={<UserDashboard socket={socket} />} path="/dashboard/*" />
                    </>
                    :
                    <>
                      <Route element={<PermissionDenied
                        type='auth'
                        title={'Login to view your Dashboard'} />} path="/dashboard/*" />
                    </>
                }

                {
                  isLoggedIn && (user_data?.is_super_admin || user_data?.is_sub_admin)
                    ?
                    <>
                      <Route element={<Dashboard socket={socket} />} path="/admin/dashboard/*" />
                    </>
                    :
                    <>
                      <Route element={<PermissionDenied
                        type='auth'
                        title={'Login to view your Dashboard'} />} path="/admin/dashboard/*" />
                    </>
                }


                {
                  isLoggedIn && (user_data?.is_super_admin || user_data?.is_sub_admin)
                    ?
                    <>
                      <Route element={<BlogDashboard socket={socket} />} path="/blog/dashboard/*" />
                    </>
                    :
                    <>
                      <Route element={<PermissionDenied
                        type='auth'
                        title={'Login to view your Dashboard'} />} path="/blog/dashboard/*" />
                    </>
                }

                <Route element={<Contact />} path="/contact" />

                <Route element={<SuperAdminLogin />} path="/admin/login" />
                <Route element={<SubAdminLogin />} path="/sub-admin/login" />

                <Route element={<ForgetPassword />} path="/admin/forget-password" />
                <Route element={<ResetPassword />} path="/admin/reset-password/:token" />

                <Route element={<ChangeAdminEmail />} path="/admin/change-email" />

                <Route element={<PaymentStatus />} path="/payment-status" />

                <Route path="*" element={<ErrorPage />} />

                <Route element={<PrivacyPolicy />} path="/privacy-policy" />
                <Route element={<Terms />} path="/terms-and-conditions" />
                <Route element={<RefundsPolicy />} path="/refund-policy" />
                <Route element={<Faq />} path="/faq" />

                <Route element={<PmsPrivacyPolicy />} path="/pms-privacy-policy" />

              </Routes>
            </Router>
          </>
      }
    </div>
  );
}

export default App;