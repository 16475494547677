import { Rating, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { get_data } from '../../api';

const testimonials = [
    {
        name: "Kadin Torff",
        handle: "@darrels",
        text: "Wilson was fantastic! Easy to work with, efficient, and went above and beyond when the task ended up being much larger/longer than we anticipated.",
        service: "Delivery",
        image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with actual image path
    },
    {
        name: "Lincoln Herwitz",
        handle: "@lesslie",
        text: "Essra was a pleasure to work with. Punctual, friendly, helpful and really knows how to pack a storage unit for maximum efficiency.",
        service: "Help Moving",
        image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with actual image path
    },
    {
        name: "Jakob Saris",
        handle: "@jennywilson",
        text: "Dmitriy was great! Was fast to respond to the job, worked quickly but professionally, and had all the appropriate tools.",
        service: "Mounting",
        image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with actual image path
    },
    {
        name: "Carla Rosser",
        handle: "@kristinwatson2",
        text: "Hermann did a fantastic job installing a new AC unit in our house. His communication was excellent and he did everything efficiently.",
        service: "Home Repairs",
        image: "https://images.pexels.com/photos/2379004/pexels-photo-2379004.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with actual image path
    }
];


const Testimonials = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)

    const getAllTestimonials = async () => {
        try {
            const data = await get_data(`testimonials/get-all-testimonials?pageNumber=${1}`);
            if (data.status) {
                setLoader(false);
                setData(data?.data?.testimonials);
            }
        } catch (error) {
            setLoader(false);
        }
    }
    
    useEffect(() => {
        getAllTestimonials();
    }, [])

    return (
        <div className="testimonials-section">
            <h2 style={{ fontWeight: 600, color: '#000', fontSize: isDesktop ? 35 : 24, }}>Our customers are living life with a <span className="highlight">smile on their faces!</span></h2>
            <div className="testimonials">
                {data?.map((testimonial, index) => (
                    <div className="testimonial" key={index}>
                        {/* <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" /> */}
                        <div style={{marginBottom: '3%'}}>
                        <FaUserCircle
                            style={{
                                color: 'gainsboro',
                                height: 40,
                                width: 40,
                                textAlign: 'left'
                            }}
                        />
                       </div>
                        <div className="testimonial-content">
                            <p className="testimonial-name">{testimonial?.name}</p>
                            <p className="testimonial-text">"{testimonial?.review}"</p>
                            <Rating precision={0.5} value={testimonial?.rating} readOnly />
                            {/* <p className="testimonial-service">{testimonial.service}</p> */}
                        </div>
                    </div>
                ))}
            </div>
            <a target='_blank' href="https://www.google.com/search?q=Nivishka+Services&num=10&sca_esv=8963e2b334c09b73&sxsrf=ADLYWIJ08BVj2jjVoHOBjl5bHb2hLP65qQ%3A1729299672514&ei=2AQTZ_OQH_-94-EP99bTuAg&ved=&uact=5&oq=Nivishka+Services&gs_lp=Egxnd3Mtd2l6LXNlcnAiEU5pdmlzaGthIFNlcnZpY2VzMgcQIxiwAxgnMgcQIxiwAxgnMg0QABiABBiwAxhDGIoFMg4QLhiABBiwAxjHARivATIIEAAYgAQYsAMyCBAAGIAEGLADMgsQABiABBiwAxiiBDILEAAYgAQYsAMYogQyCxAAGIAEGLADGKIEMgsQABiABBiwAxiiBEi-AlAAWABwAXgAkAEAmAHgAaAB4AGqAQMyLTG4AQPIAQCYAgGgAgaYAwCIBgGQBgqSBwExoAfNCg&sclient=gws-wiz-serp"
             className="reviews-link">Check all Reviews</a>
        </div>
    );
};

export default Testimonials;