import { Button, Grid } from "@mui/material";
import CategoryTable from "../components/CategoryTable";
import { IoMdAdd } from "react-icons/io";
import { useEffect, useState } from "react";
import { get_data } from "../../api";
import { createButtonBg } from "../../globalConstants";
import DashboardHeading from "../../web-app/admin-dashboard/Components/DashboardHeading";

export default function Category() {

    const [addCategory, setAddCategory] = useState(false)
    const [loader, setLoader] = useState(true);
    const [categoryData, setCategoryData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalCategory, setTotalCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);


    const category_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const getAllCategory = async () => {
        try {
            const data = await get_data(`category/get-all-category?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setCategoryData(data?.data?.category);
                setTotalPages(data?.data?.totalPages);
                setTotalCategory(data?.data?.totalCategory);
            }
        } catch (error) {
            setLoader(false);
        }
    }
    useEffect(() => {
        getAllCategory()
    }, [currentPage])

    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Catgory", grid: 9 },
        { title: "Action", grid: 2 },
    ];

    const count = categoryData?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...category_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Category' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setAddCategory(true)}>
                        Create
                    </Button>
                </div>

                <div style={page_container}>
                    <CategoryTable
                        addCategory={addCategory}
                        setAddCategory={setAddCategory}
                        tableHeader={tableHeader}
                        data={categoryData}
                        totalCategory={totalCategory}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        loader={loader}
                        setLoader={setLoader}
                        getAllCategory={getAllCategory}
                    />
                </div>
            </Grid>
        </>
    )
}