import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatPrice, formatDate, globalConstant } from "../../../globalConstants";
import { get_data } from "../../../api";
import Loader from "../../../website/Components/Loader";

const SalesInvoicesModal = ({ open, setOpen }) => {

    const contentRef = useRef(null);
    const { primaryColor } = globalConstant()
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };


    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const pdf = new jsPDF("p", "mm", "a4");
            const pageWidth = 210; // A4 page width in mm
            const pageHeight = 295; // A4 page height in mm
            const margin = 10;
            const tableStartY = 20;
            const rowHeight = 10; // Estimate row height
            const rowsPerPage = Math.floor((pageHeight - tableStartY - margin) / rowHeight);

            // Clone the data to paginate through it
            const dataClone = [...data];
            let positionY = tableStartY;
            let pageNumber = 1;

            // Render each page
            while (dataClone.length > 0) {
                const currentPageData = dataClone.splice(0, rowsPerPage);

                // Add table headers
                pdf.setFontSize(12);
                pdf.text("Invoice no.", margin, positionY);
                pdf.text("Invoice To", margin + 30, positionY);
                pdf.text("Item Description", margin + 70, positionY);
                pdf.text("Date", margin + 110, positionY);
                pdf.text("Price", margin + 140, positionY);
                pdf.text("GST", margin + 160, positionY);
                pdf.text("Total", margin + 180, positionY);

                positionY += rowHeight;

                // Add rows for the current page
                currentPageData.forEach((item, i) => {
                    pdf.setFontSize(10);
                    pdf.text(item?.invoice_number, margin, positionY);
                    pdf.text(item?.billing_info?.full_name || "", margin + 30, positionY);
                    pdf.text(item?.service_id?.map((s) => s.service_name).join(", ") || "", margin + 70, positionY);
                    pdf.text(formatDate(item?.created_at) || "", margin + 110, positionY);
                    pdf.text(getAmountBeforeGst(item?.total_amount, item?.gst) || "", margin + 140, positionY);
                    pdf.text(item?.gst + '%', margin + 160, positionY);
                    pdf.text(formatPrice(item?.total_amount) || "", margin + 180, positionY);

                    positionY += rowHeight;
                });

                // Add a new page if there are more records to render
                if (dataClone.length > 0) {
                    pdf.addPage();
                    positionY = tableStartY;
                    pageNumber++;
                }
            }

            pdf.save("invoice.pdf");
        }
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        height: '85%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: 'auto'
    }

    const getAllSales = async () => {
        const data = await get_data('booking/get-last-month-sales');
        if (data?.status) {
            setLoader(false);
            setData(data?.data);
        }
        else {
            setLoader(false);
        }
    }

    useEffect(() => {
        getAllSales();
    }, []);

    function calculatePercentageIncrease(amount_before_gst, total_amount) {
        const difference = total_amount - amount_before_gst;
        const percentageIncrease = (difference / amount_before_gst) * 100;
        const roundedPercentageIncrease = Math.ceil(percentageIncrease);
        return roundedPercentageIncrease;
    }


    const getAmountBeforeGst = (total_amount, gst) => {
        return formatPrice(Math.ceil(parseInt(total_amount) / (1 + parseInt(gst) / 100)));
    }

    console.log('datadatadata', data)
    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    <Button variant="contained" onClick={() => { handleDownloadPdf() }} style={button}>Download</Button>
                    <div ref={contentRef} id="invoice" style={styles.invoice}>
                        <div style={styles.header}>
                            <div style={styles.brand}>
                                <h2 style={{ fontWeight: 600, margin: 0, }}>Nivishka Services</h2>
                                <p style={{ fontSize: 11, opacity: "70%" }}>
                                    Services with a Lifestyle
                                </p>
                            </div>
                            <h2 style={{ fontWeight: 600, margin: 0 }}>SALES INVOICE</h2>
                        </div>

                        <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                            <thead style={{ textAlign: "left" }}>
                                <tr>
                                    <th>Invoice no.</th>
                                    <th>Invoice To</th>
                                    <th>Item Description</th>
                                    <th>Date</th>
                                    <th>Price</th>
                                    <th>GST</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loader ?
                                        <>
                                            <Loader />
                                        </>
                                        :
                                        <>
                                            {
                                                data?.map((item, i) => {
                                                    return (
                                                        <>
                                                            <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                                <td style={{ padding: '1% 0' }}>{item?.invoice_number}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.billing_info?.full_name}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.service_id?.map((item) => item?.service_name)?.join(', ')}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.created_at)}</td>
                                                                <td style={{ padding: '1% 0' }}>{getAmountBeforeGst(item?.total_amount, item?.gst)}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.gst}%</td>
                                                                <td style={{ padding: '1% 0' }}>{formatPrice(item?.total_amount)}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                }
                            </tbody>
                        </table>
                        <div style={{ ...styles.footer, ...styles.text }}>
                            <div style={styles.terms}>
                                <p>All Invoices are of last 30 days of period.</p>
                                <p>For Nivishka Services</p>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default SalesInvoicesModal;
