import { Grid, Modal, TextField, Box, Button, FormControl, OutlinedInput, InputLabel, Chip, MenuItem, Select, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import Swal from "sweetalert2";

const AddPermissionModal = ({ open, setOpen, getAllPermissions, selectedPermission, isUpdate }) => {
    console.log('selected  selectedPermission', selectedPermission)

    const [permissionName, setPermissionName] = useState('');
    const [errors, setErrors] = useState('');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (selectedPermission) {
            setPermissionName(selectedPermission?.permission_name || '');
        }
    }, [open]);
    const handleClose = () => {
        setPermissionName('');
        setOpen(false);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
    };


    const validate = () => {
        let hasError = false;

        if (!permissionName) {
            hasError = true;
            setErrors('Permission Name is required');
        }
        return hasError;
    };

    function convertStringToSnakeCase(str) {
        const lowercased = str.toLowerCase();
        const snakeCase = lowercased.replace(/\s+/g, '_');
        return snakeCase;
    }


    const handleAddPermission = async () => {
        const hasError = validate();
        if (!hasError) {
            setDisabled(true);
            const data = await post_data('sub-admin-permission/create-sub-admin-permission', { permission_name: permissionName, permission_key: convertStringToSnakeCase(permissionName) });

            if (data?.status) {
                handleClose();
                getAllPermissions();
                Toast.fire({
                    title: "Permission created successfully",
                    icon: 'success',
                    color: 'green'
                });
            } else {
                Toast.fire({
                    title: "Something went wrong",
                    icon: 'warning',
                    color: 'red'
                });
            }
            setDisabled(false);
        }
    };

    const handleUpdatePermission = async () => {
        const hasError = validate();
        if (!hasError) {
            const data = await post_data(`sub-admin-permission/update-sub-admin-permission/${selectedPermission?._id}`, { permission_name: permissionName });

            if (data?.status) {
                handleClose();
                getAllPermissions();
                Toast.fire({
                    title: "Permission updated successfully",
                    icon: 'success',
                    color: 'green'
                });
            } else {
                Toast.fire({
                    title: "Something went wrong",
                    icon: 'warning',
                    color: 'red'
                });
            }
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Permission Name"
                            name="permissionName"
                            variant="outlined"
                            value={permissionName}
                            error={!!errors}
                            fullWidth
                            helperText={errors}
                            onChange={(e) => {
                                setPermissionName(e?.target?.value);
                                setErrors('');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={disabled}
                            sx={{
                                boxShadow: 'none', padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761', boxShadow: 'none' }
                            }}
                            onClick={isUpdate ? handleUpdatePermission : handleAddPermission}
                        >
                            {isUpdate ? "Update Permission" : "Add Permission"}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default AddPermissionModal;