export default function Empty() {
    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <img src='/images/empty.svg' style={{ width: 350, height: 350 }} />
                <h3 style={{ fontWeight: 500, fontSize: 25 }}>Nothing to show!</h3>
            </div>
        </>
    )
}