import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import BackButton from "../Components/BackButton";
import { get_data, post_data } from "../../../api";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../globalConstants";
import SearchComponent from "../Components/SearchComponent";
import CouponTable from "../Components/CouponTable";

export default function Coupon({ type }) {

    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [couponData, setCouponData] = useState([]);
    const [value, setValue] = useState('')
    const [addCoupon, setAddCoupon] = useState(false)
    const [totalPages, setTotalPages] = useState(null);
    const [totalCoupons, setTotalCoupons] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchState, setSearchState] = useState(false)

    const handleSearch = () => {
        setLoader(true)
        setTimeout(() => {
            if (value !== '') {
                searchCoupons()
                setSearchState(true)
            }
            else {
                getAllCoupons()
                setSearchState(false)
            }
        }, 500);
    }

    const searchCoupons = async () => {
        try {
            const data = await post_data(`coupon/search-coupons/${value}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setCouponData(data?.data?.coupons);
                setTotalPages(data?.data?.totalPages);
                setTotalCoupons(data?.data?.totalCoupons);
            }
            else {
                setLoader(false);
                setCouponData([])
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching all Coupons", error);
        }
    }

    const coupon_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };


    const getAllCoupons = async () => {
        try {
            const data = await get_data(`coupon/get-all-coupon?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setCouponData(data?.data?.coupon);
                setTotalPages(data?.data?.totalPages);
                setTotalCoupons(data?.data?.totalCoupons);
            }
        } catch (error) {
            setLoader(false);
            console.log("Error while fetching Coupons", error);
        }
    }
    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchCoupons()
            }
            else {
                getAllCoupons();
            }
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Coupon", grid: 7 },
        { title: "Discount", grid: 2 },
        { title: "Action", grid: 2 },
    ];

    const count = couponData?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...coupon_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Coupons' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setAddCoupon(true)}>
                        Create
                    </Button>
                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>

                <div style={page_container}>
                    <CouponTable
                        addCoupon={addCoupon}
                        setAddCoupon={setAddCoupon}
                        tableHeader={tableHeader}
                        type="permissions"
                        data={couponData}
                        totalCoupons={totalCoupons}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        loader={loader}
                        setLoader={setLoader}
                        getAllCoupons={getAllCoupons}
                    />
                </div>
            </Grid>
        </>
    )
}