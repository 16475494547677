import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Chip } from '@mui/material';
import { get_data } from '../../../api';

const RefundPayment = ({ open, setOpen, currentCustomer, getAllCustomer }) => {

    const [isMember, setIsMember] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const checkIsMemberShip = async () => {
        const data = await get_data(`membership-purchase/membership-purchased-by-customer/${currentCustomer._id}`)
        if (data.status) {
            setIsMember(true);
        }
    }

    useEffect(() => {
        checkIsMemberShip();
    }, [])

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 8,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        }
    };


    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }


    const DataRow = ({ label, value }) => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2% 0',
        }}>
            {label === "Address" ?
                <>
                    <Typography variant="body1" style={{ flex: 2, textAlign: 'left' }}>{label}:</Typography>
                    <Typography variant="body1" style={{ textTransform: 'capitalize', textAlign: 'right', flex: 2 }}>
                        {[
                            value?.[0]?.street || '-',
                            value?.[0]?.zip_code || '-',
                            value?.[0]?.city || '-',
                            value?.[0]?.state || '-'
                        ].filter(part => part !== '-').join(', ') || '-'}
                    </Typography>

                </>
                :
                label === "Email Verified" || label === "Membership"
                    ?
                    <>
                        <Typography variant="body1" style={{ flex: 2, textAlign: 'left' }}>{label}:</Typography>
                        <Typography variant="body1" style={{ flex: 1, textAlign: 'right' }}>
                            {
                                value === true ?
                                    <img src='/images/check-icon.svg' alt="Check Icon" style={{ width: 20, height: 20 }} />
                                    :
                                    <>-</>
                            }
                        </Typography>

                    </>
                    :
                    <>
                        <Typography variant="body1">{label}:</Typography>
                        <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{value}</Typography>
                    </>
            }
        </Box>
    )


    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={heading}>
                    Customer Details
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, padding: '2% 6% 6% 6%' }}>
                    <DataRow label="Address" value={currentCustomer?.address || '-'} />
                    <DataRow label="Unique User Name" value={currentCustomer?.user_name || '-'} />
                    <DataRow label="Email Verified" value={currentCustomer?.is_email_verified || '-'} />
                    <DataRow label="Membership" value={isMember || '-'} />
                </Typography>
            </Box>
        </Modal>
    );
};

export default RefundPayment;
