import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, Button } from '@mui/material';
import SubServicesSlider from '../../../website/Components/SubServicesSlider';
import OnsitePaymentModal from '../Components/OnsitePaymentModal';
import { get_data } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import Empty from '../Components/Empty';

const OnSiteBooking = ({ user_data, socket }) => {

    const [refresh, setRefresh] = useState(false);
    const isTablet = useMediaQuery('(max-width: 960px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const [open, setOpen] = useState(false);
    const [couponDisabled, setCouponDisabled] = useState();
    const [subServiceVendorNotFound, setSubServiceVendorNotFound] = useState([]);
    const [isMember, setIsMember] = useState(false);
    const [memberShipSaved, setMemberSaved] = useState(0);
    const [total, setTotal] = useState(105.00);
    const [subTotal, setSubTotal] = useState(0);
    const [saved, setSaved] = useState(0.00);
    const [allSubServices, setAllSubServices] = useState([]);
    const [subServiceIDs, setSubServiceIDs] = useState([]);
    const [serviceIDs, setServiceIDs] = useState([]);
    const [gst, setGst] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [appliedCoupon, setAppliedCoupon] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [isOnsiteBooking, setIsOnsiteBooking] = useState(false);

    const styles = {
        container: {
            padding: isMobile || isTablet ? '20px' : '3% 7%',
            position: 'relative',
        },
        heading: {
            fontSize: isMobile || isTablet ? '24px' : 40,
            fontWeight: '600',
            marginBottom: '20px',
            width: isMobile || isTablet ? '100%' : '60%'
        },
        subheading: {
            fontSize: '18px',
            color: 'gray',
            marginBottom: '20px',
        },
        faqItem: {
            marginBottom: '10px',
            boxShadow: 'none',
            background: 'transparent'
        },
    };

    const calculateTotal = () => {
        const subtotal = parseInt(localStorage.getItem('selected_service_amount'));
        setTotal(subtotal);
        setSubTotal(subtotal);

        let lastAmount = subtotal;
        if (user_data?.is_membership) {
            const discount = Math.ceil((subtotal * 10) / 100);
            setIsMember(true);
            setTotal(subtotal - discount);
            lastAmount = subtotal - discount;
            setMemberSaved(discount);
            setTotalDiscount(10);
        }

        if (appliedCoupon) {
            const discount = Math.ceil((subtotal * couponDiscount) / 100);
            if (user_data?.is_membership) {
                setTotalDiscount(10 + couponDiscount);
            }
            else {
                setTotalDiscount(couponDiscount);
            }
            setTotal(lastAmount - discount);
            lastAmount = lastAmount - discount;
            setSaved(discount);
        }

        if (user_data?.gst) {
            const gstPrice = Math.ceil((lastAmount * 18) / 100);
            setGst(gstPrice);
            setTotal(lastAmount + gstPrice);
        } else {
            const gstPrice = Math.ceil((lastAmount * 5) / 100);
            setGst(gstPrice);
            setTotal(lastAmount + gstPrice);
        }

    };

    const fetchSelectedServices = async () => {
        // const response = await get_data(`selected-service/get-selected-services-by-customer/${user_data?._id}`);
        // if (response?.status) {
        // setAllSubServices(response?.data);
        // }
    };

    const fetchSubServiceID = () => {
        // const subserviceid = allSubServices.map((item) => {
        //     return ({ id: item?.selected_sub_service_id?._id, amount: item?.selected_sub_service_id?.discounted_price })
        // })

        const allselectedservice = JSON.parse(localStorage.getItem("all_selected_service"))

        const subserviceid = allselectedservice?.map((item) => {
            return ({ id: item?.sub_service_id, amount: item?.amount })
        })
        setSubServiceIDs(subserviceid);
    }


    const fetchServiceID = () => {
        // const serviceid = [...new Set(allSubServices.map((item) => {
        //     return item?.selected_sub_service_id?.parent_service_id?._id
        // }))]
        const selectedserviceid = JSON.parse(localStorage.getItem("service_id"))
        const serviceid = [...new Set(selectedserviceid)]

        setServiceIDs(serviceid);
    }


    useEffect(() => {
        calculateTotal();
        fetchServiceID();
        fetchSubServiceID()
    }, [allSubServices, open, appliedCoupon]);

    useEffect(() => {
        fetchSelectedServices();
    }, [user_data, refresh]);

    return (
        <Box sx={styles.container}>
            <Typography variant="overline" display="block" gutterBottom>Book services on site</Typography>
            <Typography sx={styles.heading}>On-Site Booking</Typography>
            <SubServicesSlider setIsOnsiteBooking={setIsOnsiteBooking} refresh={refresh} setRefresh={setRefresh} setOpen={setOpen} screen={'onsite'} />
            <Typography variant='body' style={{ display: 'flex', justifyContent: "center" }}>
                {isOnsiteBooking &&
                    <Button
                        variant="contained"
                        sx={{
                            boxShadow: 'none', padding: isTablet || isMobile ? '2.5% 5%' : '1% 5%', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                        }}
                        onClick={() => setOpen(true)}
                    >
                        Checkout Booking
                    </Button>
                }
                {open &&
                    <OnsitePaymentModal
                        isMember={isMember}
                        memberShipSaved={memberShipSaved}
                        total={total}
                        saved={saved}
                        open={open}
                        setOpen={setOpen}
                        setTotal={setTotal}
                        setSaved={setSaved}
                        user_data={user_data}
                        subServiceIDs={subServiceIDs}
                        serviceIDs={serviceIDs}
                        subTotal={subTotal}
                        socket={socket}
                        appliedCoupon={appliedCoupon}
                        totalDiscount={totalDiscount}
                        gst={gst}
                        couponDiscount={couponDiscount}
                        setAppliedCoupon={setAppliedCoupon}
                        setCouponDiscount={setCouponDiscount}
                    />}
            </Typography>
        </Box>
    );
};

export default OnSiteBooking;