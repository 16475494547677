import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { CircularProgress, useMediaQuery } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Heading from './Heading';
import { globalConstant } from '../../globalConstants';
import { endPointURL, get_data } from '../../api';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import ServicesSkeletonV2 from './Skeleton/ServicesSkeletonV2';

const Services = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { primaryColor } = globalConstant();
    const [loader, setLoader] = useState(true);
    const [allServices, setAllServices] = useState([]);
    const navigate = useNavigate()

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: '' }}
                onClick={onClick}
            >
                <IoIosArrowForward style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <IoIosArrowBack style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 150,
        autoplay: false,
        slidesToShow: isDesktop ? 3.7 : 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const getAllServices = async () => {
        try {
            const data = await get_data("services/get-all-services");
            if (data.status) {
                setLoader(false);
                setAllServices(data?.data?.services);
            }
        } catch (error) {
            setLoader(false);
            console.log("Error while fetching Services", error);
        }
    }

    useEffect(() => {
        getAllServices();
    }, []);

    const styles = {
        container: {
            padding: isDesktop ? '3% 10%' : '20px',
            textAlign: 'center',
            overflow: 'hidden'
        },
        subheading: {
            fontSize: '16px',
            marginBottom: isDesktop ? '6%' : '20px'
        },
        serviceTitle: {
            marginTop: '10px',
            fontWeight: '600',
        },
        serviceCard: {
            padding: '10px',
            cursor: 'pointer'
        },
        serviceImage: {
            width: '100%',
            height: 250,
            margin: '0 auto',
            borderRadius: '10px'
        },
        serviceHeading: {
            color: primaryColor,
            textAlign: isDesktop ? 'left' : 'center',
            fontWeight: 600,
            fontSize: 19,
            marginLeft: '2%'
        }
    };

    const handleServiceClick = (service) => {
        const formattedServiceName = service?.service_name?.toLowerCase().replace(/ /g, '-');
        navigate(`/service/${formattedServiceName}`, { state: { service: service } });
        window.scrollTo(0, 0);
    };


    function showSlider(services) {
        return services.map((service, index) => {
            return (
                <div>
                    <div key={index} style={styles.serviceCard} onClick={() => handleServiceClick(service)}>
                        <img src={`${endPointURL}/uploads/service-picture/${service?.service_image}`} alt={service.title} style={styles.serviceImage} />
                        <p style={styles.serviceTitle}>{service?.service_name}</p>
                    </div>
                </div>
            );
        });
    }

    return (
        <div style={styles.container}>
            <Heading title={'Services We Provide'} color={primaryColor} />
            <p style={styles.subheading}>Choose from the vast list of services provided by us</p>
            {
                loader ?
                    <div>
                        <ServicesSkeletonV2 />
                    </div>
                    :
                    <div>
                        <div style={{ margin: '3% 0', padding: '0 2%' }}>
                            <Slider {...settings}>
                                {showSlider(allServices?.slice(0, Math.ceil(allServices?.length / 3)))}
                            </Slider>
                        </div>
                        <div style={{ margin: '3% 0', padding: '0 2%' }}>
                            <Slider {...settings}>
                                {showSlider(allServices?.slice(Math.ceil(allServices?.length / 3), Math.ceil(allServices?.length / 3) * 2))}
                            </Slider>
                        </div>
                        <div style={{ margin: '3% 0', padding: '0 2%' }}>
                            <Slider {...settings}>
                                {showSlider(allServices?.slice(Math.ceil(allServices?.length / 3) * 2))}
                            </Slider>
                        </div>
                    </div>}

        </div>
    );
};

export default Services;