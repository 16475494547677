import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { get_data } from "../../api";
import { createButtonBg } from "../../globalConstants";
import ClientsTable from "../components/ClientsTable";
import DashboardHeading from "../../web-app/admin-dashboard/Components/DashboardHeading"

export default function Clients() {

    const [loading, setLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalClients, setTotalClients] = useState(null);

    const permissions_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };


    const getAllClients = async () => {
        try {
            const data = await get_data(`clients/get-all-clients-with-pagination?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setData(data?.data?.clients);
                setTotalPages(data?.data?.totalPages);
                setTotalClients(data?.data?.totalClients);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Newsletters", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllClients(currentPage);
        }
    }, [currentPage])


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Name", grid: 9 },
        { title: "Actions", grid: 2 },
    ];

    const count = data.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...permissions_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Clients' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button startIcon={<IoMdAdd style={{ color: 'white' }} />} variant="contained" style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none' }}
                        onClick={() => setOpen(true)}
                    >
                        Create
                    </Button>
                </div>

                <div style={page_container}>
                    <ClientsTable
                        tableHeader={tableHeader}
                        data={data}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalPages={totalPages}
                        loader={loader}
                        totalClients={totalClients}
                        addClients={open}
                        setLoader={setLoader}
                        setAddClients={setOpen}
                        getAllClients={getAllClients}
                    />
                </div>
            </Grid>
        </>
    )
}