import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    Typography,
    Select,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Alert,
} from "@mui/material";
import { saveAs } from "file-saver"; // Ensure file-saver is installed
import { post_data } from "../../../api";
import { globalConstant } from "../../../globalConstants";

const ExportModal = ({ isOpen, setOpen, type }) => {
    const { primaryColor } = globalConstant();
    const [selectedOption, setSelectedOption] = useState(null); // Default value should be numeric
    const [data, setData] = useState([]);
    const [noDataAlert, setNoDataAlert] = useState(false);

    const onRequestClose = () => {
        setOpen(false);
    };

    const fetchData = async (timeRange) => {
        try {
            let response;
            if (type === "customers") {
                response = await post_data("customer/get-export-data", { days: timeRange });
            } else if (type === "newsletters") {
                response = await post_data("newsletter/get-export-data", { days: timeRange });
            } else if (type === "membershipCustomers") {
                response = await post_data("membership-purchase/get-export-data", { days: timeRange });
            } else {
                response = await post_data("vendor/get-export-data", { days: timeRange });
            }
            setData(response?.data || []);
        } catch (err) {
            // console.error(err);
        }
    };

    useEffect(() => {
        if (selectedOption !== null) {
            fetchData(selectedOption);
        }
    }, [selectedOption]); // Fetch data when selectedOption changes

    const convertToCSV = (data) => {
        if (data.length === 0) {
            setNoDataAlert(true);
            return "";
        }

        const fields = type === "newsletters" ?
            ["email", "created_at"]
            :
            type === "customers" ?
                ["full_name", "phone", "email", "address.city", "created_at"]
                :
                type === "membershipCustomers" ?
                    ["customer_id.full_name", "customer_id.phone", "created_at", "end_date"]
                    :
                    ["full_name", "phone", "created_at"];

        const header = fields.join(",");

        const getFieldValue = (row, field) => {
            return field.split(".").reduce((acc, part) => acc && acc[part], row);
        };

        const rows = data
            .map((row) => fields.map((field) => getFieldValue(row, field)).join(","))
            .join("\n");

        return `${header}\n${rows}`;
    };

    const handleExport = () => {
        const csvData = convertToCSV(data);
        if (csvData) {
            const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "exported_data.csv");
            onRequestClose();
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 350,
        bgcolor: "background.paper",
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
    };

    const placeOrderButton = {
        padding: "13px 20px",
        fontSize: "16px",
        color: "#fff",
        backgroundColor: primaryColor,
        border: "none",
        borderRadius: "5px",
        boxShadow: "none",
        cursor: "pointer",
        width: "100%",
    };

    return (
        <Modal open={isOpen} onClose={onRequestClose} aria-labelledby="export-modal-title">
            <Box sx={style}>
                <Typography id="export-modal-title" variant="h6" component="h2">
                    Export Data
                </Typography>
                <FormControl fullWidth style={{ marginTop: "5%" }}>
                    <InputLabel id="demo-simple-select-label">Select Date Range</InputLabel>
                    <Select
                        value={selectedOption}
                        onChange={(e) => {
                            setSelectedOption(Number(e.target.value));
                            setNoDataAlert(false); // Reset the alert when a new option is selected
                        }}
                        fullWidth
                        label="Select Date Range"
                    >
                        <MenuItem value={1}>Today</MenuItem>
                        <MenuItem value={0}>All Time</MenuItem>
                        <MenuItem value={7}>Last 7 days</MenuItem>
                        <MenuItem value={30}>Last 30 days</MenuItem>
                        <MenuItem value={45}>Last 45 days</MenuItem>
                        <MenuItem value={60}>Last 60 days</MenuItem>
                        <MenuItem value={90}>Last 90 days</MenuItem>
                    </Select>
                </FormControl>

                {noDataAlert && (
                    <Alert severity="warning" style={{ marginTop: "10px" }}>
                        No data available for the selected range.
                    </Alert>
                )}

                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                    <Button variant="contained" onClick={handleExport} style={placeOrderButton}>
                        Download CSV
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ExportModal;