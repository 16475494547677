import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useMediaQuery } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Heading from './Heading';
import { globalConstant } from '../../globalConstants';
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const ServicesBanners = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { primaryColor } = globalConstant();
    const navigate = useNavigate()

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: '' }}
                onClick={onClick}
            >
                <IoIosArrowForward style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <IoIosArrowBack style={{ width: '30px', height: '30px', color: "gray" }} />
            </div>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 150,
        autoplay: false,
        slidesToShow: isDesktop ? 3 : 1,
        slidesToScroll: 2,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />
    };

    const styles = {
        container: {
            padding: isDesktop ? '3% 5%' : '20px',
            textAlign: 'center',
            overflow: 'hidden'
        },
        subheading: {
            fontSize: '16px',
            marginBottom: isDesktop ? '3%' : '20px'
        },
        serviceTitle: {
            marginTop: '10px',
            fontWeight: '600',
        },
        serviceCard: {
            padding: '10px',
            cursor: 'pointer'
        },
        serviceImage: {
            width: '100%',
            height: isDesktop ? 270 : '100%',
            margin: '0 auto',
            borderRadius: '10px'
        },
        serviceHeading: {
            color: primaryColor,
            textAlign: isDesktop ? 'left' : 'center',
            fontWeight: 600,
            fontSize: 19,
            marginLeft: '2%'
        }
    };

    const services = [
        {
            service_name: 'Painting',
            img: '/images/painting-banner.png',
            link: '/service/painting',
        },
        {
            service_name: 'Deep Clean',
            img: '/images/deep-banner.png',
            link: '/service/deep',
        },
        {
            service_name: 'Bathroom Cleaning',
            img: '/images/bathroom-banner.png',
            link: '/service/bathroom',
        },
        {
            service_name: 'Laundry',
            img: '/images/laundry-banner.png',
            link: '/service/laundry',
        },
    ]

    const handleClick = (service) => {
        navigate(`/services`);
        window.scrollTo(0, 0);
    }


    function showSlider() {
        return services?.map((service, index) => {
            return (
                <div>
                    <div key={index} style={styles.serviceCard} onClick={() => handleClick(service)}>
                        <img src={service?.img} style={styles.serviceImage} />
                    </div>
                </div>
            );
        });
    }

    return (
        <div style={styles.container}>
            <Heading title={'Hand Picked Services'} color={primaryColor} />
            <p style={styles.subheading}>Best quality services you can get at best price.</p>
            <div style={{ margin: '1% 0 3%' }}>
                <Slider {...settings}>
                    {showSlider()}
                </Slider>
                <Slider {...settings}>
                    {showSlider()}
                </Slider>
            </div>
        </div>
    );
};

export default ServicesBanners;