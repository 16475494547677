import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { post_data } from '../../api';

const Newsletter = () => {
    
    const [email, setEmail] = useState('');
    const [disabled,setDisabled] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const handleSubscribe = async () => {
        if (email.length === 0) {
            Toast.fire({
                icon: "error",
                text: "Email is required!",
                color: "red",
            })
        }
        else {
            setDisabled(true);

            const data = await post_data("newsletter/create-newsletter", { email })

            if (data?.status) {
                setEmail('');
                Toast.fire({
                    icon: "success",
                    text: "Thanks for subscribing us!",
                    color: "Green"
                })
            } else {
                Toast.fire({
                    icon: "error",
                    text: "Something Went Wrong",
                    color: "red"
                })
            }
            setDisabled(false);
        }
    }

    return (
        <div className="newsletter-container">
            <h2 className="newsletter-heading">Subscribe to our Newsletter</h2>
            <div className="newsletter-input-container">
                <input
                    type="email"
                    className="newsletter-input"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />
                <button className="newsletter-button" onClick={handleSubscribe}>{disabled ? "Subscribe..." : "Subscribe"}</button>
            </div>
            <p style={{ opacity: '70%' }}>Subscribe to us and be the first fo receive offers and promotional stuffs.</p>
        </div>
    );
};

export default Newsletter;
