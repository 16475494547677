import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';

const RefundPayment = ({ refundOpen, setRefundOpen, currentRefund, type }) => {
    const handleClose = () => {
        setRefundOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        }
    };


    const heading = {
        fontWeight: 600,
        fontSize: 22,
        marginBottom: '10px',
        textAlign: 'left',
        p: 3,
        borderBottom: '1px solid gainsboro'
    }

    const DataRow = ({ label, value }) => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2% 0',
        }}>
            <Typography variant="body1">{label}:</Typography>
            <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{value}</Typography>
        </Box>
    )


    return (
        <Modal
            open={refundOpen}
            onClose={handleClose}
            sx={{
                border: 'none',
                "& .MuiBackdrop-root": {
                }
            }}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={heading}>
                    Refund Details
                </Typography>
                {type === "bank" ?
                    <Typography id="modal-modal-description" sx={{ mt: 2, padding: '2% 6% 6% 6%' }}>
                        <DataRow label="Bank Name" value={currentRefund?.bank_details?.bank_name || '-'} />
                        <DataRow label="Account Number" value={currentRefund?.bank_details?.account_number || '-'} />
                        <DataRow label="IFSC" value={currentRefund?.bank_details?.ifsc || '-'} />
                        <DataRow label="Account Holder Name" value={currentRefund?.bank_details?.account_holder_name || '-'} />
                        <DataRow label="Branch Name" value={currentRefund?.bank_details?.branch_name || '-'} />
                        <DataRow label="UPI Handle" value={currentRefund?.upi || '-'} />
                    </Typography>
                    :
                    <Typography id="modal-modal-description" sx={{ mt: 2, padding: '2% 6% 6% 6%' }}>
                        <DataRow label="Cancel Reason" value={currentRefund?.reason || '-'} />
                        <DataRow label="Cancel Type" value={currentRefund?.cancel_type || '-'} />
                    </Typography>
                }
            </Box>
        </Modal>
    );
};

export default RefundPayment;
