import React from 'react';
import { useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { globalConstant } from '../../globalConstants';
import { endPointURL } from '../../api';
import { useNavigate } from 'react-router-dom';

const OurAllServices = ({ serviceData }) => {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { primaryColor } = globalConstant()
    const navigate = useNavigate()

    const styles = {
        container: {
            padding: isDesktop ? '3% 10%' : '20px',
            textAlign: 'center',
            position: 'relative'
        },
        heading: {
            color: '#41A317',
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        subheading: {
            fontSize: 18,
            marginBottom: '20px',
            color: '#555',
        },
        grid: {
            display: 'grid',
            gap: '20px',
            marginTop: isDesktop ? '5%' : '',
            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : isTablet ? 'repeat(2, 1fr)' : '1fr',
        },
        card: {
            borderRadius: '10px',
            overflow: 'hidden',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            background: '#fff',
            margin: '3% 0',
            cursor: 'pointer',
            height: 450
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        cardContent: {
            padding: isDesktop ? '5%' : '15px',
            textAlign: 'left',
            background: 'linear-gradient(180deg, #163F1800 , #163F18 90%',
            color: '#fff',
            position: 'absolute',
            bottom: 0,
            width: '100%',
        },
        title: {
            fontSize: 22,
            fontWeight: '600',
        },
        description: {
            fontSize: 16,
            width: isDesktop ? '80%' : '',
            marginTop: '5px',
        }
    };

    const handleServiceClick = (service) => {
        const formattedServiceName = service?.service_name?.toLowerCase().replace(/ /g, '-');
        navigate(`/service/${formattedServiceName}`, { state: { service: service } });
        window.scrollTo(0, 0);
    };

    return (
        <div style={styles.container}>
            <img src='/images/blur-div-left.svg' style={{ position: 'absolute', left: 0, top: 0 }} />
            <div style={styles.grid}>
                {serviceData?.map((service, index) => (
                    <div key={index} style={styles.card} onClick={() => handleServiceClick(service)}>
                        <img src={`${endPointURL}/uploads/service-picture/${service?.service_image}`} alt={service.title} style={styles.image} />
                        <div style={styles.cardContent}>
                            <h3 style={styles.title}>{service?.service_name}</h3>
                            <p style={styles.description}>{service?.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OurAllServices;
