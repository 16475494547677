import React from 'react';
import { useMediaQuery } from '@mui/material';
import { globalConstant } from '../../globalConstants';
import Heading from './Heading';

const WhyChooseUs = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { primaryColor } = globalConstant()

    const styles = {
        container: {
            textAlign: 'center',
            padding: isDesktop ? '4% 15%' : '40px 20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            color: primaryColor,
            marginBottom: '10px',
        },
        description: {
            fontSize: 18,
            color: '#555',
            marginBottom: '40px',
        },
        cardsContainer: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            gap: '20px',
        },
        card: {
            flex: 1,
            backgroundColor: 'white',
            padding: '20px',
            height: isDesktop ? 300 : 'auto',
            borderRadius: '10px',
            transition: 'background 0.3s ease',
            cursor: 'pointer',
            flexDirection: 'column',
            display: 'flex',
            justifyContents: 'start',
            alignItems: 'start',
            maxWidth: isDesktop ? '300px' : '400px',
            width: isDesktop ? '100%' : '80%',
            textAlign: 'left',
            border: '2px solid #5DB761',
        },
        cardHover: {
            background: 'linear-gradient(135deg, #00C853 0%, #B2FF59 100%)',
            color: 'white',
        },
        icon: {
            fontSize: '40px',
        },
        cardTitle: {
            fontSize: 20,
            fontWeight: '600',
            marginBottom: 5,
        },
        cardDescription: {
            fontSize: 16,
            color: '#777',
        },
    };

    return (
        <div style={styles.container}>
            <Heading title={'Why Choose Nivishka Services?'} color={primaryColor} />
            <p style={styles.description}>
                Discover why Nivishka Services is the preferred choice for homeowners seeking top-quality maintenance and furnishing solutions. Our commitment to excellence ensures that you receive nothing less than the best, delivered with professionalism and care.
            </p>
            <div style={styles.cardsContainer}>
                <div style={styles.card} className="hover-card">
                    <div style={styles.icon}>
                        <img src='/images/best-quality.png' style={{ height: 80, width: 80 }} />
                    </div>
                    <h3 style={styles.cardTitle}>Best Quality</h3>
                    <p style={styles.cardDescription}>
                        At Nivishka Services, we ensure that our clients receive precisely what they desire, offering a delightful experience with impeccable attention to detail. Our quality-first approach guarantees superior results that stand the test of time.
                    </p>
                </div>
                <div style={styles.card} className="hover-card">
                    <div style={styles.icon}>
                        <img src='/images/expert-advice.png' style={{ height: 80, width: 80 }} />
                    </div>
                    <h3 style={styles.cardTitle}>Expert Advice</h3>
                    <p style={styles.cardDescription}>
                        Our team of experienced professionals provides insightful advice, helping both our clients and workers achieve the best possible outcomes. We guide you through every step of the process, ensuring that your vision becomes a reality.
                    </p>
                </div>
                <div style={styles.card} className="hover-card">
                    <div style={styles.icon}>
                        <img src='/images/labour-expertise.png' style={{ height: 80, width: 80 }} />
                    </div>
                    <h3 style={styles.cardTitle}>Labour Expertise</h3>
                    <p style={styles.cardDescription}>
                        We rigorously train and quality-test our workers, selecting only those who demonstrate exceptional skill and expertise. With Nivishka Services, you can be confident that your home is in the hands of the most capable professionals.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;
