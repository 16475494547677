import { Grid } from "@mui/material";
import BannerCard from "../components/BannerCard";
import DashboardHeading from "../../web-app/admin-dashboard/Components/DashboardHeading";
import CreatePost from "../components/CreatePost";

export default function Create() {

    const create_page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...create_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Create Post' />

                <div style={page_container}>
                    <div style={{ background: 'white', padding: '2%', borderRadius: 10, height: 1050 }}>
                        <CreatePost />
                    </div>
                </div>
            </Grid>
        </>
    )
}