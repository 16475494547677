import React, { useEffect, useState } from 'react';
import { useMediaQuery, Box, Typography } from '@mui/material';
import { globalConstant } from '../../globalConstants'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Newsletter from '../Components/Newsletter';

const RefundsPolicy = () => {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { primaryColor } = globalConstant();

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: isDesktop ? '3%' : '20px 5px',
            backgroundColor: '#efffef',
        },
        leftSide: {
            flex: 1,
            padding: '20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        heading: {
            fontSize: isDesktop ? 45 : 32,
            fontWeight: 500,
            marginBottom: '20px',
            marginTop: 0,
        },
        highlight: {
            color: primaryColor,
        },
        description: {
            width: '90%',
            fontSize: '16px',
            marginBottom: '20px',
        },
        cardsContainer: {
            display: 'grid',
            padding: isDesktop ? '1% 4%' : '20px 5px',
            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : isTablet ? 'repeat(2, 1fr)' : '1fr',
            gap: '20px',
        },
        cardSection: {
            padding: isDesktop ? '3% 7%' : '40px 20px',
            position: 'relative'
        },
        image: {
            maxWidth: isDesktop ? '80%' : '100%',
            height: 'auto',
            borderRadius: '10px',
        },

        starIcon: {
            color: '#6F42C1',
            marginRight: '5px',
        },
        ratingText: {
            fontWeight: 'bold',
        },
        bookingText: {
            color: '#757575',
            marginLeft: '5px',
        },
        dottedLine: {
            borderBottom: '1px dotted #757575',
            flex: 1,
            marginLeft: '10px',
        }
    };


    return (
        <>
            <Header />

            <div style={styles.container} >
                <div style={styles.leftSide}>
                    <h2 style={styles.heading}>Refund Policy</h2>
                </div>
                <div style={styles.rightSide}>
                </div>
            </div>

            <div style={{ padding: isDesktop ? '3% 18%' : '5%', fontSize: 17 }}>
                <p>This Refund Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Refund Policy.</p>
                <br />
                <b>Refund Policy</b><br />
                <p>
                If you cancel your booking before 6 hours of scheduled slot, you can claim 100% refund and if you cancel your booking within 6 hours of scheduled slot, 500 INR deduction will be there in the total Booking amount.
                </p>
                <br />

            
                <h2>Interpretation and Definitions</h2>
                
                <h3>Interpretation</h3>
                <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                <h3>Definitions</h3>
                <p>For the purposes of this Refund Policy:</p>
                <ul>
                    <li>
                        <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                    </li>
                    <li>
                        <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                    </li>
                    <li>
                        <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Nivishka Services, No. 92 , 7th Cross, Basavanapura Main Road, Bangalore 560036.</p>
                    </li>
                    <li>
                        <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
                    </li>
                    <li>
                        <p><strong>Country</strong> refers to: Karnataka,  India</p>
                    </li>
                    <li>
                        <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                    </li>
                    <li>
                        <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                    </li>
                    <li>
                        <p><strong>Service</strong> refers to the Website.</p>
                    </li>
                    <li>
                        <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                    </li>
                    <li>
                        <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                    </li>
                    <li>
                        <p><strong>Website</strong> refers to Nivishka, accessible from <a href="https://nivishka.com" rel="external nofollow noopener" target="_blank">https://nivishka.com</a></p>
                    </li>
                    <li>
                        <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                    </li>
                </ul>

                <h2>Contact Us</h2>
                <p>If you have any questions about this Refund Policy, You can contact us:</p>
                <ul>
                    <li>
                        <p>By email: contact@nivishka.com</p>
                    </li>
                    <li>
                        <p>By visiting this page on our website: <a href="https://nivishka.com/contact" rel="external nofollow noopener" target="_blank">https://nivishka.com/contact</a></p>
                    </li>
                    <li>
                        <p>By phone number: +(91)-903-609-6000</p>
                    </li>
                </ul>
            </div>

            <Footer />
        </>
    );
};

export default RefundsPolicy;