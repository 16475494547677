import { Grid, Button } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import SubAdminTable from "../Components/SubAdminTable";
import { get_data, post_data } from "../../../api";
import SearchComponent from "../Components/SearchComponent";
import AddSubAdmin from "../Components/AddSubAdmin";
import { IoMdAdd } from "react-icons/io";
import { createButtonBg } from "../../../globalConstants";

export default function SubAdmin({ type }) {

    const [loading, setLoading] = useState("true")
    const [loader, setLoader] = useState(true);
    const [subAdminData, setSubAdminData] = useState([]);
    const [open, setOpen] = useState(false)
    const [addSubAdmin, setAddSubAdmin] = useState(false)
    const [value, setValue] = useState('')
    const [searchState, setSearchState] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [totalSubAdmins, setTotalSubAdmins] = useState(null);

    const handleSearch = () => {
        setLoader(true)
        setTimeout(() => {
            if (value !== '') {
                searchSubAdmins()
                setSearchState(true)
            }
            else {
                getAllSubAdmins()
                setSearchState(false)
            }
        }, 500);
    }

    const searchSubAdmins = async () => {
        try {
            const data = await post_data(`sub-admin/search-sub-admins/${value}?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setSubAdminData(data.data?.subAdmins);
                setTotalPages(data?.data?.totalPages);
                setTotalSubAdmins(data?.data?.totalSubAdmins);
            }
            else {
                setLoader(false);
                setSubAdminData([])
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error While Fetching All Sub Admins", error);
        }
    }

    const vendor_page = {
        width: '100%',
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };

    const getAllSubAdmins = async () => {
        try {
            const data = await get_data(`sub-admin/get-all-sub-admin?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setSubAdminData(data.data?.subAdmins);
                setTotalPages(data?.data?.totalPages);
                setTotalSubAdmins(data?.data?.totalSubAdmins);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Sub Admin", error);
        }
    }

    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchSubAdmins()
            }
            else {
                getAllSubAdmins();
            }
        }
    }, [currentPage])


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Name", grid: 2 },
        { title: "Email", grid: 2 },
        { title: "Permissions", grid: 3 },
        { title: "Active", grid: 2 },
        { title: "Actions", grid: 2 },
    ];

    const count = subAdminData?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...vendor_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Sub Admins' />

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right' }}>
                    <Button variant="contained" startIcon={<IoMdAdd style={{ color: 'white' }} />} style={{ borderRadius: 7, background: createButtonBg, boxShadow: 'none', color: 'white' }} onClick={() => setAddSubAdmin(true)}>
                        Create
                    </Button>
                    <AddSubAdmin
                        open={addSubAdmin}
                        setOpen={setAddSubAdmin}
                        getAllSubAdmins={getAllSubAdmins}
                        
                    />

                    <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
                </div>

                <div style={page_container}>
                    <SubAdminTable
                        tableHeader={tableHeader}
                        data={subAdminData}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        count={count}
                        totalSubAdmins={totalSubAdmins}
                        totalPages={totalPages}
                        loader={loader}
                        getAllSubAdmins={getAllSubAdmins}
                        setLoader={setLoader}
                    />
                </div>
            </Grid>
        </>
    )
}