import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { globalConstant } from "../../../globalConstants";

export default function BackButton() {

    const { primaryColor } = globalConstant()
    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <>
            <div
                onClick={handleBack}
                style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: primaryColor,
                    color: 'white',
                    borderRadius: '50%',
                    cursor: "pointer"
                }}>
                <IoArrowBackSharp />
            </div>
        </>
    )
}