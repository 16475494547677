import React, { useEffect, useState } from 'react';
import { useMediaQuery, Box, Typography } from '@mui/material';
import { globalConstant } from '../../globalConstants'
import Header from '../Components/Header';
import Empty from "../../web-app/user-dashboard/Components/Empty"
import Footer from '../Components/Footer';
import SubServiceCard from '../Components/SubServiceCard';
import Testimonials from '../Components/Testimonials';
import Newsletter from '../Components/Newsletter';
import StarIcon from '@mui/icons-material/Star';
import { useLocation } from 'react-router-dom';
import { endPointURL, get_data } from '../../api';
import ServiceReviews from "../Components/ServiceReviews";
import { useSelector } from 'react-redux';
import Loader from '../Components/Loader';

const Service = () => {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { primaryColor } = globalConstant();
    const location = useLocation()
    const [refresh, setRefresh] = React.useState(false)
    const [reviews, setReviews] = React.useState([])
    const { user_data } = useSelector(state => state.user);
    const [reviewsLoading, setReviewsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const [totalPages, setTotalPages] = useState(null);
    const [totalReviews, setTotalReviews] = useState(null);

    const service = location?.state?.service

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: isDesktop ? '0 3%' : '20px 5px',
            backgroundColor: '#efffef',
        },
        leftSide: {
            flex: 1,
            padding: '20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        heading: {
            fontSize: isDesktop ? 45 : 32,
            fontWeight: 500,
            marginBottom: '20px',
            marginTop: 0,
        },
        highlight: {
            color: primaryColor,
        },
        description: {
            width: '90%',
            fontSize: '16px',
            marginBottom: '20px',
        },
        cardsContainer: {
            display: 'grid',
            padding: isDesktop ? '1% 4%' : '20px 5px',
            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : isTablet ? 'repeat(2, 1fr)' : '1fr',
            gap: '20px',
        },
        cardSection: {
            padding: isDesktop ? '3% 7%' : '40px 20px',
            position: 'relative'
        },
        image: {
            maxWidth: isDesktop ? '80%' : '100%',
            height: 'auto',
            borderRadius: '10px',
        },

        starIcon: {
            color: '#6F42C1',
            marginRight: '5px',
        },
        ratingText: {
            fontWeight: 'bold',
        },
        bookingText: {
            color: '#757575',
            marginLeft: '5px',
        },
        dottedLine: {
            borderBottom: '1px dotted #757575',
            flex: 1,
            marginLeft: '10px',
        },
        ratingsContainer: {
            display: 'flex',
            alignItems: 'center',
            fontSize: isDesktop ? 16 : 14,
        },
        amenity: {
            fontSize: '12px',
            color: '#666',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '15px',
            padding: '5px 10px',
            marginRight: '5px',
            marginBottom: '5px',
        },
        amenities: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '10px',
        },
    };

    const formatReviews = (totalReviews) => {
        if (totalReviews >= 1000000) {
            return `${(totalReviews / 1000000).toFixed(1)}M`;
        } else if (totalReviews >= 1000) {
            return `${(totalReviews / 1000).toFixed(1)}k`;
        } else {
            return totalReviews;
        }
    };


    const ratings = () => {
        return (
            <div style={styles.ratingsContainer}>
                <StarIcon style={styles.starIcon} />
                <span style={styles.ratingText}>{service?.averageRating}</span>
                <span style={styles.bookingText}>({formatReviews(service?.totalReviews)})</span>
                <div style={styles.dottedLine}></div>
            </div>
        );
    }

    const getAllReviews = async () => {
        try {
            const data = await get_data(`reviews/get-all-reviews-by-service/${service?._id}?pageNumber=${currentPage}`);
            if (data.status) {
                setReviews(data?.data?.reviews)
                setReviewsLoading(false)
                setTotalPages(data?.data?.totalPages);
                setTotalReviews(data?.data?.totalReviews);
            }
        } catch (error) {
            setReviewsLoading(false)
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllReviews(currentPage);
        }
    }, [currentPage]);

    return (
        <>
            <Header />

            <div style={styles.container} >
                <div style={styles.leftSide}>
                    <h2 style={styles.heading}>{service?.service_name}</h2>
                    {ratings()}
                    <p style={styles.description}>{service?.description}</p>
                    <Box sx={styles.amenities}>
                        {['Value for Moeny', '100% Satisfaction', 'Hygiene and Clean', 'Safe & Secure'].map((amenity, index) => (
                            <Typography key={index} sx={styles.amenity}>{amenity}</Typography>
                        ))}
                    </Box>
                </div>
                <div style={styles.rightSide}>
                    <img
                        src={`${endPointURL}/uploads/service-picture/${service?.service_image}`}
                        style={styles.image}
                        alt=''
                    />
                </div>
            </div>

            <div style={styles.cardSection}>
                {
                    isDesktop && (
                        <img src='/images/blur-div.svg' style={{ position: 'absolute', left: 0, top: 0, transform: 'rotate(180deg)', zIndex: -1 }} />
                    )
                }

                {
                    service?.sub_services?.length === 0 ?
                        <>
                            <Empty />
                        </>
                        :
                        <>
                            <div style={styles.cardsContainer}>
                                {
                                    service?.sub_services?.map((subService, index) => (
                                        <SubServiceCard key={index} subService={subService} refresh={refresh} setRefresh={setRefresh} />
                                    ))
                                }
                            </div>
                        </>
                }
            </div>

            {
                reviewsLoading ?
                    <>
                        <Loader />
                    </>
                    :
                    <>
                        <dvi style={{ zIndex: 2 }}>
                            <ServiceReviews service={service} reviews={reviews} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} />
                        </dvi>
                    </>
            }
            <Testimonials />
            <Newsletter />
            <Footer />
        </>
    );
};

export default Service;