import React from 'react';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { globalConstant } from '../../globalConstants';

const AboutHero = () => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');
    const { primaryColor } = globalConstant()

    const Container = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: isMobile || isTablet ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: isMobile ? '20px' : isTablet ? '40px' : '60px',
        gap: '20px',
    }));

    const ImageContainer = styled(Box)(({ theme }) => ({
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        img: {
            width: '100%',
            borderRadius: '10px',
        },
    }));

    const ContentContainer = styled(Box)(({ theme }) => ({
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        textAlign: 'left',
        padding: isMobile || isTablet ? '10px' : '20px',
    }));

    return (
        <Container>
            <ImageContainer>
                <img src="/images/house-dummy.jpg" alt="Team" />
            </ImageContainer>
            <ContentContainer>
                <Typography variant="h3" gutterBottom style={{ fontWeight: 600, fontSize: isTablet || isMobile ? 25 : 45, textAlign: 'left' }}>
                    Dedicated Teams.
                    <br />
                    For Your Dedicated Dreams.
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <Box sx={{ background: '#efffef', padding: '20px', borderRadius: '10px' }}>
                        <Typography variant="h6" gutterBottom style={{ textAlign: 'left' }}>
                            Why We Do This
                        </Typography>
                        <Typography variant="body1" gutterBottom style={{ textAlign: 'left' }}>
                            At Nivishka Services, we understand that your home is more than just a place—it's a sanctuary. Our mission is to transform everyday spaces into extraordinary environments with our expert home services. Whether it's fixing a leaky faucet or giving your entire home a makeover, our team is here to bring your vision to life with precision and care.
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h6" gutterBottom style={{ textAlign: 'left' }}>
                        Helping You
                        <br />
                        Grow In Every Stage.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" style={{ textAlign: 'left' }}>
                        We're committed to delivering excellence in every project, ensuring that our services enhance your living experience. From concept to completion, we work closely with you, keeping your needs at the forefront of everything we do. Let us be your partner in creating the home of your dreams.
                    </Typography>
                </Box>
            </ContentContainer>
        </Container>
    );
};

export default AboutHero;
