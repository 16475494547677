import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MdDashboard, MdPeople, MdSettings, MdOutlineSubtitles } from "react-icons/md";
import { useSelector } from "react-redux";
import Sidebar from "../../web-app/admin-dashboard/Components/Sidebar";
import AdminProfile from "../../web-app/admin-dashboard/Pages/AdminProfile";
import TopBar from "../../web-app/admin-dashboard/Components/TopBar";
import { BsFillPinAngleFill } from "react-icons/bs";
import { MdOutlineCategory } from "react-icons/md";
import BlogDashboardComponent from "../components/BlogDashboardComponent";
import { IoMdList } from "react-icons/io";
import { GrGallery } from "react-icons/gr";
import { LuGalleryThumbnails } from "react-icons/lu";
import Banners from "./Banners";
import Create from "./Create";
import Category from "./Category";
import Posts from "./Posts";
import EditPost from "./EditPost";
import Gallery from "./Gallery";
import Clients from "./Clients";
import { FaUserGroup } from "react-icons/fa6";

export default function BlogDashboard({ socket }) {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { user_data } = useSelector(state => state.user);
    const [permissions, setPermissions] = useState([])

    function getPermissionKeys(arr) {
        return arr?.map(permission => permission.permission_key);
    }

    useEffect(() => {
        const sub_admin_permissions = getPermissionKeys(user_data?.permissions)

        if (sub_admin_permissions) {
            setPermissions(sub_admin_permissions)
        }
    }, [user_data])

    const content_section = {
        height: '90.8vh',
        padding: '0 2%',
        background: '#F8F8F8',
        overflowY: 'auto',
    }

    const menu_items = [
        // {
        //     title: 'Dashboard',
        //     link: '/blog/dashboard',
        //     icon: <MdDashboard style={{ color: 'white' }} />,
        // },
        {
            title: 'Gallery',
            link: '/blog/dashboard',
            icon: <GrGallery style={{ color: 'white' }} />,
        },
        {
            title: 'Clients',
            link: '/blog/dashboard/clients',
            icon: <FaUserGroup style={{ color: 'white' }} />,
        },
        {
            title: 'Banners',
            link: '/blog/dashboard/banners',
            icon: <LuGalleryThumbnails style={{ color: 'white' }} />,
        },
        {
            title: 'Create',
            link: '/blog/dashboard/create',
            icon: <BsFillPinAngleFill style={{ color: 'white' }} />,
        },
        {
            title: 'Posts',
            link: '/blog/dashboard/posts',
            icon: <IoMdList style={{ color: 'white' }} />,
        },
        {
            title: 'Category',
            link: '/blog/dashboard/category',
            icon: <MdOutlineCategory style={{ color: 'white' }} />,
        },
        {
            title: 'Settings',
            link: '/blog/dashboard/settings',
            icon: <MdSettings style={{ color: 'white' }} />,
        }
    ]


    return (
        <>
            <div style={{ position: 'relative' }}>
                {
                    isDesktop ?
                        <>
                            <Grid container spacing={0}>
                                <Grid item md={1.6} style={{ position: 'sticky', top: 0, height: '100vh' }}>
                                    <Sidebar
                                        menu_items={menu_items}
                                    />
                                </Grid>
                                <Grid item md={10.4} style={{ overflowY: 'auto' }} className="dashboard_content_grid">
                                    <TopBar socket={socket} permissions={permissions} type='blog' />
                                    <div style={content_section} className="content-section">
                                        <Routes>

                                            {/* <Route element={<BlogDashboardComponent socket={socket} permissions={permissions} />} path="/" /> */}

                                            <Route element={<Banners />} path="/banners" />
                                            <Route element={<Create />} path="/create" />
                                            <Route element={<Category />} path="/category" />
                                            <Route element={<Posts />} path="/posts" />
                                            <Route element={<Gallery />} path="/" />
                                            <Route element={<AdminProfile />} path="/settings" />
                                            <Route element={<EditPost />} path="/posts/edit" />
                                            <Route element={<Clients />} path="/clients" />
                                        </Routes>
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <center><p>This web app is not made for small screen devices</p></center>
                        </>
                }
            </div>
        </>
    )
}