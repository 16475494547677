import React, { useState } from 'react';
import { Box, TextField, Typography, Select, MenuItem, InputLabel, FormControl, Button, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import { globalConstant } from '../../../globalConstants';
import { useSelector } from 'react-redux';
import { endPointURL, post_data } from '../../../api';
import { login } from '../../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { FaUserCircle } from "react-icons/fa";

const AdminProfileCard = () => {

    const dispatch = useDispatch();
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const { primaryColor } = globalConstant();
    const [formValues, setFormValues] = useState({
        fullName: user_data?.name || '',
        email: user_data?.email || '',
        password: user_data?.password || ''
    });

    const [formErrors, setFormErrors] = useState({});

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phoneNumber') {
            const phoneValue = value.replace(/\D/g, '');
            setFormValues({
                ...formValues,
                [name]: phoneValue
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }

        setFormErrors({
            ...formErrors,
            [name]: ''
        });
    };
    const validate = () => {
        const errors = {};
        let hasError = false;

        if (!formValues.fullName) {
            hasError = true;
            errors.fullName = 'Full Name is required'
        };
        if (!formValues.email) {
            hasError = true;
            errors.email = 'Email Address is required';
        }
        setFormErrors(errors);

        return hasError;
    }

    const handleSubmit = async () => {
        if (!validate()) {
            const data = { name: formValues.fullName, email: formValues.email };
            const res = await post_data(`super-admin/update-super-admin/${user_data._id}`, data);
            if (res?.status) {
                Toast.fire({
                    title: "Super Admin Updated",
                    color: "green",
                    icon: "success"
                });
                dispatch(login(res?.data));
            }
            else {
                Toast.fire({
                    title: "Something Went Wrong!",
                    color: "red",
                    icon: "warning"
                });
            }
        }
    };

    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '3%',
        boxShadow: 'none'
    }

    return (
        <div className="profile-card">
            <div className="profile-header">
                <FaUserCircle
                    style={{
                        color: 'gainsboro',
                        height: 80,
                        width: 80
                    }}
                />
            </div>
            <div className="profile-info">
                <h2 style={{ fontWeight: 600, fontSize: 25 }}>{user_data?.name}</h2>
                <p>{user_data?.email}</p>
            </div>
        </div>
    );
};

export default AdminProfileCard;