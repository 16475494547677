import { Box, Button, TextField, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { post_data } from '../../api';
import Swal from 'sweetalert2';
import { globalConstant } from '../../globalConstants';

const ContactForm = () => {

    const { primaryColor } = globalConstant();
    const [formErrors, setFormErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [formValues, setFormValues] = useState({
        name: "",
        email: "",
        message: "",
    })
    const isDesktop = useMediaQuery('(min-width: 768px)');


    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value
        });

        setFormErrors({ ...formErrors, [name]: false })
    };

    const validate = () => {
        const errors = {};
        let hasError = false;
        if (!formValues.name) {
            hasError = true;
            errors.name = 'Name is required'
        };
        if (!formValues.email) {
            hasError = true;
            errors.email = 'Email is required';
        }
        if (!formValues.message) {
            hasError = true;
            errors.message = 'Message is required';
        }
        setFormErrors(errors);

        return hasError;
    }

    const handleSend = async () => {
        if (!validate()) {
            setLoader(true);
            const data = await post_data('contact-message/create-contact-messages', { ...formValues, full_name: formValues.name })
            if (data.status) {
                Toast.fire({
                    "icon": "success",
                    "text": "Contact Send Succesfully",
                    "color": "green"
                })
                setFormValues({
                    name: "",
                    email: "",
                    message: "",
                })
            }
            else {
                Toast.fire({
                    "icon": "warning",
                    "text": "Something Went Wrong!",
                    "color": "red"
                })
            }
            setLoader(false);
        }
    }


    const button = {
        padding: !isDesktop ? '13px 20px' : '13px 40px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '3%',
        boxShadow: 'none'
    };


    return (
        <div className="contact-form-section">
            <div className="contact-info">
                <h2 className="form-title">Let's build an awesome project together!</h2>
                <div className="contact-details">
                    <p style={{ fontWeight: 600 }}><span role="img" aria-label="call">📞</span> Call us</p>
                    <p>9900840337 / 9900761138</p>
                </div>
                <div className="contact-details">
                    <p style={{ fontWeight: 600 }}><span role="img" aria-label="location">📍</span> Find us</p>
                    <p>
                    Corporate Office - No. 2497/577, 8th Main Rd, 5th Block, 1st Stage, HBR Layout, Bengaluru, Karnataka 560043 <br /> <br />
                    Registered Office - #92, 7th Cross, Near Indus Valley College, Gayatri Layout, Basavanapura Main Road, Krishnarajapuram, Bangalore-560036
                    </p>
                </div>
                <div className="contact-details">
                    <p style={{ fontWeight: 600 }}><span role="img" aria-label="visit">🕒</span> Visit us</p>
                    <p>Monday - Saturday</p>
                    <p>9AM - 8PM</p>
                </div>
            </div>
            <div className="contact-form">
                <h2>Get in touch.</h2>
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                    <TextField
                        fullWidth
                        label="Your Name"
                        name="name"
                        variant="outlined"
                        value={formValues.name}
                        onChange={handleChange}
                        error={!!formErrors.name}
                        helperText={formErrors.name}
                    />
                    <TextField
                        fullWidth
                        label="Your Email"
                        name="email"
                        type="email"
                        variant="outlined"
                        value={formValues.email}
                        onChange={handleChange}
                        error={!!formErrors.email}
                        helperText={formErrors.email}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                    <TextField
                        fullWidth
                        label="Enter Message"
                        name="message"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={formValues.message}
                        onChange={handleChange}
                        error={!!formErrors.message}
                        helperText={formErrors.message}
                    />
                </Box>

                <Box style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                    <Button disabled={loader} onClick={handleSend} style={button}>{loader ? "Send..." : "Send"}</Button>
                </Box>

            </div>
        </div>
    );
};

export default ContactForm;
