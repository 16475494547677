import React, { useEffect, useState } from "react";
import { Grid, Typography, Pagination, CircularProgress, Modal, Box, TextField, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, FormHelperText } from "@mui/material";
import { MdDeleteForever } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import styled from 'styled-components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@mui/material/styles';
import { endPointURL, get_data, post_data } from "../../../api";
import Swal from 'sweetalert2';
import Loader from "../../../website/Components/Loader";
import { convertMinutes, formatPrice } from "../../../globalConstants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const ServiceBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  background: #fff;
  box-sizing:border-box;
  overflow: hidden;
  `;

export default function SubServicesTable({
    tableHeader, data, itemsPerPage = 6, currentPage, setLoader,
    setCurrentPage, count, totalPages, loader, getAllSubServices,
    addSubServices, setAddSubServices, totalSubServices
}) {

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [subServiceName, setSubServiceName] = useState('');
    const [description, setDescription] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [subServiceImage, setSubServiceImage] = useState('');
    const [localSubServiceImage, setLocalSubServiceImage] = useState('');
    const [duration, setDuration] = useState();
    const [price, setPrice] = useState();
    const [discountedPrice, setDiscountedPrice] = useState();
    const [errors, setErrors] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [updateID, setUpdateID] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [services, setServices] = useState([]);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleOpen = () => setAddSubServices(true);
    const handleClose = () => {
        setSubServiceName('');
        setDescription('');
        setSelectedService('');
        setSubServiceImage('');
        setLocalSubServiceImage('');
        setDuration();
        setPrice();
        setDiscountedPrice();
        setIsUpdate(false);
        setUpdateID('');
        setDisabled(false);
        setAddSubServices(false);
    };


    const getServices = async () => {
        try {
            const data = await get_data("services/get-all-services");
            if (data.status) {
                setServices(data?.data?.services);
            }
        } catch (error) {
            console.log("Erro While Fetching Sub Services ", error);
        }
    }

    useEffect(() => {
        getServices();
    }, []);

    const fillServices = () => {
        return services.map((item) => {
            return (<MenuItem value={item._id}>{item.service_name}</MenuItem>)
        })
    }

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        background: "white",
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalSubServices);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
    };

    const handleDeleteService = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5DB761",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`sub-services/delete-sub-service/${id}`, {})
                    if (data.status) {
                        Toast.fire({
                            title: "Sub Service Deleted!",
                            color: "green",
                            icon: "success"
                        });
                        getAllSubServices();
                    }
                    else {
                        Toast.fire({
                            title: "Something Went Wrong!",
                            color: "red",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    console.log("error while delete service ", error);
                }
            }
        });
    }


    const handleSetUpdateSubService = (item) => {
        setIsUpdate(true);
        setUpdateID(item?._id);
        setSubServiceName(item?.sub_service_name);
        setDescription(item?.description);
        setSubServiceImage(item?.sub_service_image);
        setSelectedService(item?.parent_service_id?._id);
        setDuration(item?.duration);
        setPrice(item?.price);
        setDiscountedPrice(item?.discounted_price);
        handleOpen();
    }


    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }


    function validate() {
        let error = false;

        if (subServiceName.length === 0) {
            error = true;
            handleError('subServiceName', 'Sub Service Name is required')
        }
        if (description.length === 0) {
            error = true;
            handleError('description', 'Description is required')
        }
        if (selectedService.length === 0) {
            error = true;
            handleError('service', 'Service is required')
        }
        if (!duration) {
            error = true;
            handleError('duration', 'Duration is required')
        }
        if (!price) {
            error = true;
            handleError('price', 'Price is required')
        }
        if (!discountedPrice) {
            error = true;
            handleError('discountedPrice', 'Discounted Price is required')
        }
        if (subServiceImage.length === 0) {
            error = true;
            handleError('subServiceImage', 'Sub Service Image is required')
        }
        return error;
    }

    const handleAddSubService = async () => {
        const error = validate();
        if (!error) {
            try {
                setDisabled(true);
                const formdata = new FormData();
                formdata.append("sub_service_name", subServiceName);
                formdata.append("description", description);
                formdata.append("parent_service_id", selectedService);
                formdata.append("duration", duration);
                formdata.append("price", price);
                formdata.append("discounted_price", discountedPrice);
                formdata.append("sub_service_image", subServiceImage);

                const data = await post_data("sub-services/create-sub-service", formdata);
                console.log(data)
                console.log(data.status)

                if (data.status) {
                    handleClose();
                    getAllSubServices();
                    Toast.fire({
                        icon: 'success',
                        title: "Sub Service Added Successfully",
                        color: "green"
                    })
                }
                else {
                    handleClose();
                    Toast.fire({
                        icon: 'warning',
                        title: "Something Went Wrong",
                        color: "green"
                    })
                }
            } catch (error) {
                handleClose();
                console.log("Error While Add Sub Service ", error);
            }
        }
    }


    const handleUpdateService = async () => {
        const error = validate();
        if (!error) {
            setDisabled(true);
            if (localSubServiceImage) {
                try {
                    const formdata = new FormData();
                    formdata.append("sub_service_name", subServiceName);
                    formdata.append("description", description);
                    formdata.append(`parent_service_id`, selectedService);
                    formdata.append("sub_service_image", subServiceImage);
                    formdata.append("duration", duration);
                    formdata.append("price", price);
                    formdata.append("discounted_price", discountedPrice);

                    const data = await post_data(`sub-services/update-sub-service-with-picture/${updateID}`, formdata);

                    if (data.status) {
                        handleClose();
                        Toast.fire({
                            icon: 'success',
                            title: "Sub Service Updated Successfully",
                            color: "green"
                        })
                        getAllSubServices();
                    }
                    else {
                        handleClose();
                        Toast.fire({
                            icon: 'Warning',
                            title: "Something Went Wrong",
                            color: "red"
                        })
                    }
                } catch (error) {
                    handleClose();
                    console.log("Error While Update Service ", error);
                }
            }
            else {
                try {
                    const body = {
                        sub_service_name: subServiceName, description: description, parent_service_id: selectedService,
                        duration: duration, price: price, discounted_price: discountedPrice
                    }
                    const data = await post_data(`sub-services/update-sub-service/${updateID}`, body);

                    if (data.status) {
                        handleClose();
                        getAllSubServices();
                        Toast.fire({
                            icon: 'success',
                            title: "Service Updated Successfully",
                            color: "green"
                        })
                    }
                    else {
                        handleClose();
                        Toast.fire({
                            icon: 'warning',
                            title: "Something Went Wrong",
                            color: "red"
                        })
                    }
                } catch (error) {
                    handleClose();
                    console.log("Error While Add Service ", error);
                }
            }
        }
    }

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 || index === 2 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };



    const displayAllHeader = () => {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right',
                    cursor: 'pointer',
                }}
                >
                    <span style={{ display: 'flex', alignItems: 'center', gap: '3px', color: '#34eb52' }}>
                        <Modal
                            open={addSubServices}
                            onClose={handleClose}
                        >
                            <Box sx={style}>
                                <ServiceBox>
                                    {/* <FormSection> */}
                                    <Grid container spacing={1}>

                                        <Grid item xs={12} style={{ display: "flex", alignItems: 'center', flexDirection: 'column', marginTop: 0 }}>

                                            {
                                                isUpdate && localSubServiceImage ?
                                                    <Grid item xs={6}>
                                                        <img alt="service" src={localSubServiceImage} style={{ width: 150, height: 150, borderRadius: '50%' }} />
                                                    </Grid>
                                                    : isUpdate ?
                                                        <Grid item xs={6}>
                                                            <img alt="service" src={`${endPointURL}/uploads/service-picture/${subServiceImage}`} style={{ width: 150, height: 150, borderRadius: '50%' }} />
                                                        </Grid>
                                                        : localSubServiceImage ?
                                                            <Grid item xs={6}>
                                                                <img alt="service" src={localSubServiceImage} style={{ width: 150, height: 150, borderRadius: '50%' }} />
                                                            </Grid>
                                                            :
                                                            <></>
                                            }
                                        </Grid>


                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                label="Enter Sub Service Name"
                                                value={subServiceName}
                                                error={errors.subServiceName}
                                                helperText={errors.subServiceName}
                                                sx={{ mt: 2 }}
                                                onChange={(e) => {
                                                    setSubServiceName(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrors({ ...errors, subServiceName: "" })
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl error={errors.service} fullWidth sx={{ mt: 2 }}>
                                                <InputLabel>Select Service</InputLabel>
                                                <Select
                                                    label="Select Service"
                                                    value={selectedService}
                                                    onChange={(e) => {
                                                        setSelectedService(e.target.value);
                                                    }}
                                                    onFocus={(e) => {
                                                        setErrors({ ...errors, service: '' });
                                                    }}
                                                >
                                                    {fillServices()}
                                                </Select>
                                                <FormHelperText>{errors.service}</FormHelperText>
                                            </FormControl>
                                        </Grid>


                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                label="Enter Description"
                                                error={errors.description}
                                                helperText={errors.description}
                                                sx={{ mt: 2 }}
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrors({ ...errors, description: "" })
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                value={duration}
                                                label="Enter Duration (in mins)"
                                                error={errors.duration}
                                                helperText={errors.duration}
                                                sx={{ mt: 2 }}
                                                type="number"
                                                onChange={(e) => {
                                                    setDuration(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrors({ ...errors, duration: "" })
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                label="Enter Price"
                                                error={errors.price}
                                                helperText={errors.price}
                                                sx={{ mt: 2 }}
                                                type="number"
                                                value={price}
                                                onChange={(e) => {
                                                    setPrice(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrors({ ...errors, price: "" })
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                label="Enter Discounted Price"
                                                error={errors.discountedPrice}
                                                helperText={errors.discountedPrice}
                                                value={discountedPrice}
                                                sx={{ mt: 2 }}
                                                type="number"
                                                onChange={(e) => {
                                                    setDiscountedPrice(e.target.value);
                                                }}
                                                onFocus={() => {
                                                    setErrors({ ...errors, discountedPrice: "" })
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={4} style={{ marginTop: '2%' }}>

                                            <FormControl fullWidth error={errors.subServiceImage}>
                                                <Button
                                                    fullWidth
                                                    component="label"
                                                    role={undefined}
                                                    variant="contained"
                                                    tabIndex={-1}
                                                    startIcon={<CloudUploadIcon />}
                                                    style={{ marginTop: '2%', boxShadow: 'none', background: 'transparent', border: '1px dashed gainsboro', color: 'black' }}
                                                    error={errors.subServiceImage}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, subServiceImage: "" })
                                                    }}
                                                >
                                                    {isUpdate ? "Change file" : "Upload file"}
                                                    <input type="file" required hidden accept="images/*" onChange={(e) => {
                                                        setSubServiceImage(e.target.files[0])
                                                        setLocalSubServiceImage(URL.createObjectURL(e.target.files[0]))
                                                    }}
                                                    />
                                                </Button>
                                                <FormHelperText>{errors.subServiceImage}</FormHelperText>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                disabled={disabled}
                                                sx={{
                                                    padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                                                }}
                                                onClick={isUpdate ? handleUpdateService : handleAddSubService}
                                            >
                                                {isUpdate ? "Update Sub Service" : "Add Sub Service"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {/* </FormSection> */}
                                </ServiceBox>
                            </Box>
                        </Modal>
                    </span>
                </div>
                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        :
                        data?.length > 0 ?
                            data?.map((item, i) => (
                                <div style={{ width: "100%" }} key={i}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{
                                            width: "100%",
                                            padding: 8,
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px dashed gainsboro",
                                        }}
                                    >
                                        <Grid item xs={0.5}>
                                            <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>{startEntry + i}</h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'left' }}
                                        >
                                            <img src={`${endPointURL}/uploads/service-picture/${item?.sub_service_image}`} style={{ width: 45, height: 45, borderRadius: 5 }} />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2.5}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254", margin: 0, padding: 0
                                                }}
                                            >
                                                {item.sub_service_name || '-'}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254", margin: 0, padding: 0
                                                }}
                                            >
                                                {item?.parent_service_id?.service_name || '-'}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254", margin: 0, padding: 0
                                                }}
                                            >
                                                {formatPrice(item?.price)}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254", margin: 0, padding: 0
                                                }}
                                            >
                                                {formatPrice(item?.discounted_price)}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254", margin: 0, padding: 0
                                                }}
                                            >
                                                {convertMinutes(item?.duration)}
                                            </h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", justifyContent: "center", gap: '10px' }}
                                        >
                                            <img onClick={() => handleSetUpdateSubService(item)} src="/images/edit-img.svg" style={{ cursor: 'pointer' }} />
                                            <img onClick={() => handleDeleteService(item._id)} src="/images/delete-img.svg" style={{ cursor: 'pointer' }} />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                            :
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
                            >
                                No Sub Service Found...
                            </Grid>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalSubServices} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}