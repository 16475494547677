import React, { useEffect, useState } from 'react';
import { useMediaQuery, Box, Typography } from '@mui/material';
import { globalConstant } from '../../globalConstants'
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const PmsPrivacyPolicy = () => {

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
    const { primaryColor } = globalConstant();

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: isDesktop ? '3%' : '20px 5px',
            backgroundColor: '#efffef',
        },
        leftSide: {
            flex: 1,
            padding: '20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        heading: {
            fontSize: isDesktop ? 45 : 32,
            fontWeight: 500,
            marginBottom: '20px',
            marginTop: 0,
        },
        highlight: {
            color: primaryColor,
        },
        description: {
            width: '90%',
            fontSize: '16px',
            marginBottom: '20px',
        },
        cardsContainer: {
            display: 'grid',
            padding: isDesktop ? '1% 4%' : '20px 5px',
            gridTemplateColumns: isDesktop ? 'repeat(3, 1fr)' : isTablet ? 'repeat(2, 1fr)' : '1fr',
            gap: '20px',
        },
        cardSection: {
            padding: isDesktop ? '3% 7%' : '40px 20px',
            position: 'relative'
        },
        image: {
            maxWidth: isDesktop ? '80%' : '100%',
            height: 'auto',
            borderRadius: '10px',
        },

        starIcon: {
            color: '#6F42C1',
            marginRight: '5px',
        },
        ratingText: {
            fontWeight: 'bold',
        },
        bookingText: {
            color: '#757575',
            marginLeft: '5px',
        },
        dottedLine: {
            borderBottom: '1px dotted #757575',
            flex: 1,
            marginLeft: '10px',
        }
    };


    return (
        <>
            <Header />

            <div style={styles.container} >
                <div style={styles.leftSide}>
                    <h2 style={styles.heading}>Nivishka PMS Privacy Policy</h2>
                    {/* <p style={styles.description}></p> */}
                </div>
                <div style={styles.rightSide}>
                </div>
            </div>

            <div style={{ padding: isDesktop ? '3% 18%' : '5%', fontSize: 17 }}>

                <p>Welcome to Nivishka PMS! This privacy policy explains how we collect, use, and protect your information when you use our app.</p>

                <h2>1. Information We Collect</h2>
                <p>To provide the best user experience, <strong>Nivishka PMS</strong> may collect the following information:</p>
                <ul>
                    <li>
                        <strong>Camera Access:</strong> We may request access to your device's camera to allow you to capture images or videos for features such as uploading photos of documents, assets, or other relevant purposes within the app. These images are used only within the app’s intended functionality and are not shared without your consent.
                    </li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <p>The information collected is used for the following purposes:</p>
                <ul>
                    <li>To provide core app features, such as capturing and managing data through the camera.</li>
                    <li>To ensure the smooth operation of the app and improve its functionality.</li>
                </ul>
                <p>We do not use your camera or any collected data for any unauthorized purposes.</p>

                <h2>3. Sharing of Information</h2>
                <p>We do not share your personal information, including camera-captured data, with third parties unless:</p>
                <ul>
                    <li>It is required by law.</li>
                    <li>You have explicitly consented to such sharing.</li>
                </ul>

                <h2>4. Data Security</h2>
                <p>We prioritize the security of your data. We implement appropriate technical and organizational measures to protect your information from unauthorized access, disclosure, or loss.</p>

                <h2>5. Your Rights</h2>
                <p>You have the right to:</p>
                <ul>
                    <li>Access and manage the information we collect.</li>
                    <li>Revoke camera permissions at any time through your device settings.</li>
                </ul>
                <p><strong>Note:</strong> Revoking permissions may affect certain app functionalities.</p>

                <h2>6. Third-Party Services</h2>
                <p><strong>Nivishka PMS</strong> may use third-party services that adhere to their privacy policies. These services will only access the information necessary to perform their functions.</p>

                <h2>7. Changes to This Privacy Policy</h2>
                <p>We may update this privacy policy periodically. Any changes will be posted on this page with an updated effective date. Please review it regularly.</p>

                <h2>8. Contact Us</h2>
                <p>If you have any questions or concerns about this privacy policy or the app's data usage, please contact us:</p>
                <ul>
                    <li><strong>Email:</strong> <a href="mailto:support@nivishka.com">support@nivishka.com</a></li>
                </ul>

            </div>

            <Footer />
        </>
    );
};

export default PmsPrivacyPolicy;