import React, { useState, useEffect, useRef } from "react";
import { FaCamera, FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../web-app/admin-dashboard/Components/LogoutModal";
import { Avatar } from "@mui/material";
import { endPointURL } from "../../api";
import { useSelector } from "react-redux";
import { HiCalendar } from "react-icons/hi";
import { MdPeople, MdStore, } from "react-icons/md";
import { GrServices } from "react-icons/gr";
import { AiOutlineLogout } from "react-icons/ai";
import { MdOutlineContactSupport } from "react-icons/md";

export default function UserAvatar() {

    const navigate = useNavigate()
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const { user_data } = useSelector(state => state.user);
    const [open, setOpen] = useState(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        if (showMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showMenu]);

    const handleLogout = () => {
        setShowMenu(!showMenu)
        setOpen(true);
    }

    return (
        <div style={{ position: 'relative', display: 'inline-block' }} ref={menuRef}>
            <div onClick={toggleMenu} style={{ cursor: 'pointer' }}>
                {user_data?.profile_picture ?
                    <Avatar className="user-icon" src={`${endPointURL}/uploads/profile-picture/${user_data?.profile_picture}`} style={{ height: 40, width: 40 }} />
                    :
                    <div className="icon-container">
                        <FaUserCircle
                            style={{
                                color: 'gainsboro',
                                height: 40,
                                width: 40
                            }}
                        />
                    </div>
                }
            </div>
            {showMenu && (
                <div style={styles.menu}>

                    <div className="popup-menu-item" onClick={() => { navigate('/dashboard', { state: { tab: 0 } }); window.scrollTo(0, 0); setShowMenu(!showMenu) }} style={styles.menuItem}>
                        <HiCalendar style={{ color: 'gray', fontSize: 20 }} />
                        My Profile
                    </div>
                    <div className="popup-menu-item" onClick={() => { navigate('/dashboard', { state: { tab: 1 } }); window.scrollTo(0, 0); setShowMenu(!showMenu) }} style={styles.menuItem}>
                        <HiCalendar style={{ color: 'gray', fontSize: 20 }} />
                        My Bookings
                    </div>
                    <div className="popup-menu-item" onClick={() => { navigate('/dashboard', { state: { tab: 4 } }); window.scrollTo(0, 0); setShowMenu(!showMenu) }} style={styles.menuItem}>
                        <MdOutlineContactSupport style={{ color: 'gray', fontSize: 20 }} />
                        Help Center
                    </div>
                    <div className="popup-menu-item" onClick={handleLogout} style={{ ...styles.menuItem, borderBottom: 'none', color: 'red' }}>
                        <AiOutlineLogout style={{ color: 'red', fontSize: 20 }} />
                        Logout
                    </div>
                </div>
            )}
            {open && <LogoutModal open={open} setOpen={setOpen} />}
        </div>
    );
}

const styles = {
    menu: {
        position: 'absolute',
        top: '45px',
        right: 0,
        width: 200,
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        zIndex: 1,
    },
    menuItem: {
        padding: '10%',
        display: 'flex',
        alignItems: 'center',
        gap: 15,
        cursor: 'pointer',
        borderBottom: '1px solid #eee',
    },
    lastMenuItem: {
        borderBottom: 'none',
    }
};