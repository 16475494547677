import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LuDot } from "react-icons/lu";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { MdNotificationsActive } from "react-icons/md";
import { get_data } from '../../../api';
import { formatDateTime } from '../../../globalConstants';

export default function NotificationsDrawer({ socket }) {
    const dispatch = useDispatch()
    const [state, setState] = React.useState({ right: false })
    const [anchorEl, setAnchorEl] = useState(null)
    const { user_data } = useSelector(state => state.user);
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false)
    const [notifications, setNotifications] = useState([]);
    const open = Boolean(anchorEl)
    const navigate = useNavigate()


    const getAllNotitfication = async () => {
        const res = await get_data("notifications/get-all-notifications")
        if (res?.status) {
            setNotifications(res?.data);
        }
    }

    useEffect(() => {
        socket.on("booking-created", (data) => {
            console.log('daata booking created - ', data);

            if (data?.status) {
                getAllNotitfication();
            }
        });
    }, [socket])


    useEffect(() => {
        getAllNotitfication();
    }, []);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event?.type === 'keydown' && (event?.key === 'Tab' || event?.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const notification_title = {
        color: "#3F4254",
        fontSize: 16,
        lineHeight: "18px",
        fontWeight: 400,
    };

    const notification_para = {
        color: "#B5B5C3",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "15px",
    };

    const notification_div = {
        borderBottom: "1px solid #F1F1F2",
        padding: "1% 6%",
        display: "flex",
        alignItems: "start",
        gap: "3%",
        cursor: 'pointer'
    };


    const handleClick = () => {
        navigate('/admin/dashboard/bookings')
        toggleDrawer('right', false)()
    }

    const notificationsSec = () => {
        return (
            <>
                {
                    notifications?.map((item, i) => {
                        return (
                            <>
                                <div
                                    onClick={handleClick}
                                    style={notification_div}
                                    className='notifications-box'
                                >
                                    <div>
                                        <LuDot style={{ marginTop: 17 }} />
                                    </div>
                                    <div>
                                        <h3 style={notification_title}>New Booking for {item?.title} Service</h3>
                                        <p style={notification_para}>{item?.customer_id?.full_name || "Someone"} booked a {item?.title} service for {item?.booking_id?.service_date?.split(" ").join("-")} at {item?.booking_id?.service_time} from {item?.customer_id?.address[0]?.city}</p>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </>
        )

    }


    const list = (anchor) => (
        <Box
            style={{ width: 400, position: 'relative' }}
            role="presentation"
            onKeyDown={(event) => {
                if (event.key === 'Escape') {
                    toggleDrawer(anchor, false)(event);
                }
            }}
        >
            <CloseIcon onClick={toggleDrawer('right', false)} style={{ position: 'absolute', right: '4%', top: 10, cursor: 'pointer', opacity: '70%', zIndex: 99 }} />
            {notificationsSec()}
        </Box>
    );


    return (
        <div>
            <React.Fragment key={'right'}>
                <MdNotificationsActive onClick={toggleDrawer('right', true)} style={{ width: 25, height: 25, marginRight: '2%', cursor: 'pointer' }} />
                <Drawer
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    {list('right')}
                </Drawer>
            </React.Fragment>
        </div>
    );
}