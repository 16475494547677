import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { globalConstant } from '../../globalConstants';

const StatsSection = () => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');
    const { primaryColor } = globalConstant()

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '20px',
            flexDirection: isMobile ? 'column' : 'row',
            textAlign: 'center',
            background: '#F9F9F9'
        },
        statBox: {
            margin: '10px',
        },
        statNumber: {
            fontSize: isMobile ? '2rem' : '3rem',
            color: primaryColor,
            fontWeight: 'bold',
        },
        statLabel: {
            fontSize: '1rem',
            color: primaryColor
        },
    };

    return (
        <Box sx={styles.container}>
            <Box sx={styles.statBox}>
                <Typography sx={styles.statNumber}>10+</Typography>
                <Typography sx={styles.statLabel}>Years of Experience</Typography>
            </Box>
            <Box sx={styles.statBox}>
                <Typography sx={styles.statNumber}>6</Typography>
                <Typography sx={styles.statLabel}>Serviceable Cities</Typography>
            </Box>
            <Box sx={styles.statBox}>
                <Typography sx={styles.statNumber}>100%</Typography>
                <Typography sx={styles.statLabel}>Professional Team</Typography>
            </Box>
            <Box sx={styles.statBox}>
                <Typography sx={styles.statNumber}>95%</Typography>
                <Typography sx={styles.statLabel}>Client Satisfaction Rate</Typography>
            </Box>
            <Box sx={styles.statBox}>
                <Typography sx={styles.statNumber}>50+</Typography>
                <Typography sx={styles.statLabel}>Successful Projects</Typography>
            </Box>
        </Box>
    );
};

export default StatsSection;
