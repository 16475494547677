import { createSlice } from "@reduxjs/toolkit";

export const subServiceSlice = createSlice({
    name: "subService",
    initialState: {
        selected_sub_services: [],
    },
    reducers: {
        allSelectedSubServices: (state, action) => {
            state.selected_sub_services = [...state.selected_sub_services, ...action.payload];
        }
    },
});

export const { allSelectedSubServices } = subServiceSlice.actions;

export default subServiceSlice.reducer;
