import { Grid, Typography, Pagination, CircularProgress, Modal, Box, TextField, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, FormHelperText } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { post_data } from "../../../api";
import Swal from "sweetalert2";

const ServiceBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  background: #fff;
  box-sizing:border-box;
  overflow: hidden;
  `;

const EditMemberShipModal = ({ getAllMemberShip, open, setOpen, memberShipData, selectedTab }) => {
    const [formValues, setFormValues] = useState({
        name: memberShipData?.name,
        description: memberShipData?.description,
        quaterly_price: memberShipData?.quaterly_price,
        quaterly_discounted_price: memberShipData?.quaterly_discounted_price,
        yearly_price: memberShipData?.yearly_price,
        yearly_discounted_price: memberShipData?.yearly_discounted_price,
        semi_yearly_price: memberShipData?.semi_yearly_price,
        semi_yearly_discounted_price: memberShipData?.semi_yearly_discounted_price,
    });

    const [errors, setErrors] = useState({})
    const [disabled, setDisabled] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleClose = () => {
        setOpen(false);
        setFormValues({});
    };

    const handleChange = (label, value) => {
        if (label == "yearly_price" || label == "yearly_discounted_price" || label == "semi_yearly_price" || label == "semi_yearly_discounted_price") {
            const data = value.replace(/\D/g, '');
            setFormValues({
                ...formValues,
                [label]: data
            });
        }
        else {
            setFormValues({
                ...formValues, [label]: value
            })
        }
    }

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }

    const validate = () => {
        let error = false;
        if (!formValues?.name) {
            error = true;
            handleError("name", "Name is Required")
        }
        if (selectedTab === 0 ? !formValues?.yearly_price : !formValues?.semi_yearly_price) {
            error = true;
            handleError("price", "Price is Required")
        }
        if (selectedTab === 0 ? !formValues?.yearly_discounted_price : !formValues?.semi_yearly_discounted_price) {
            error = true;
            handleError("discountedPrice", "Discounted Price is Required")
        }
        if (!formValues?.description) {
            error = true;
            handleError("description", "Description is Required")
        }

        return error;
    }


    const handleUpdateMemberShip = async () => {
        if (!validate()) {
            setDisabled(true);
            const data = await post_data(`membership/update-membership/${memberShipData._id}`, {
                name: formValues?.name,
                description: formValues?.description,
                quaterly_price: formValues.quaterly_price ? formValues.quaterly_price : memberShipData.quaterly_price,
                quaterly_discounted_price: formValues.quaterly_discounted_price ? formValues.quaterly_discounted_price : memberShipData.quaterly_discounted_price,
                yearly_price: formValues.yearly_price ? formValues.yearly_price : memberShipData.yearly_price,
                yearly_discounted_price: formValues.yearly_discounted_price ? formValues.yearly_discounted_price : memberShipData.yearly_discounted_price,
                semi_yearly_discounted_price: formValues?.semi_yearly_discounted_price ? formValues?.semi_yearly_discounted_price : memberShipData.semi_yearly_discounted_price,
                semi_yearly_price: formValues?.semi_yearly_price ? formValues.semi_yearly_price : memberShipData.semi_yearly_price,
            })


            if (data?.status) {
                getAllMemberShip();
                Toast.fire({
                    icon: "success",
                    text: "MemberShip Updated Successfully",
                    color: "Green"
                })
            }
            else {
                Toast.fire({
                    icon: "error",
                    text: "Something Went Wrong",
                    color: "gred"
                })
            }
            handleClose();
            setDisabled(false)

        }
    }

    return (

        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                }}
                >
                    <Typography sx={{ mt: 2 }}>
                        <ServiceBox>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label="Member Ship Name"
                                        value={formValues?.name}
                                        error={errors.name}
                                        helperText={errors.name}
                                        sx={{ mt: 2 }}
                                        onChange={(e) => {
                                            handleChange("name", e.target.value)
                                        }}
                                        onFocus={() => {
                                            setErrors({ ...errors, name: "" })
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={selectedTab === 0 ? "Quaterly Price" : selectedTab === 1 ? "Semi Yearly Price" : "Yearly Price"}
                                        error={errors.price}
                                        helperText={errors.price}
                                        sx={{ mt: 2 }}
                                        value={selectedTab === 0 ? formValues?.quaterly_price : selectedTab === 1 ? formValues?.semi_yearly_price : formValues?.yearly_price}
                                        onChange={(e) => {
                                            handleChange(selectedTab === 0 ? "quaterly_price" : selectedTab === 1 ? "semi_yearly_price" : "yearly_price", e.target.value);
                                        }}
                                        onFocus={() => {
                                            setErrors({ ...errors, price: "" })
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label={selectedTab === 0 ? "Quaterly Discounted Price" : selectedTab === 1 ? "Semi Yearly Discounted Price" : "Yearly Discounted Price"}
                                        error={errors.discountedPrice}
                                        helperText={errors.discountedPrice}
                                        sx={{ mt: 2 }}
                                        value={selectedTab === 0 ? formValues?.quaterly_discounted_price : selectedTab === 1 ? formValues?.semi_yearly_discounted_price : formValues?.yearly_discounted_price}
                                        onChange={(e) => {
                                            handleChange(selectedTab === 0 ? "quaterly_discounted_price" : selectedTab === 1 ? "semi_yearly_discounted_price" : "yearly_discounted_price", e.target.value)
                                        }}
                                        onFocus={() => {
                                            setErrors({ ...errors, discountedPrice: "" })
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        variant="outlined"
                                        label="Description"
                                        error={errors.description}
                                        helperText={errors.description}
                                        sx={{ mt: 2 }}
                                        value={formValues?.description}
                                        onChange={(e) => {
                                            handleChange("description", e.target.value)
                                        }}
                                        onFocus={() => {
                                            setErrors({ ...errors, description: "" })
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        disabled={disabled}
                                        sx={{
                                            padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                                        }}
                                        onClick={handleUpdateMemberShip}
                                    >
                                        {disabled ? "Edit MemberShip..." : "Edit MemberShip"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </ServiceBox>
                    </Typography>
                </Box>
            </Modal>
        </div >
    )
}

export default EditMemberShipModal;