import React, { useState } from "react";
import { Grid, Typography, Pagination, CircularProgress, Switch, Chip } from "@mui/material";
import AddPermissionModal from "./AddPermissionModal";
import { post_data } from "../../../api";
import Swal from "sweetalert2";
import Loader from "../../../website/Components/Loader";

export default function PermissionTable({
    tableHeader,
    data,
    itemsPerPage = 6,
    currentPage,
    setCurrentPage,
    setLoader,
    count,
    totalPages,
    loader,
    totalPermissions,
    getAllPermissions,
    searchState
}) {

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const [editPermission, setEditPermission] = useState(false);
    const [switchLoaders, setSwitchLoaders] = useState({});
    const [selectedPermission, setSelectedPermission] = useState({});

    const handleSwitch = async (item) => {
        setSwitchLoaders(prev => ({ ...prev, [item?.permission?._id]: true }));
        const data = await post_data(`sub-admin-permission/update-sub-admin-permission/${item?.permission?._id}`, { is_active: !item?.permission?.is_active });
        if (data?.status) {
            Toast.fire({
                title: "Permission Status updated!",
                color: "green",
                icon: "success"
            });
            getAllPermissions();
        }
        else {
            Toast.fire({
                title: "Something Went Wrong!",
                color: "red",
                icon: "warning"
            });
        }

        setSwitchLoaders(prev => ({ ...prev, [item?.permission?._id]: false }));
    };

    const handleEditPermission = (item) => {
        setEditPermission(true);
        setSelectedPermission(item);
    }

    const handleDeletePermission = async (item) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#5DB761',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = await post_data(`sub-admin-permission/delete-sub-admin-permission/${item?.permission?._id}`);
                if (data?.status) {
                    getAllPermissions();
                    Toast.fire({
                        title: "Permission Deleted!",
                        color: "green",
                        icon: "success"
                    });
                }
                else {
                    Toast.fire({
                        title: "Something Went Wrong!",
                        color: "red",
                        icon: "warning"
                    });
                }
            }
        })
    }

    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };

    const table_heading = {
        fontWeight: 600,
        fontSize: 17,
        color: "#3F4254",
    };

    const table_tagline = {
        fontWeight: 500,
        color: "#A1A5B7",
        fontSize: 13,
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, count);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    const displayAllHeader = () => {
        return (
            <div>
                {displayTableHeader()}
                {editPermission && <AddPermissionModal
                    open={editPermission}
                    setOpen={setEditPermission}
                    selectedPermission={selectedPermission}
                    getAllPermissions={getAllPermissions}
                    isUpdate="true"
                />}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{ display: "flex", justifyContent: "center", marginTop: '5%' }}
                        >
                            <Loader />
                        </Grid>
                        :
                        data.length > 0 ?
                            data?.map((item, i) => (
                                <div style={{ width: "100%" }} key={i}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{
                                            width: "100%",
                                            padding: "0.4% 0",
                                            display: "flex",
                                            alignItems: "center",
                                            borderTop: "1px dashed gainsboro",
                                        }}
                                    >
                                        <Grid item xs={1}>
                                            <h3 style={{ ...table_row_font, color: "#3F4254" }}>{startEntry + i}</h3>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={6}
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254",
                                                }}
                                            >
                                                {/* {searchState ? item?.permission_name : item?.permission?.permission_name} */}
                                                {item?.permission?.permission_name}
                                            </h3>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={3}
                                            style={{ color: "#3F4254", display: "flex", alignItems: "center", justifyContent: 'center', gap: 5 }}
                                        >
                                            {/* {item?.subAdmins.map((subAdmin) => (
                                                <Chip label={subAdmin?.name} />
                                            ))} */}
                                            <h3
                                                style={{
                                                    ...table_row_font,
                                                    color: "#3F4254",
                                                }}
                                            >
                                                {item?.subAdmins?.length} {item?.subAdmins?.length > 1 ? 'Sub Admins' : 'Sub Admin'}
                                            </h3>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", justifyContent: "center" }}
                                        >
                                            {switchLoaders[item?.permission?._id] ?
                                                <CircularProgress size={20} />
                                                :
                                                <Switch onChange={() => handleSwitch(item)} checked={item?.permission?.is_active} />
                                            }
                                        </Grid>

                                        <Grid
                                            item
                                            xs={1}
                                            style={{ display: "flex", justifyContent: "center", gap: '10px' }}
                                        >
                                            {/* <img src="/images/edit-img.svg" style={{ cursor: 'pointer' }} onClick={() => handleEditPermission(item)} /> */}
                                            <img src="/images/delete-img.svg" style={{ cursor: 'pointer' }} onClick={() => handleDeletePermission(item)} />
                                        </Grid>
                                    </Grid>
                                </div>
                            ))
                            :
                            <Grid
                                item
                                xs={12}
                                style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}
                            >
                                No Permissions Found...
                            </Grid>
                }
            </div>
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalPermissions} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}