import { Button, Grid } from "@mui/material";
import DashboardHeading from "../Components/DashboardHeading"
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../../api";
import CustomerCartTable from "../Components/CustomerCartTable";

export default function CustomersCart({ type }) {

    const [loader, setLoader] = useState(true);
    const [customerCartData, setCustomerCartData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalCustomerCart, setTotalCustomerCart] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const coupon_page = {
        width: '100%',
    };

    const page_container = {
        width: "100%",
        margin: "2% 0",
    };


    const getAllCustomerCart = async () => {
        try {
            const data = await get_data(`selected-service/get-all-selected-services?pageNumber=${currentPage}`);
            if (data.status) {
                setLoader(false);
                setCustomerCartData(data?.data?.selectedServices);
                setTotalPages(data?.data?.totalPages);
                setTotalCustomerCart(data?.data?.totalSelectedServices);
            }
        } catch (error) {
            setLoader(false);
            // console.log("Error while fetching Customer Cart", error);
        }
    }
    useEffect(() => {
        if (currentPage) {
            getAllCustomerCart();
        }
    }, [currentPage])


    const itemsPerPage = 15;

    const tableHeader = [
        { title: "S No.", grid: 1 },
        { title: "Phone", grid: 3 },
        { title: "Name", grid: 3 },
        { title: "City", grid: 2 },
        { title: "Services", grid: 2 },
        { title: "Action", grid: 1 },
    ];

    const count = customerCartData?.length;

    return (
        <>
            <Grid container spacing={0} style={{ ...coupon_page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <DashboardHeading title='Customers Cart' />

                <div style={page_container}>
                    <CustomerCartTable
                        tableHeader={tableHeader}
                        data={customerCartData}
                        setLoader={setLoader}
                        loader={loader}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                        totalCustomerCart={totalCustomerCart}
                        count={count}
                        getAllCustomerCart={getAllCustomerCart}
                    />
                </div>
            </Grid>
        </>
    )
}