import React, { useState } from 'react';
import { Box, TextField, Typography, Select, MenuItem, InputLabel, FormControl, Button, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import { globalConstant } from '../../../globalConstants';
import { useSelector } from 'react-redux';
import { post_data } from '../../../api';
import { login } from '../../../redux/slices/user-slice';
import { useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';

const AdminInfo = () => {

    const dispatch = useDispatch();
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const { primaryColor } = globalConstant();
    const [formValues, setFormValues] = useState({
        fullName: user_data?.name || '',
        email: user_data?.email || '',
        password: user_data?.password || ''
    });

    const [formErrors, setFormErrors] = useState({});

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phoneNumber') {
            const phoneValue = value.replace(/\D/g, '');
            setFormValues({
                ...formValues,
                [name]: phoneValue
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }

        setFormErrors({
            ...formErrors,
            [name]: ''
        });
    };
    const validate = () => {
        const errors = {};
        let hasError = false;

        if (!formValues.fullName) {
            hasError = true;
            errors.fullName = 'Full Name is required'
        };
        if (!formValues.email) {
            hasError = true;
            errors.email = 'Email Address is required';
        }
        setFormErrors(errors);

        return hasError;
    }

    const handleSubmit = async () => {
        if (!validate()) {
            const data = { name: formValues.fullName, email: formValues.email };
            let res
            if (user_data?.is_super_admin) {
                res = await post_data(`super-admin/update-super-admin/${user_data._id}`, data);
            }
            else {
                res = await post_data(`sub-admin/update-sub-admin/${user_data._id}`, data);
            }
            if (res?.status) {
                Toast.fire({
                    title: user_data?.is_super_admin ? "Super Admin Updated" : "Sub Admin Updated",
                    color: "green",
                    icon: "success"
                });
                dispatch(login(res?.data));
            }
            else {
                Toast.fire({
                    title: "Something Went Wrong!",
                    color: "red",
                    icon: "warning"
                });
            }
        }
    };

    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '3%',
        boxShadow: 'none'
    }

    return (
        <Box sx={{ padding: '30px', borderRadius: '10px', background: 'white', border: '1px solid #E0E0E0', height: 280 }}>
            <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '3%' }}>
                Personal Information
            </Typography>

            {/* First row */}
            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <TextField
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    variant="outlined"
                    value={formValues.fullName}
                    onChange={handleChange}
                    error={!!formErrors.fullName}
                    helperText={formErrors.fullName}
                    autoComplete="off"
                />
            </Box>

            {/* Second row */}
            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    type="email"
                    disabled
                    variant="outlined"
                    value={formValues.email}
                    onChange={handleChange}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    autoComplete="off"
                />

            </Box>

            <Button variant="contained" onClick={handleSubmit} style={{ ...button, marginBottom: '3%' }}>
                Update
            </Button>
            {
                user_data?.is_super_admin && (
                    <Link style={{ color: primaryColor }} to='/admin/change-email'>Change Email</Link>
                )
            }
        </Box>
    );
};

export default AdminInfo;
