import { Box, Button, Typography, Modal, Grid, useMediaQuery } from '@mui/material';
import { GoPencil } from "react-icons/go";
import { useEffect, useState } from "react";
import { get_data, post_data } from '../../api';

const Slot = ({ setServiceDate, setServiceTime, serviceDate, serviceTime }) => {

    const [open, setOpen] = useState(false);
    const [times, setTimes] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const styles = {
        slotContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItem: "center",
            cursor: 'pointer'
        },
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isDesktop ? 500 : '75%',
        bgcolor: 'white',
        border: 'none',
        boxShadow: 24,
        maxHeight: '80%',
        overflowY: 'auto',
        borderRadius: '10px',
        p: isDesktop ? 4 : 3,
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    const getSlot = async () => {
        const data = await get_data("slot/get-all-slot");

        if (data.status) {
            const timeSlot = data.data.map((slot) => {
                return (slot.from)
            })
            setTimes(timeSlot);
        }
        else {
            console.log("Error While Fetching Slot");
        }
    }

    useEffect(() => {
        getSlot();
    }, []);

    const handleSelectSlot = async () => {
        setServiceDate(selectedDate);
        setServiceTime(selectedTime);
        handleClose();
    }

    const generateNextFourDays = () => {
        const days = [];
        const today = new Date();
        for (let i = 1; i < 5; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() + i);
            const day = date.toLocaleDateString('en-US', { weekday: 'short' });
            const dayNumber = date.getDate();
            const month = date.getMonth() + 1; // months are 0-based in JS
            const year = date.getFullYear().toString().slice(-2); // last two digits of the year
            const formattedDate = `${dayNumber < 10 ? `0${dayNumber}` : dayNumber} ${month < 10 ? `0${month}` : month} ${year}`;
            days.push(`${day} ${formattedDate}`);
        }
        return days;
    }

    const dates = generateNextFourDays();

    // const formatDate = (date) => {
    //     const dayNumber = date.getDate();
    //     const month = date.getMonth() + 1; // months are 0-based in JS
    //     const year = date.getFullYear().toString().slice(-2); // last two digits of the year
    //     return `${dayNumber < 10 ? `0${dayNumber}` : dayNumber} ${month < 10 ? `0${month}` : month} ${year}`;
    // }

    return (
        <div>
            <Box style={styles.slotContainer} onClick={handleOpen}>
                <Typography variant="h6" gutterBottom>Select A Slot</Typography>
                <GoPencil onClick={handleOpen} style={{ fontSize: '30px', cursor: 'pointer', zIndex: 99 }} />
            </Box>
            {serviceDate &&
                <Typography gutterBottom>{`Your Slot is ${serviceTime} ${serviceDate?.split(' ')[0]} ${serviceDate?.split(' ')[1]}-${serviceDate?.split(' ')[2]}-20${serviceDate?.split(' ')[3]}`}</Typography>}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style}>
                    <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
                        When should the professional arrive?
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Select your comfortable Date and Time for our Partners to arrive.
                    </Typography>
                    <Grid container spacing={1} sx={{ mb: 2 }}>
                        {dates.map((date, index) => (
                            <Grid item key={index}>
                                <Button
                                    onClick={() => setSelectedDate(date)}
                                    sx={{
                                        width: '80px',
                                        height: '60px',
                                        border: '1px solid',
                                        borderColor: selectedDate === date ? 'transparent' : '#000',
                                        backgroundColor: selectedDate === date ? '#e0f7e9' : '#fff',
                                        color: '#000',
                                        display: 'flex',
                                        padding: '10px',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        borderRadius: 0,
                                        alignItems: 'center',
                                        transition: 'background-color 0.3s, border-color 0.3s',
                                        '&:hover': {
                                            backgroundColor: selectedDate === date ? '#e0f7e9' : '#f0f0f0',
                                        },
                                    }}
                                >
                                    <Typography variant="body2">{date?.split(' ')[0]}</Typography>
                                    <Typography variant="h6">{date?.split(' ')[1]}</Typography>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h6" component="h3" style={{ margin: '3% 0' }}>
                        Select start time of service
                    </Typography>
                    <Grid container spacing={1} sx={{ mb: 2 }}>
                        {times?.map((time, index) => (
                            <Grid item key={index}>
                                <Button
                                    onClick={() => setSelectedTime(time)}
                                    sx={{
                                        width: '120px',
                                        border: '1px solid',
                                        borderColor: selectedTime === time ? 'transparent' : '#000',
                                        backgroundColor: selectedTime === time ? '#e0f7e9' : '#fff',
                                        color: '#000',
                                        borderRadius: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        marginLeft: "2px",
                                        alignItems: 'center',
                                        boxShadow: 'none',
                                        padding: '10px 20px',
                                        transition: 'background-color 0.3s, border-color 0.3s',
                                        '&:hover': {
                                            backgroundColor: selectedTime === time ? '#e0f7e9' : '#f0f0f0',
                                        },
                                    }}
                                >
                                    {time}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSelectSlot}
                        sx={{
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            padding: '15px',
                            boxShadow: 'none',
                            marginTop: '3%',
                            fontSize: '16px',
                            '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#45a049',
                            },
                        }}
                    >
                        Book this Slot
                    </Button>
                </Box>
            </Modal>
        </div>
    )
}

export default Slot;