import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { get_data } from "../../api";
// import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';

const BillingDetail = ({ user_data, setCity, setBillingDetail, billingError, setBillingError }) => {

    const [formValues, setFormValues] = useState({
        fullName: '',
        phoneNumber: '',
        city: '',
        state: '',
        zipCode: ''
    });
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const isDesktop = useMediaQuery('(min-width: 768px)');

    // const [center, setCenter] = useState({ lat: -3.745, lng: -38.523 });
    // const [selectedAddress, setSelectedAddress] = useState('');
    // const [selectedCoordinates, setSelectedCoordinates] = useState({ lat: -3.745, lng: -38.523 });
    // const autocompleteRef = useRef(null);

    const getCity = async () => {
        const data = await get_data("service-area/get-all-service-area");

        const city = data?.data?.map((item) => item.city);
        setCities(city);

        const state = data?.data?.map((item) => item.state);
        const uniqueStates = [...new Set(state)];
        setStates(uniqueStates);
    };

    useEffect(() => {
        setFormValues({
            fullName: user_data?.full_name || '',
            phoneNumber: user_data?.phone || '',
            email: user_data?.email || '',
            street: user_data?.address?.[0]?.street || '',
            city: user_data?.address?.[0]?.city || '',
            state: user_data?.address?.[0]?.state || '',
            zipCode: user_data?.address?.[0]?.zip_code || ''
        });
        setBillingDetail({
            fullName: user_data?.full_name || '',
            phoneNumber: user_data?.phone || '',
            email: user_data?.email || '',
            street: user_data?.address?.[0]?.street || '',
            city: user_data?.address?.[0]?.city || '',
            state: user_data?.address?.[0]?.state || '',
            zipCode: user_data?.address?.[0]?.zip_code || ''
        });
        getCity();
    }, [user_data]);

    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phoneNumber') {
            const phoneValue = value.replace(/\D/g, '');
            setFormValues({ ...formValues, [name]: phoneValue });
            setBillingDetail({ ...formValues, [name]: phoneValue });
        } else {
            setFormValues({ ...formValues, [name]: value });
            setBillingDetail({ ...formValues, [name]: value });
        }


        if (name === "city") {
            setCity(value);
        }

        setBillingError({ ...billingError, [name]: "" })
        setFormErrors({ ...formErrors, [name]: '' });
    };

    // const containerStyle = {
    //     width: '100%',
    //     height: '400px'
    // };

    // const onPlaceChanged = () => {
    //     const place = autocompleteRef.current.getPlace();
    //     if (place.geometry) {
    //         const newCenter = {
    //             lat: place.geometry.location.lat(),
    //             lng: place.geometry.location.lng()
    //         };
    //         setCenter(newCenter);
    //         setSelectedAddress(place.formatted_address);
    //         setSelectedCoordinates(newCenter);
    //     }
    // };

    return (
        <div>
            <Typography variant="h6" gutterBottom>Service Address Details</Typography>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px', marginTop: '5%' }}>
                <TextField
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    variant="outlined"
                    value={formValues.fullName}
                    onChange={handleChange}
                    error={!!billingError.fullName}
                    helperText={billingError.fullName}
                />
                <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    type="tel"
                    variant="outlined"
                    value={formValues.phoneNumber}
                    onChange={handleChange}
                    inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                    error={!!billingError.phoneNumber}
                    helperText={billingError.phoneNumber}
                />
            </Box>

            <Box>
                <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="text"
                    variant="outlined"
                    value={formValues.email}
                    onChange={handleChange}
                    error={!!billingError.email}
                    helperText={billingError.email}
                />
            </Box>

            <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '3%', marginTop: '3%' }}>
                Address
            </Typography>
            {/* <p style={{ marginTop: 0, fontSize: 14, opacity: '70%' }}>Confirm your address manually</p> */}
            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <TextField
                    fullWidth
                    label="Street"
                    name="street"
                    variant="outlined"
                    value={formValues.street}
                    onChange={handleChange}
                    error={!!billingError.street}
                    helperText={billingError.street}
                />
            </Box>

            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                <FormControl fullWidth variant="outlined" error={!!billingError.city}>
                    <InputLabel>City</InputLabel>
                    <Select
                        name="city"
                        value={formValues.city}
                        onChange={handleChange}
                        label="City"
                    >
                        {cities?.map((city) => (
                            <MenuItem key={city} value={city}>{city}</MenuItem>
                        ))}
                    </Select>
                    {billingError.city && <FormHelperText>{billingError.city}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth variant="outlined" error={!!billingError.state}>
                    <InputLabel>State</InputLabel>
                    <Select
                        name="state"
                        value={formValues.state}
                        onChange={handleChange}
                        label="State"
                    >
                        {states?.map((state) => (
                            <MenuItem key={state} value={state}>{state}</MenuItem>
                        ))}
                    </Select>
                    {billingError.state && <FormHelperText>{billingError.state}</FormHelperText>}
                </FormControl>
                {
                    isDesktop && (
                        <TextField
                            fullWidth
                            label="Zip Code"
                            name="zipCode"
                            type="number"
                            variant="outlined"
                            value={formValues.zipCode}
                            onChange={handleChange}
                            error={!!billingError.zipCode}
                            helperText={billingError.zipCode}
                        />
                    )
                }
            </Box>

            {
                !isDesktop && (
                    <Box>
                        <TextField
                            fullWidth
                            label="Zip Code"
                            name="zipCode"
                            type="number"
                            variant="outlined"
                            value={formValues.zipCode}
                            onChange={handleChange}
                            error={!!billingError.zipCode}
                            helperText={billingError.zipCode}
                        />
                    </Box>
                )
            }


            {/* <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '1%', marginTop: '3%' }}>
                Locate on Google Maps
            </Typography>
            <LoadScript googleMapsApiKey="AIzaSyADcEUt4NJHZ4R4ELZWxPtRSNjnXooFHAc" libraries={['places']}>
                <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)} onPlaceChanged={onPlaceChanged}>
                    <TextField
                        fullWidth
                        label="Search Address"
                        name="searchAddress"
                        variant="outlined"
                        style={{ marginBottom: '20px' }}
                    />
                </Autocomplete>
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
                    <Marker position={center} />
                </GoogleMap>
            </LoadScript>

            <Typography variant="body1" gutterBottom style={{ marginTop: '5%' }}>
                Selected Address: {selectedAddress}
            </Typography> */}
            {/* <Typography variant="body1" gutterBottom>
                Selected Coordinates: {`Latitude: ${selectedCoordinates.lat}, Longitude: ${selectedCoordinates.lng}`}
            </Typography> */}
        </div>
    );
}

export default BillingDetail;
