import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal, { modalClasses } from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, colors } from '@mui/material';
import { useSelector } from 'react-redux';
import { globalConstant } from '../../globalConstants';
import { post_data } from '../../api';
import Swal from 'sweetalert2';

export default function FeedbackModal({
    open,
    setOpen,
    getCustomerFeedBack
}) {

    const handleClose = () => setOpen(false);
    const [selectedRating, setSelectedRating] = useState('')
    const [loading, setLoading] = useState(false)
    const { user_data } = useSelector(state => state.user);
    const { primaryColor } = globalConstant()
    const [formData, setFormData] = useState({})
    const [error, setError] = useState('');

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 470,
        maxHeight: '70%',
        bgcolor: 'white',
        overflowY: 'auto',
        boxShadow: 24,
        borderRadius: 3,
        padding: '2%'
    };

    const modal_title = {
        textAlign: 'left',
        width: '80%',
        fontSize: 24,
        fontWeight: 500,
        color: primaryColor
    }

    const rating_div = {
        display: 'flex',
        gap: '2.5%',
        flexWrap: 'wrap',
        justifyContent: 'left',
        marginTop: '3%',
        width: '100%'
    }

    const button = {
        padding: '13px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '20px',
        boxShadow: 'none',
    }

    const rating_arr = [1, 2, 3, 4, 5]

    const rating_box = {
        height: 40,
        width: 40,
        background: '#EEE9E3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 40
    }

    const rating_txt = {
        fontSize: 15,
        fontWeight: 500
    }

    const handleRatingClick = (index) => {
        setSelectedRating(index)
        setFormData({ ...formData, rating: index + 1 })
    }

    const handleInputChange = (e) => {
        const { name, value } = e?.target;
        if (value.length <= 200) {
            setFormData({ ...formData, [name]: value });
        } else {
            setError("Only 200 Character allowed!")
        }
    }

    const modal_title_2 = { fontSize: 18, color: 'black' }

    const rating = () => {
        return (
            <>
                <dv style={rating_div}>
                    {
                        rating_arr?.map((item, i) => {
                            return (
                                <div style={{ width: 40, height: 40 }} onClick={() => handleRatingClick(i)}>
                                    <div style={{
                                        ...rating_box, background: selectedRating === i ? primaryColor : '#EEE9E3',
                                        color: selectedRating === i ? 'white' : primaryColor
                                    }}
                                    >
                                        <p style={rating_txt}>{item}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </dv >
            </>
        )
    }

    const handleSubmitReview = () => {
        setLoading(true)
        setTimeout(async () => {
            let payload = {
                customer_id: user_data?._id,
                message: formData?.message,
                rating: formData?.rating
            }
            let response = await post_data('feedback/create-feedback', payload)
            if (response?.status) {
                getCustomerFeedBack()
                setOpen(false)
                setLoading(false)
                Toast.fire({
                    icon: 'success',
                    title: "Thanks for giving Feedback!",
                    color: "green"
                })
            }
            else {
                setLoading(false)
                setOpen(false)
                Toast.fire({
                    icon: 'Warning',
                    title: "Something Went Wrong",
                    color: "red"
                })
            }
        }, 1000);
    }

    React.useEffect(() => {
        setSelectedRating('')
    }, [open])

    const handleLeftBtn = () => {
        handleClose()
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Grid container spacing={0}>

                        <Grid item md={12} style={{
                            display: 'flex',
                            justifyContent: 'left',
                            flexDirection: 'column',
                        }}>
                            <h2 style={modal_title}>
                                Leave a Feedback
                            </h2>
                            <p className='about-para' style={{ textAlign: 'left' }}>We value your options, take a time to evaluate us.</p>

                            {rating()}

                            <h2 style={{ ...modal_title, ...modal_title_2, marginTop: '8%', color: 'black' }}>
                                What are you thinking about us?
                            </h2>

                            <textarea
                                style={{ height: 100, marginTop: '2%' }}
                                className='from-textarea'
                                placeholder="Enter your message here..."
                                type="text"
                                name="message"
                                value={formData?.message}
                                onChange={handleInputChange}
                                onFocus={() => setError('')}
                            />
                            <small>Only 200 Characters are allowed</small>
                        </Grid>

                        <Grid item md={12} style={{
                            width: '100%',
                            marginTop: '2%'
                        }}>
                            <Button
                                fullWidth
                                onClick={handleSubmitReview} style={button}
                                disabled={loading} variant='contained'
                            >
                                {loading ? 'Submit...' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
