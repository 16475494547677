import React, { useEffect, useState } from "react";
import { Box, Grid, Modal, Typography, Pagination, CircularProgress, Switch, Button, FormHelperText, FormControl, OutlinedInput, Select, InputLabel, TextField, MenuItem, FormGroup, FormControlLabel, Chip } from "@mui/material";
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { get_data, post_data } from "../../../api";
import { useTheme } from '@mui/material/styles';
import { IoClose } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import styled from 'styled-components';
import { RxUpdate } from "react-icons/rx";
import Loader from "../../../website/Components/Loader";
import { FaArrowUpShortWide } from "react-icons/fa6";
import VendorEarningModal from "./VendorEarningModal";

const ServiceBox = styled.div`
display: flex;
width: 100%;
height:100%;
background: #fff;
box-sizing:border-box;
overflow: hidden;
`;

export default function VendorTable({
    tableHeader,
    data,
    itemsPerPage = 15,
    currentPage,
    setCurrentPage,
    count,
    totalPages,
    loader,
    setLoader,
    getAllVendors,
    addVendor,
    totalVendors,
    setAddVendor,
    searchState
}) {

    const [errors, setErrors] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [profession, setProfession] = useState('');
    const [days, setDays] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [cities, setCitites] = useState([]);
    const [states, setStates] = useState([]);
    const [isVerified, setIsVerified] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [branchName, setBranchName] = useState('')
    const [ifsc, setIfsc] = useState('')
    const [commission, setCommission] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [updateID, setUpdateID] = useState('');
    const [switchLoaders, setSwitchLoaders] = useState(false);
    const [allProfession, setAllProfession] = useState([]);
    const [openEarningModal, setOpenEarningModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [ServiceAreas, setServiceAreas] = useState([]);
    const [zipcodeByCity, setZipcodeByCity] = useState([]);
    const [selectedZipcodes, setSelectedZipcodes] = useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    const table_div = {
        padding: "3%",
        borderRadius: 10,
        border: "1px solid #F1F1F2",
        boxShadow: "0px 3px 4px 0 #00000008",
        background: "white",
    };

    const table_header_title = {
        margin: 0,
        fontWeight: 600,
        fontSize: 14,
        color: "#3F4254",
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: '80vh',
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: 'none',
        outline: 'none',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px'
    };

    const table_header_div = {
        width: "100%",
        margin: "1% 0",
        padding: "1% 0",
        display: "flex",
        alignItems: "center",
    };

    const table_row_font = { margin: 0, fontWeight: 500, fontSize: 14 };

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalVendors);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
    };

    const getServicableZipCode = (city) => {
        ServiceAreas.map((item) => {
            if (item.city === city) {
                setZipcodeByCity(item?.pincodes);
            }
        })
    }

    const getCityAndStates = async () => {
        const data = await get_data("service-area/get-all-service-area");

        setServiceAreas(data?.data);

        const city = [...new Set(data?.data?.map((item) => item.city))];
        setCitites(city);

        const state = [...new Set(data?.data?.map((item) => item.state))];
        setStates(state);
    }

    const getProfession = async () => {
        const data = await get_data("profession/get-all-profession");

        setAllProfession(data?.data?.profession);
    }

    useEffect(() => {
        getCityAndStates();
        getProfession();
    }, [])


    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    // const handleOpen = () => setOpen(true);
    const handleOpen = () => setAddVendor(true);

    const handleClose = () => {
        setFullName('');
        setPhone('');
        setStreet('');
        setCity('');
        setState('');
        setZipCode('');
        setProfession('');
        setDays('');
        setStartTime('');
        setEndTime('')
        setIsVerified(false);
        setAccountNumber('');
        setAccountHolderName('');
        setBranchName('');
        setIfsc('');
        setCommission('');
        setIsUpdate(false);
        setUpdateID('');
        setDisabled(false);
        // setOpen(false);
        setAddVendor(false);
        setSelectedZipcodes([]);
        setZipcodeByCity([]);
        setErrors({});
    };

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }

    const validate = () => {
        let error = false;
        if (fullName.length === 0) {
            error = true;
            handleError("fullName", "Full Name is required");
        }
        if (phone.length === 0) {
            error = true;
            handleError("phone", "Phone is required");
        }
        if (commission.length === 0) {
            error = true;
            handleError("commission", "Commission is required");
        }
        if (profession.length === 0) {
            error = true;
            handleError("profession", "Profession is required");
        }
        if (city.length === 0) {
            error = true;
            handleError("city", "City is required");
        }
        return error;
    }

    const handleAddVendor = async () => {
        if (!validate()) {

            const data = {
                full_name: fullName, phone,
                address: [{ street, city, state, zip_code: zipCode, country: "India" }],
                profession_id: profession,
                commission,
                aadhar,
                is_active: isActive,
                is_verified: isVerified,
                bank_details: { account_number: accountNumber, ifsc, account_holder_name: accountHolderName, branch_name: branchName },
                serviceable_zipcodes: selectedZipcodes,
            }

            const res = await post_data("vendor/create-vendor", data);
            if (res?.status) {
                Toast.fire({
                    title: "Vendor Created",
                    color: "green",
                    icon: "success"
                });
                getAllVendors();
            }
            else {
                Toast.fire({
                    title: "Something Went Wrong!",
                    color: "red",
                    icon: "warning"
                });
            }
            handleClose();
        }
    }

    const handleUpdateVendor = async () => {
        if (!validate()) {

            // const position = await getLocation();
            // const lat = position?.coords?.latitude;
            // const long = position?.coords?.longitude;

            const data = {
                full_name: fullName,
                phone,
                address: [{ street, city, state, zip_code: zipCode, country: "India" }],
                profession_id: profession,
                commission,
                aadhar,
                is_verified: isVerified,
                is_active: isActive,
                bank_details: { account_number: accountNumber, ifsc, account_holder_name: accountHolderName, branch_name: branchName },
                serviceable_zipcodes: selectedZipcodes,
            }

            const res = await post_data(`vendor/update-vendor/${updateID}`, data);
            if (res?.status) {
                Toast.fire({
                    title: "Vendor Updated",
                    color: "green",
                    icon: "success"
                });
                getAllVendors();
            }
            else {
                Toast.fire({
                    title: "Something Went Wrong!",
                    color: "red",
                    icon: "warning"
                });
            }
            handleClose();
        }
    }

    const handleSetUpdateVendor = (item) => {
        setIsUpdate(true);
        setUpdateID(item?._id);
        setFullName(item.full_name || '');
        setPhone(item?.phone || '');
        setStreet(item?.address[0]?.street || '');
        setCity(item?.address[0]?.city || '');
        setState(item?.address[0]?.state || '');
        setAadhar(item?.aadhar || '');
        setZipCode(item?.address[0]?.zip_code || '');
        setProfession(item?.profession_id?._id || '');
        setIsVerified(item?.is_verified);
        setIsActive(item?.is_active);
        setAccountNumber(item?.bank_details?.account_number || '');
        setAccountHolderName(item?.bank_details?.account_holder_name || '');
        setBranchName(item?.bank_details?.branch_name || '');
        setIfsc(item?.bank_details?.ifsc || '');
        setCommission(item?.commission || '');
        setSelectedZipcodes(item?.serviceable_zipcodes || []);
        getServicableZipCode(item?.address[0]?.city);
        handleOpen();
    }

    const handleSwitch = async (item) => {
        setSwitchLoaders(prev => ({ ...prev, [item._id]: true }));
        const updatedItem = { ...item, is_active: !item.is_active };
        const data = await post_data(`vendor/update-vendor/${item._id}`, { is_active: updatedItem.is_active });
        if (data.status) {
            Toast.fire({
                title: "Vendor Status updated!",
                color: "green",
                icon: "success"
            });
            getAllVendors();
        } else {
            Toast.fire({
                title: "Something Went Wrong!",
                color: "red",
                icon: "warning"
            });
        }
        setSwitchLoaders(prev => ({ ...prev, [item._id]: false }));
    };


    const handleDeleteVendor = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#5DB761",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = await post_data(`vendor/delete-vendor/${id}`, {})
                    if (data.status) {
                        Toast.fire({
                            title: "Vendor Deleted!",
                            color: "green",
                            icon: "success"
                        });
                        getAllVendors();
                    }
                    else {
                        Toast.fire({
                            title: "Something Went Wrong!",
                            color: "red",
                            icon: "warning"
                        });
                    }
                } catch (error) {
                    console.log("error while delete service ", error);
                }
            }
        })
    };

    const displayTableHeader = () => {
        return (
            <div style={{ width: "100%" }}>
                <Grid container spacing={0} style={table_header_div}>
                    {tableHeader.map((header, index) => (
                        <Grid
                            key={index}
                            item
                            xs={header?.grid}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: index === 0 || index === 1 ? "left" : "center",
                            }}
                        >
                            <h3 style={table_header_title}>{header?.title}</h3>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };


    const handleEarningModal = (item) => {
        setOpenEarningModal(true);
        setSelectedItem(item);
    }

    const displayAllHeader = () => {
        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right',
                    cursor: 'pointer',
                }}
                >
                    {openEarningModal && <VendorEarningModal open={openEarningModal} setOpen={setOpenEarningModal} currentVendor={selectedItem} />}

                    <span style={{ display: 'flex', alignItems: 'center', gap: '3px', color: '#34eb52' }}>
                        <Modal
                            open={addVendor}
                            onClose={handleClose}
                        >
                            <Box sx={style}>
                                <Typography>
                                    <ServiceBox>
                                        <Grid container spacing={2}>

                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Enter Vendor Name"
                                                    value={fullName}
                                                    error={errors.fullName}
                                                    helperText={errors.fullName}
                                                    sx={{ mt: 2 }}
                                                    onChange={(e) => {
                                                        setFullName(e.target.value);
                                                    }}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, fullName: "" })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Enter Phone"
                                                    error={errors.phone}
                                                    helperText={errors.phone}
                                                    sx={{ mt: 2 }}
                                                    value={phone}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value) && value.length <= 10) {
                                                            setPhone(value);
                                                        }
                                                    }}
                                                    onFocus={() => {
                                                        setErrors({ ...errors, phone: "" })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <FormControl error={errors.profession} fullWidth sx={{ mt: 2 }}>
                                                    <InputLabel>Select Profession</InputLabel>
                                                    <Select
                                                        label="Enter Profession"
                                                        value={profession}
                                                        onChange={(e) => {
                                                            setProfession(e.target.value);
                                                        }}
                                                        onFocus={() => {
                                                            setErrors({ ...errors, profession: "" })
                                                        }}
                                                    >
                                                        {allProfession?.map((item) => {
                                                            return (<MenuItem value={item._id}>{item.profession_name}</MenuItem>)
                                                        })}
                                                    </Select>
                                                    <FormHelperText>{errors.profession}</FormHelperText>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Street"
                                                    name="street"
                                                    sx={{ mt: 2 }}
                                                    variant="outlined"
                                                    value={street}
                                                    onChange={(e) => {
                                                        setStreet(e.target.value)
                                                    }}
                                                    error={!!errors.street}
                                                    helperText={errors.street}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <FormControl fullWidth sx={{ mt: 2 }} variant="outlined" error={!!errors.city}>
                                                    <InputLabel>City</InputLabel>
                                                    <Select
                                                        name="city"
                                                        value={city}
                                                        onChange={(e) => {
                                                            setCity(e.target.value);
                                                            getServicableZipCode(e.target.value);
                                                        }}
                                                        label="City"
                                                    >
                                                        {cities?.map((city) => (
                                                            <MenuItem key={city} value={city}>{city}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={6}>
                                                <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
                                                    <InputLabel>Serviceable Zipcodes</InputLabel>
                                                    <Select
                                                        multiple
                                                        value={selectedZipcodes}
                                                        onChange={(event) =>
                                                            setSelectedZipcodes(event.target.value)
                                                        }
                                                        input={<OutlinedInput label="Serviceable Zipcodes" />}
                                                        renderValue={(selected) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {selected.map((value) => (
                                                                    <Chip key={value} label={value} />
                                                                ))}
                                                            </Box>
                                                        )}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {zipcodeByCity.map((zipcode) => (
                                                            <MenuItem
                                                                key={zipcode}
                                                                value={zipcode}
                                                            // style={getStyles(zipcode, zipcodeByCity, theme)}
                                                            >
                                                                {zipcode}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={6}>
                                                <FormControl sx={{ mt: 2 }} fullWidth variant="outlined" error={!!errors.state}>
                                                    <InputLabel>States</InputLabel>
                                                    <Select
                                                        name="state"
                                                        value={state}
                                                        onChange={(e) => {
                                                            setState(e.target.value);
                                                        }}
                                                        label="State"
                                                    >
                                                        {states?.map((state) => (
                                                            <MenuItem key={state} value={state}>{state}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.state && <FormHelperText>{errors.state}</FormHelperText>}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Zip Code"
                                                    name="zipCode"
                                                    sx={{ mt: 2 }}
                                                    type="number"
                                                    variant="outlined"
                                                    value={zipCode}
                                                    onChange={(e) => {
                                                        setZipCode(e.target.value)
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Commision"
                                                    name="commission"
                                                    sx={{ mt: 2 }}
                                                    variant="outlined"
                                                    value={commission}
                                                    onChange={(e) => {
                                                        setCommission(e.target.value)
                                                    }}
                                                    onFocus={(() => {
                                                        setErrors({ ...errors, commission: "" })
                                                    })}
                                                    error={!!errors.commission}
                                                    helperText={errors.commission}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Aadhar"
                                                    name="aadhar"
                                                    sx={{ mt: 2 }}
                                                    variant="outlined"
                                                    value={aadhar}
                                                    onChange={(e) => {
                                                        setAadhar(e.target.value)
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Account Number"
                                                    name="accountNumber"
                                                    sx={{ mt: 2 }}
                                                    variant="outlined"
                                                    value={accountNumber}
                                                    onChange={(e) => {
                                                        setAccountNumber(e.target.value)
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="IFSC"
                                                    name="ifsc"
                                                    sx={{ mt: 2 }}
                                                    variant="outlined"
                                                    value={ifsc}
                                                    onChange={(e) => {
                                                        setIfsc(e.target.value)
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Account Holder Name"
                                                    name="accountholdername"
                                                    sx={{ mt: 2 }}
                                                    variant="outlined"
                                                    value={accountHolderName}
                                                    onChange={(e) => {
                                                        setAccountHolderName(e.target.value)
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Branch"
                                                    name="branch"
                                                    sx={{ mt: 2 }}
                                                    variant="outlined"
                                                    value={branchName}
                                                    onChange={(e) => {
                                                        setBranchName(e.target.value)
                                                    }}
                                                />
                                            </Grid>

                                            {/* <Grid item xs={6}>
                                                <FormGroup>
                                                    <InputLabel>Verified</InputLabel>
                                                    <FormControlLabel control={
                                                        <Switch
                                                            checked={isVerified}
                                                            onChange={(e) => {
                                                                setIsVerified(e.target.checked)
                                                            }} />} />
                                                </FormGroup>
                                            </Grid> */}

                                            <Grid item xs={6}>
                                                <FormGroup>
                                                    <InputLabel>Active</InputLabel>
                                                    <FormControlLabel control={
                                                        <Switch
                                                            checked={isActive}
                                                            onChange={(e) => {
                                                                setIsActive(e.target.checked)
                                                            }} />} />
                                                </FormGroup>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    disabled={disabled}
                                                    sx={{
                                                        padding: '2% 0', mt: 2, background: '#5DB761', '&:hover': { backgroundColor: '#5DB761' }
                                                    }}
                                                    onClick={isUpdate ? handleUpdateVendor : handleAddVendor}
                                                >
                                                    {isUpdate ? "Update Vendor" : "Add Vendor"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ServiceBox>
                                </Typography>
                            </Box>
                        </Modal>
                    </span>
                </div>

                {displayTableHeader()}
                {
                    loader ?
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: '5%'
                            }}
                        >
                            <Loader />
                        </Grid> :
                        data?.length > 0 ? data?.map((item, i) => (
                            <div style={{ width: "100%" }} key={i}>
                                <Grid
                                    container
                                    spacing={0}
                                    style={{
                                        width: "100%",
                                        padding: '0.6% 0',
                                        display: "flex",
                                        alignItems: "center",
                                        borderTop: "1px dashed gainsboro",
                                    }}
                                >
                                    <Grid item xs={1}>
                                        <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>{startEntry + i}</h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        <h3
                                            style={{
                                                ...table_row_font,
                                                color: "#3F4254", margin: 0, padding: 0
                                            }}
                                        >
                                            {item?.full_name || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3 style={{ ...table_row_font, color: "#3F4254", margin: 0, padding: 0 }}>
                                            {searchState ? item?.profession?.profession_name || '-' : item?.profession_id?.profession_name || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                margin: 0,
                                                fontWeight: 500,
                                                fontSize: 14, margin: 0, padding: 0,
                                                color: "#3F4254",
                                            }}
                                        >
                                            {item?.phone || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h3
                                            style={{
                                                margin: 0,
                                                fontSize: 14,
                                                margin: 0, padding: 0,
                                                borderRadius: 4,
                                                textAlign: "center",
                                                fontWeight: 500,
                                            }}
                                        >
                                            {item?.address?.[0]?.city || '-'}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "#3F4254",
                                        }}
                                    >
                                        <div style={{ width: "60%", margin: "auto" }}>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    margin: 0, padding: 0,
                                                    borderRadius: 4,
                                                    textAlign: "center",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {item?.commission || '-'}%
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "#3F4254",
                                        }}
                                    >
                                        <div style={{ width: "60%", margin: "auto" }}>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    margin: 0, padding: 0,
                                                    borderRadius: 4,
                                                    textAlign: "center",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {item?.ratings || '-'}
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "#3F4254",
                                        }}
                                    >
                                        <div style={{ width: "60%", margin: "auto" }}>
                                            <p
                                                style={{
                                                    fontSize: 14,
                                                    margin: 0, padding: 0,
                                                    borderRadius: 4,
                                                    textAlign: "center",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                <FaArrowUpShortWide onClick={() => handleEarningModal(item)} style={{ cursor: 'pointer' }} />
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        style={{ display: "flex", justifyContent: "center", gap: '10px' }}
                                    >
                                        <img onClick={() => handleSetUpdateVendor(item)} src="/images/edit-img.svg" style={{ cursor: 'pointer' }} />
                                        <img onClick={() => handleDeleteVendor(item._id)} src="/images/delete-img.svg" style={{ cursor: 'pointer' }} />
                                    </Grid>
                                </Grid>
                            </div>
                        ))
                            :
                            <div>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "#3F4254",
                                        marginTop: '5%'
                                    }}
                                >
                                    No Vendor Found...
                                </Grid>
                            </div>
                }
            </div >
        );
    };

    return (
        <div style={table_div}>
            {displayAllHeader()}
            {data?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px dashed gainsboro",
                        paddingTop: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalVendors} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
}